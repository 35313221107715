import React, { useEffect, useState, useCallback } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useLazyQuery, useQuery } from '@apollo/react-hooks'
import SelectField from 'ui-components/lib/components/SelectField'
import BreadCrumb from 'ui-components/lib/components/BreadCrumb'
import Label from 'ui-components/lib/components/Label'
import SearchField from 'ui-components/lib/components/SearchField'
import useSearchTerm from 'ui-components/lib/hooks/useSearchTerm'
import { faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import styled from 'styled-components'
import { servicesQueries, clientsQueries, walletQueries } from '../../graphql/queries'
import { FinalPrice, Loading } from '../../components'
import SearchSuggestions from './SearchSuggestions'
import QuestionForm from './QuestionForm'
import StatusVariants from './StatusVariants'
import ServicesBalloons from '../../components/ServicesBalloons'
import { ReactComponent as Info } from '../../assets/icons/info.svg'
import { ReactComponent as Calender } from '../../assets/icons/calender.svg'
import { ReactComponent as Money } from '../../assets/icons/money.svg'
import './style.css';

const CloseButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;

  border-radius: 6px;
  background: #fff;
  color: #db2828;

  :hover {
    opacity: 0.8;
  }
`

const PurshaseService = (props) => {
  const { subscriptionService } = useParams();

  const [servicesOptions, setServicesOptions] = useState([''])
  const [servicesTypesOptions, setServicesTypesOptions] = useState([''])
  const [subservicesOptions, setSubservicesOptions] = useState([''])

  const [finalPrice, setFinalPrice] = useState('')
  const [extraUrgentDeadline, setExtraUrgentDeadline] = useState(false)
  const [selectedService, setSelectedService] = useState(null)
  const [selectedServiceType, setSelectedServiceType] = useState(null)
  const [selectedSubservice, setSelectedSubservice] = useState(null)
  const [searchResult, setSearchResult] = useState(null)
  const [searchBlur, setSearchBlur] = useState(false)
  const [ballonsOpened, setBallonsOpened] = useState(true)
  const [clientSubscription, setClientSubscription] = useState('')
  const [serviceId,setServiceId] = useState("");
  const [serviceTypeId, setServiceTypeId] = useState("");
  const [subserviceId, setSubserviceId] = useState("");
  const [clientSubscriptionBP, setClientSubscriptionBP] = useState('')
  const [clientSubscriptionIdentifier,setClientSubscriptionIdentifier] = useState('')
  
  const { push } = useHistory()

  const { data: statusData, loading: statusLoading } = useQuery(clientsQueries.GET_STATUS)
  const { data: servicesOptionsData, loading: servicesOptionsLoading, refetch: fetchServicesOptions } = useQuery(
    servicesQueries.GET_SERVICES_OPTIONS,
    {
      onCompleted() {
        setServicesOptions(servicesOptionsData?.services)
      },
    }
  )

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

  const { data: dataClientSubscriptionBP } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans',
    },
  })


  const [fetchServicesTypesOptions, { data: servicesTypesOptionsData, loading: servicesTypesOptionsLoading }] = useLazyQuery(
    servicesQueries.GET_SERVICESTYPES_OPTIONS,
    {
      variables: {
        serviceId: selectedService?._id,
      },
      onCompleted() {
        setServicesTypesOptions(servicesTypesOptionsData?.servicesTypes)
      },
    }
  )

  const [fetchSubservicesOptions, { data: subservicesOptionsData, loading: subservicesOptionsLoading }] = useLazyQuery(
    servicesQueries.GET_SUBSERVICES_OPTIONS,
    {
      variables: {
        typeId: selectedServiceType?._id,
      },
      onCompleted() {
        setSubservicesOptions(subservicesOptionsData?.subservices)
      },
    }
  )

  const [searchSubservice, { data: dataSearch, loading: loadingSearch }] = useLazyQuery(servicesQueries.SEARCH_SUBSERVICE, {
    onCompleted() {},
  })

  useEffect(() => {
    fetchServicesOptions()
  }, [fetchServicesOptions])

  useEffect(() => {
    if (selectedService !== null) fetchServicesTypesOptions()
  }, [fetchServicesTypesOptions, selectedService])

  useEffect(() => {
    if (selectedServiceType !== null) fetchSubservicesOptions()
  }, [fetchSubservicesOptions, selectedServiceType])

  useEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription)
      setClientSubscriptionIdentifier(dataClientSubscription.subscription)
    }
    if(dataClientSubscriptionBP){
      setClientSubscriptionBP(dataClientSubscriptionBP.subscription.planIdentifier)
      setClientSubscriptionIdentifier(dataClientSubscriptionBP.subscription.planIdentifier)
    }
  }, [dataClientSubscription, dataClientSubscriptionBP]) //eslint-disable-line

  const handleServiceSelect = (event) => {
    const selectedServiceId = event.target.value
    const serviceData = servicesOptions.find((service) => service._id === selectedServiceId)

    setSelectedService(serviceData)
    setSelectedServiceType(null)
    setSelectedSubservice(null)
    if(serviceData){
      setBallonsOpened(false)
    } else {
      setBallonsOpened(true)
    }
  }

  const handleServiceTypeSelect = (event) => {
    const selectedServiceTypeId = event.target.value
    const serviceTypeData = servicesTypesOptions?.find((serviceType) => serviceType._id === selectedServiceTypeId)
    setSelectedServiceType(serviceTypeData)
    setSelectedSubservice(null)
  }

  const handleSubservicesSelect = (event) => {
    const selectedSubservice = event.target.value
    const subserviceData = subservicesOptions?.find((subservice) => subservice._id === selectedSubservice)
    setSelectedSubservice(subserviceData)
  }

  const selectSubserviceByHint = async (serviceId,serviceType,subserviceId) => {
    setServiceId(serviceId);
    setServiceTypeId(serviceType);
    setSubserviceId(subserviceId);
    setBallonsOpened(false);
  }

  useEffect(()=>{
    const serviceData = servicesOptions.find((service) => service._id === serviceId)
    setSelectedService(serviceData)
    fetchServicesTypesOptions()
    if(servicesTypesOptions !==  undefined){
      const serviceTypeData = servicesTypesOptions?.find((serviceType) => serviceType._id === serviceTypeId)
      setSelectedServiceType(serviceTypeData)
    }
    fetchSubservicesOptions()
    if(subservicesOptions !== undefined){
      const subserviceData = subservicesOptions?.find((subservice) => subservice._id === subserviceId)
      setSelectedSubservice(subserviceData)
    }
  },[serviceId,serviceTypeId,subserviceId,fetchServicesTypesOptions,fetchSubservicesOptions]) //eslint-disable-line



  const [searchTerm, lazySearchTerm, { handleChangeSearchTerm }] = useSearchTerm('', 400) //eslint-disable-line

  const handleSearchInput = useCallback(
    (value) => {
      if (value === '') setSearchResult(null)
      handleChangeSearchTerm(value)
    },
    [handleChangeSearchTerm]
  )

  useEffect(() => {
    if (searchTerm) {
      searchSubservice({
        variables: { search: searchTerm },
      })

      setSelectedService(null)
      setSelectedServiceType(null)
      setSelectedSubservice(null)
      setBallonsOpened(false)
    }
  }, [searchTerm, searchSubservice])

  useEffect(() => {
    if (subscriptionService){
      handleSearchInput(subscriptionService)
    }
  }, [subscriptionService]) // eslint-disable-line

  const handleCloseButton = useCallback(() => {
    setSearchResult(null)
    handleChangeSearchTerm('')
    setBallonsOpened(true)
  }, [handleChangeSearchTerm, setSearchResult])

  const handleFinalPrice = useCallback((price) => {
    setFinalPrice(price)
  },[]) // eslint-disable-line 


  const handleExtraUrgentDemand = useCallback((deadline) => {
    setExtraUrgentDeadline(deadline)
  },[]) // eslint-disable-line 


  const navigationLinks = [
    { label: 'Home', onClick: () => push('/dashboard') },
    { label: 'Serviços', onClick: () => push('/dashboard/serviços') },
    { label: 'Solicitar', onClick: () => push('/dashboard/serviços/solicitar') },
  ]

  if (statusLoading || servicesOptionsLoading) {
    return <Loading />
  }


  return (
    <>
    <Flex flexDirection="column" width={1} alignItems="center">
      <Flex width={8 / 10} flexWrap="wrap">
        <Flex width={1} mb={4}>
          <BreadCrumb ml={-3} color="primary" navigationLinks={navigationLinks} />
        </Flex>
        <div className='card card-info'>
          <div style={{borderRight: "2px #7683F6 solid"}}>
            <Info className='icon'/>
          </div>
          <div>
            <p>Adquira um pacote de Seviços e obtenha sucesso! </p>
            <p>Solicite um serviço de Orçamento com um dos nossos Advogado B.onuz. Receba propostas e defina a melhor opção para você!</p>
          </div>
        </div>
        <Box width={1} my={2}>
          <Box mb={1}>
            <Label>Buscar por serviços</Label>
          </Box>
          {statusData?.me?.status !== 'pending_package' && (
            <>
              <SearchField
                focused
                id="search"
                name="search"
                disabled={subscriptionService ? true : false}
                style={{ minWidth: '100%' }}
                placeholder="Buscar..."
                value={searchTerm}
                autoComplete="off"
                onChange={(event) => handleSearchInput(event.target.value)}
                className="input"
                // onClick={() => setSearchBlur(false)}
                onBlur={() => setSearchBlur(true)}
                onFocus={() => setSearchBlur(false)}
              />
              {!!searchTerm && (
                <SearchSuggestions
                  loading={loadingSearch}
                  suggestions={dataSearch?.searchSubservice}
                  setSearchResult={setSearchResult}
                  searchBlur={searchBlur}
                  searchResult={searchResult}
                  searchTerm={searchTerm}
                  setSearchTerm={handleSearchInput}
                  handleChangeSearchTerm={handleChangeSearchTerm}
                />
              )}
            </>
          )}
          {!!searchResult && !subscriptionService && (
            <Flex mt={2} justifyContent={'flex-end'}>
              <CloseButton onClick={handleCloseButton}>
                <FontAwesomeIcon icon={faWindowClose} style={{ fontSize: '24px' }} />
                <span style={{ marginLeft: 5 }}>Limpar busca</span>
              </CloseButton>
            </Flex>
          )}
        </Box>
        {statusData?.me?.status !== 'pending_package' && !searchTerm && (serviceId === '' && serviceTypeId === '' && subserviceId === '') && (
          <Flex flexWrap="wrap" width={1} justifyContent="space-between">
            <Box width={[1, 1, 1, 3 / 10]} my={2}>
              <SelectField label="Serviços" onChange={handleServiceSelect}>
                <option value="">Selecione um serviço</option>
                {servicesOptionsLoading ? (
                  <option selected value="">
                    Carregando...
                  </option>
                ) : (
                  <>
                    {servicesOptionsData.services.map((option) => (
                      <option key={option._id} value={option._id}>
                        {option.name}
                      </option>
                    ))}
                  </>
                )}
              </SelectField>
            </Box>
            <Box width={[1, 1, 1, 3 / 10]} my={2}>
              <SelectField
                label="Tipos"
                onChange={handleServiceTypeSelect}
                inputProps={{
                  disabled: servicesOptionsLoading || servicesTypesOptionsLoading || !selectedService,
                }}
              >
                <>
                  {servicesTypesOptionsLoading ? (
                    <option selected value="">
                      Carregando...
                    </option>
                  ) : (
                    <>
                      <option value="">Selecione um tipo</option>
                      {selectedService &&
                        servicesTypesOptions?.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </>
                  )}
                </>
              </SelectField>
            </Box>
            <Box width={[1, 1, 1, 3 / 10]} my={2}>
              <SelectField
                label="Subserviços"
                onChange={handleSubservicesSelect}
                inputProps={{
                  disabled: servicesOptionsLoading || servicesTypesOptionsLoading || subservicesOptionsLoading || !selectedServiceType,
                }}
              >
                <>
                  {subservicesOptionsLoading ? (
                    <option selected value="">
                      Carregando...
                    </option>
                  ) : (
                    <>
                      <option value="">Selecione um subserviço</option>
                      {selectedServiceType &&
                        subservicesOptions?.map((option) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </>
                  )}
                </>
              </SelectField>
            </Box>
          </Flex>
        )}

        <Flex width={1} flexDirection="column" justifyContent="center" style={{marginTop: '20px'}}>
          {statusData.me.status === 'pending_package' && <StatusVariants status={statusData.me.status} />}

          {/* {statusData.me.status !== 'pending_package' && !selectedService && !searchTerm && (
            <Alert
              title="Use os filtros ou o campo de busca para encontrar o serviço que procura"
              message={'Você pode encontrar serviços específicos para sua necessidade escolhendo entre as opções do filtro acima.'}
              icon={faArrowCircleUp}
              iconSize="45px"
              mt={3}
            />
          )} */}
          {/* {selectedService && !selectedServiceType && (
            <Alert title={selectedService.name} message={selectedService.description} icon={faCopy} iconSize="45px" mt={3} />
          )} */}
          {/* {selectedService && selectedServiceType && !selectedSubservice && (
            <Alert
              title={`${selectedService.name} - ${selectedServiceType.name}`}
              message={selectedServiceType.description}
              icon={faCopy}
              iconSize="45px"
              mt={3}
            />
          )} */}
          {!searchTerm && !searchResult && selectedService && selectedServiceType && selectedSubservice && (
            <>
            {selectedSubservice?.clientPrice?.amount ?
              <>
                <div className='card card-service'>
                  <div className='card-title'>
                    <h6 style={{margin: "0", padding: "10px 0"}}>{`${selectedService.name} - ${selectedServiceType.name} - ${selectedSubservice.name}`}</h6>
                  </div>
                  <div className='card-body'>
                    <p><span style={{marginRight: '6px'}}><Calender/></span>
                      {(!extraUrgentDeadline || !selectedSubservice?.urgentDeadline) && (
                        selectedSubservice?.deadline === 1 ? `${selectedSubservice?.deadline} dia útil` : `${selectedSubservice?.deadline} dias úteis`
                      )}
                      {(extraUrgentDeadline && selectedSubservice?.urgentDeadline) && (
                        selectedSubservice?.urgentDeadline === 1 ? `${selectedSubservice?.urgentDeadline} dia útil` : `${selectedSubservice?.urgentDeadline} dias úteis`
                      )}
                    </p>
                    <div id="price-demands">
                      <Money/>
                      <strong style={{color: "#F14F5C"}}>
                        {!clientSubscription ? <FinalPrice price={finalPrice} subcriptrionIdentifier={clientSubscriptionIdentifier}/> : `R$ ${finalPrice * 2}`}
                      </strong>
                    </div>
                  </div>
                </div> 
                {selectedSubservice.description && (
                  <div className='card card-info my-3' style={{background: '#FFFFFF', borderRadius: '16px'}}>
                    <div style={{borderRight: "2px #7683F6 solid"}}>
                      <Info className='icon'/>
                    </div>
                    <div>
                      <p style={{color: '#4E4B59'}}>{selectedSubservice.description}</p>
                    </div>
                  </div>
                )}
              </>
            :
              <>
                <div className='card card-service'>
                  <div className='card-body'>
                    <h6 style={{margin: "0", padding: "10px 0"}}>{`${selectedService.name} - ${selectedServiceType.name} - ${selectedSubservice.name}`}</h6>
                  </div>
                </div>
                {selectedSubservice.description && (
                  <div className='card card-info my-3' style={{background: '#FFFFFF', borderRadius: '16px'}}>
                    <div style={{borderRight: "2px #7683F6 solid"}}>
                      <Info className='icon'/>
                    </div>
                    <div>
                      <p style={{color: '#4E4B59'}}>{selectedSubservice.description}</p>
                    </div>
                  </div>
                )}
                <div className='card card-info my-1' style={{background: '#ECF3FF'}}>
                    <div style={{borderRight: "2px #7683F6 solid"}}>
                      <Info className='icon'/>
                    </div>
                    <div>
                      <p>Este serviço exige um processo de orçamento.{clientSubscriptionBP ? <b> Somente BNZs adquiridos</b> : ''}</p>
                    </div>
                </div>
              </>
            }
              <QuestionForm serviceId={selectedService?._id} subservice={selectedSubservice} handleFinalPrice={handleFinalPrice} handleExtraUrgentDemand={handleExtraUrgentDemand} />
            </>
          )}
          {searchTerm && !!searchResult && (
            <>
            {searchResult?.subservice?.clientPrice?.amount ?
              <>
                <div className='card card-service'>
                  <div className='card-title'>
                    <h6 style={{margin: "0", padding: "10px 0"}}>{searchTerm}</h6>
                  </div>
                  <div className='card-body'>
                    <p><span style={{marginRight: '6px'}}><Calender/></span>
                      {(!extraUrgentDeadline || !searchResult.subservice.urgentDeadline) && (
                        searchResult.subservice.deadline === 1 ? `${searchResult.subservice.deadline} dia útil` : `${searchResult.subservice.deadline} dias úteis`
                      )}
                      {(extraUrgentDeadline && searchResult.subservice.urgentDeadline) && (
                        searchResult.subservice.urgentDeadline === 1 ? `${searchResult.subservice.urgentDeadline} dia útil` : `${searchResult.subservice.urgentDeadline} dias úteis`
                      )}
                    </p>
                    <div id="price-demands">
                      <Money/>
                      <strong style={{color: "#F14F5C"}}>
                        {!clientSubscription ? <FinalPrice price={finalPrice} subcriptrionIdentifier={clientSubscriptionIdentifier}/> : `R$ ${finalPrice * 2}`}
                      </strong>
                    </div>
                  </div>
                </div>
                {searchResult.subservice.description && (
                  <div className='card card-info my-3' style={{background: '#FFFFFF', borderRadius: '16px'}}>
                    <div style={{borderRight: "2px #7683F6 solid"}}>
                      <Info className='icon'/>
                    </div>
                    <div>
                      <p style={{color: '#4E4B59'}}>{searchResult.subservice.description}</p>
                    </div>
                  </div>
                )}
              </>
            :
              <>
                <div className='card card-service'>
                  <div className='card-body'>
                    <h6 style={{margin: "0", padding: "10px 0"}}>{searchTerm}</h6>
                  </div>
                </div>
                {searchResult.subservice.description && (
                  <div className='card card-info my-3' style={{background: '#FFFFFF', borderRadius: '16px'}}>
                    <div style={{borderRight: "2px #7683F6 solid"}}>
                      <Info className='icon'/>
                    </div>
                    <div>
                      <p style={{color: '#4E4B59'}}>{searchResult.subservice.description}</p>
                    </div>
                  </div>
                )}
                <div className='card card-info my-1' style={{background: '#ECF3FF'}}>
                    <div style={{borderRight: "2px #7683F6 solid"}}>
                      <Info className='icon'/>
                    </div>
                    <div>
                      <p>Este serviço exige um processo de orçamento.{clientSubscriptionBP ? <b> Somente BNZs adquiridos</b> : ''}</p>
                    </div>
                </div>
              </>
            }
              <QuestionForm serviceId={searchResult.serviceId} subservice={searchResult.subservice} handleFinalPrice={handleFinalPrice} handleExtraUrgentDemand={handleExtraUrgentDemand} />
            </>
          )}
        </Flex>
      </Flex>
      <ServicesBalloons balloonsOpen={ballonsOpened} handleSelect={selectSubserviceByHint} />
    </Flex>
    </>
  )
}

export default PurshaseService
