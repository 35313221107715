import gql from 'graphql-tag'

export const GET_SERVICES_OPTIONS = gql`
  query {
    services {
      _id
      name
      description
    }
  }
`

export const GET_SERVICESTYPES_OPTIONS = gql`
  query($serviceId: String) {
    servicesTypes(serviceId: $serviceId) {
      _id
      name
      description
    }
  }
`

export const GET_SUBSERVICES_OPTIONS = gql`
  query($typeId: String) {
    subservices(typeId: $typeId) {
      _id
      name
      description
      needsEstimate
      deadline
      urgentDeadline
      bicolunado
      extraLanguage
      urgentExtraBNZ
      clientPrice {
        amount
      }
    }
  }
`

export const SEARCH_SUBSERVICE = gql`
  query($search: String) {
    searchSubservice(search: $search) {
      _id
      name
      description
      needsEstimate
      deadline
      urgentDeadline
      bicolunado
      extraLanguage
      urgentExtraBNZ
      clientPrice {
        amount
      }

      serviceFullName
      service {
        _id
        name
        description
      }
    }
  }
`

export const GET_SUBSERVICES_QUESTIONS = gql`
  query subServicesQuestions($subserviceId: String!) {
    subServicesQuestions(subserviceId: $subserviceId) {
      _id
      description
      order
      required
      tip
      kind
      options
      allowClientUpload
      attachment
      subserviceId
      status
    }
  }
`
