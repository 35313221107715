import React  from "react";
import Modal from '@mui/material/Modal';
import "./PlansPresentation.css";
import { ReactComponent as WritingIcon } from "../../../../images/icons/writing_icon.svg";
import { ReactComponent as SearchDocumentIcon } from "../../../../images/icons/search_document_icon.svg";
import { ReactComponent as PersonVideoIcon } from "../../../../images/icons/person_video_icon.svg";
import { ReactComponent as TagsIcon } from "../../../../images/icons/tags_icon.svg";
import { ReactComponent as CrownIcon } from "../../../../images/icons/crown_icon.svg";

const PlansPresentation = (props) => {

  const { handleClose, openModal, planType } = props;

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
    >
      <div className="modal-bonuz-plans-presentation">
        {planType === 'plano-essencial' && (
          <>
            <h4>Entregáveis</h4>
            <div className="modal-bonuz-plans-presentation-details">
              <WritingIcon width={'24px'} height={'25px'} style={{fill:'#FB6D06'}} />
              <p>10% Off Na Gestão Contratual</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <SearchDocumentIcon width={'24px'} height={'25px'} style={{fill:'#FB6D06'}} />
              <p>50BNZs Call Com O Advogado</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <PersonVideoIcon width={'25px'} height={'25px'} style={{fill:'#FB6D06'}} />
              <p>1.350 BNZs Para Uso (Mensal)</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <TagsIcon width={'20px'} height={'25px'} style={{fill:'#FB6D06'}} />
              <p>10% Off Na Compra De BNZs.</p>
            </div>
          </>
      )}
      {planType === 'plano-premium' && (
          <>
            <h4>Entregáveis</h4>
            <div className="modal-bonuz-plans-presentation-details">
              <WritingIcon width={'24px'} height={'25px'} style={{fill:'#DBB412'}} />
              <p>20% Off Na Gestão Contratual</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <SearchDocumentIcon width={'24px'} height={'25px'} style={{fill:'#DBB412'}} />
              <p>15 Calls Com O Advogado</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <PersonVideoIcon width={'25px'} height={'25px'} style={{fill:'#DBB412'}} />
              <p>Cobrança Dinâmica Com Tabela Premium</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <TagsIcon width={'20px'} height={'25px'} style={{fill:'#DBB412'}} />
              <p>20% Off Na Compra De BNZs.</p>
            </div>
            <div className="modal-bonuz-plans-presentation-details">
              <CrownIcon width={'25px'} height={'16px'} style={{fill:'#DBB412'}} />
              <p>Legal Account Manager</p>
            </div>
          </>
      )}      
      </div>
    </Modal>
  );
};

export default PlansPresentation;
