import React, { useState } from "react"
import { Popover } from "@mui/material";
import './styles.css'

import LoginButtonForm from "../../LoginButtonForm";
import { ReactComponent as CoinIcon } from '../../../images/coin.svg'

const CreditsBalloon = (props) => {
  const { clientWallet, clientSubscription,clientSubscriptionIdentifier } = props

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = anchorEl;
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
    { clientSubscription && clientSubscriptionIdentifier === 'plano-essencial' ? (

      <div className="credits-balloon-wrapper">
        <LoginButtonForm
          buttonType="primaryButton"
          onClick={handleClick}
        >
          <div className="button-credits-balloon">
            Meus Créditos
          </div>
        </LoginButtonForm>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
        >
          <div className="credits-balloon-container">
            <div className="credits-ballon-header">
              <CoinIcon style={{ width: '32px', height: '32px', marginRight: '20px' }} />
              {/* {loading ? <CustomSpinner /> : data} */}
              <p>Meus créditos</p>
            </div>
            <div className="credits-ballon-content">
              <div>
                <span style={{ color: '#4E4B59' }}>Essencial</span>
                <p style={{ color: '#4E4B59' }}>*por mês e não cumalativo</p>
              </div>
              <span style={{ color: '#7683F6' }}>{clientWallet ? clientWallet.wallet.creditsBonuzPlans : 0} BNZs</span>
            </div>
            <div className="credits-ballon-content">
              <div>
                <span style={{ color: '#4E4B59' }}>Adquiridos</span>
                <p style={{ color: '#4E4B59' }}>*fora do plano mensal</p>
              </div>
              <span style={{ color: '#7683F6' }}>{clientWallet ? clientWallet.wallet.saldoFinal || 0 : 0} BNZs</span>
            </div>
          </div>
        </Popover>
      </div>
    ) : ''
  }
    </>
  )
} 

export default CreditsBalloon
