import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Flex } from 'reflexbox'
import styled from 'styled-components'
import {clientsQueries, walletQueries, demandsQueries, termsQueries} from '../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import { Loading, AlertsVariants } from '../../components'
import Button from 'ui-components/lib/components/Button'
import Modal from "ui-components/lib/components/Modal";
import useModal from "ui-components/lib/hooks/useModal";
import {clientsMutations} from "../../graphql/mutations";
import useAlertStack from "ui-components/lib/hooks/useAlertStack";
import bannerIfood from '../../images/banner_ifood.svg'
import NoticeCarrousel from '../../components/NoticeCarrousel/NoticeCarrousel'
import MyBnzResume from '../../components/MyBnzResume/MyBnzResume'
import CardInfoModal from '../../components/Modal/CardInfo/CardInfo'
import CustomSpinner from '../../components/CustomSpinner'
import {formatDate} from '../../utils/formatDate'
import Carousel from 'react-bootstrap/Carousel';
import LogoDataPrivacy from "../../assets/img/logos/dataPrivacyWhite.svg"
import LogoJP from "../../assets/img/logos/juridicoFamiliarWt.svg"
import PlansMan from '../../assets/img/plansMan.png'


import './styles.css'
import NewWelcomeModal from '../../components/Modal/NewWelcome'




const BannerPartner = styled.img`
width: 100%;
max-height: 544px;
box-sizing: border-box;
`

const Dashboard = () => {
  const history = useHistory()

  const { showMessage } = useAlertStack()

  const [openCardInfoModal, setCardInfoModal] = useState(false);
  const [modalInfo, setmModalInfo] = useState(null);
  const [userPlan,setUserPlan] = useState(undefined)

  const [lastStatements, setLastStatements] = useState([]);
  const [lastDemands, setLastDemands] = useState([]);

  const sort = 'code'
  const order = 'desc'
  const offset = 4

  // Termos de uso
  const [stillOpen,setStillOpen] = useState(true)
  let { isOpen: openTerms, closeModal: closeTerms } = useModal()
  
  const { data: privacyPolicyData} = useQuery(termsQueries.GET_PRIVACY_POLICY)

  const { data: termsOfUseData} = useQuery(termsQueries.GET_TERMS_OF_USE)
  
  const { data: statusData, loading: statusLoading } = useQuery(clientsQueries.GET_STATUS, {
    fetchPolicy: 'network-only'
  })
  const { data: myInfoData, loading: myInfoDataLoading } = useQuery(clientsQueries.GET_MY_INFOS, {
    fetchPolicy: 'network-only'
  })
  const { data: partnerData } = useQuery(clientsQueries.GET_PARTNER, {
    fetchPolicy: 'network-only'
  })

  const { loading: loadingClientSubscriptionBonuz } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
    }
  })


  const { loading: loadingClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
    }
  })

  const { loading: loadingClientSubscriptionDataPrivacy } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'data-privacy'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
    }
  })
  
  const { data: walletData, loading: walletLoading } = useQuery(walletQueries.GET_MY_WALLET)
  
  const { loading: demandsLoading, data: demandsData } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      search: '',
      sort: sort,
      page: 1,
      order,
      offset,
      queryBy: '',
      query: '',
    },
  })


  const { data: dataMyStatement, loading: loadingMyStatement } = useQuery(walletQueries.GET_MY_STATEMENTS, {
    variables: { page: 1, search: '', filterType: '', filterPeriod: '', sort: 'createdAt', order: 'desc', offset: 10 },
  })
  
  useEffect(() => {
    if (statusData && statusData?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [statusData]) // eslint-disable-line

  useEffect(() => {
    if (partnerData) {
      if (partnerData?.me?.product === 'jf' || partnerData?.me?.product === 'jp' || partnerData?.me?.partnership === 'jf' || partnerData?.me?.partnership === 'jp') {
        history.push('/dashboard/juridico-pessoal')
      }
    }
  }, [partnerData]) // eslint-disable-line

  useEffect(() => {
    if (dataMyStatement) {
      setLastStatements(dataMyStatement?.statements?.data)
      if(!myInfoDataLoading && !myInfoData?.me?.notShowPlanSelection && !myInfoData?.me?.subAccount && dataMyStatement?.statements?.data?.length === 0){
        setOpenWelcomeModal(true)
      }
    }
  }, [dataMyStatement, myInfoData, myInfoDataLoading]) // eslint-disable-line

  useEffect(() => {
    let route = null;
    const juridico = [
      'juridico-pessoal-basico',
      'juridico-pessoal-intermediario',
      'juridico-pessoal-vip'
    ];
    if(userPlan) {
      if (juridico.indexOf(userPlan) >= 0) {
        route = '/dashboard/juridico-pessoal';
      }
    }
    if (route) {
        history.push(route)
    }
  })

  useEffect(() => {
    let route = null;
    const dataprivacy = [
      'data-privacy-semestral',
      'data-privacy-anual',
    ];
    if(userPlan) {
      if (dataprivacy.indexOf(userPlan) >= 0) {
        route = '/dashboard/data-privacy';
      }
    }
    if (route) {
      history.push(route)
    }
  })

  useEffect(() => {
    if (demandsData) {
      setLastDemands(demandsData?.listDemands?.data)
    }
  }, [demandsData]) // eslint-disable-line

  const [openWelcomeModal, setOpenWelcomeModal] = useState(false)

  const handleChangeWelcome = () => {
    setOpenWelcomeModal(!openWelcomeModal)
  }
  
  const handleChangeCardInfo = (demand) => {
    setCardInfoModal(!openCardInfoModal)
    setmModalInfo(demand)
  }

  const splitDescription = (value) => {
    const splitValue = value.split("-")
    return splitValue[0]
  }

  const [acceptTermsAndPolicy] = useMutation(clientsMutations.ACCEPT_TERMS, {
    onCompleted(response) {
      showMessage({
        title: 'Termos e politicas aceitos',
        message: 'Você aceitou os termos de uso e as politicas de privacidade',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    refetchQueries: [
      {
        query: clientsQueries.GET_MY_INFOS,
      }
    ],
    onError(error) {
      showMessage({
        title: 'Erro!',
        message: 'Houve um erro ao tentar aceitar os termos e as politicas. Tente novamente',
        color: 'error',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const confirmAcceptTermsPolicy = useCallback(() => {
    acceptTermsAndPolicy()
  }, [acceptTermsAndPolicy])



  if(myInfoData?.me?.agreedPolicy !== true || myInfoData?.me?.agreedTerms !== true){
    openTerms = true
  }


  if (statusLoading || walletLoading || demandsLoading || loadingClientSubscription || loadingClientSubscriptionBonuz || loadingClientSubscriptionDataPrivacy) return <Loading />

  if (statusData?.me?.status === 'pending_package') return <AlertsVariants status={statusData?.me?.status} />
  return (
    <>
      {/* <WelcomeModal
        clientName={dataMyName?.me?.firstName}
        openModal={openWelcomeModal}
        handleClose={handleChangeWelcome}
      /> */}
      <NewWelcomeModal
        openModal={openWelcomeModal}
        handleClose={handleChangeWelcome}
      />
      <CardInfoModal
        openModal={openCardInfoModal}
        handleClose={handleChangeCardInfo}
        demand={modalInfo}
      />
      <Carousel>
      <Carousel.Item>
        <div className='data-privacy'>
          <div className="banner">
            <img src={LogoDataPrivacy} alt="Bonuz Privacy" />
              <div className="info">
                <h1 className='mb-0'>Já possui Data Privacy? </h1>
                <p>Pelo preço de um estagiário, você terceriza toda a parte de DPO/LGPD do seu negócio. </p>
                <Link to='dashboard/data-privacy'><button className='btn btn-blue px-3 py-2'>Saiba mais</button></Link >
              </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='juridico-pessoal'>
        <div className="banner">
          <img src={LogoJP} alt="Bonuz Privacy" />
          <div className="info">
              <h1>Deixe a parte chata conosco e foque na sua qualidade de vida!</h1>
              <p>*Os serviços e descontos variam conforme o plano escolhido. </p>
          </div>
        </div>
        </div>
      </Carousel.Item>
      <Carousel.Item>
      <div className='bonuz-plan'>
      <div className='banner'>
        <div>
          {/* {clientSubscription &&  <p style={{textTransform: 'uppercase'}}>PLANO {clientSubscription.planIdentifier === 'plano-essencial' ? 'essencial' : 'premium'}</p>} */}
          <h1>Assessoria Jurídica para o seu negócio!</h1>
          <p>Conheça nossos planos de Assessoria Jurídica mensal, perfeitos para STARTUPS, pequenas e médias empresas.</p>
        </div>
        <div>
          {/* {!PlansMan && <button>Saiba mais</button>} */}
          <img alt="A man using a suit handling a tablet while picks up a call on his phone" src={PlansMan}/>
        </div>
      </div>
        </div>
      </Carousel.Item>
    </Carousel>

      <Flex alignItems={'center'} flexDirection={'column'}>
        {userPlan === undefined?
          (
            <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3}>
            {(partnerData && partnerData?.me?.partnership === 'Ifood') && (
              <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3}>
                <BannerPartner src={bannerIfood} alt="Banner Ifood" />
              </Flex>
            )}
            <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3} >
              <Flex width={1/3} alignItems={'center'} justifyContent={'center'}>
                <NoticeCarrousel/>
              </Flex>
              <Flex width={2/3} alignItems={'center'} justifyContent={'center'}>
                <MyBnzResume balance={walletData?.wallet?.balance} reserved={walletData?.wallet?.reserved}/>
              </Flex>
            </Flex>
            </Flex>
          ):""}

        <Flex width={1} alignItems={'start'} justifyContent={'flex-start'}>
          {/* <Flex width={1/2} flexDirection={'column'} mt={1} p={3}>
            <div className='dashboard-demands'>
              <h3>Propostas</h3>
              {demandsLoading ? (
                <CustomSpinner />
              ) : (
                <div className='dashboard-demands-list'>
                  {lastProposals && lastProposals.length ? (
                    lastProposals.map((item, index) => {
                      return (
                        <div className='line'>
                          <div className='w-100' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                            <h4>#000{item.code} - {splitDescription(item.fullDescription)}</h4>
                            <div className='d-flex'>
                              {item.deadline ? (
                                <div>
                                  <label>Prazo</label>
                                  <p>{item.deadline} d/u</p>
                                </div>
                              ) : ('')}
                              {item.price ? (
                                <div className='ms-3'>
                                  <label>Valor</label>
                                  <p><strong>{userPlan ? (`R$ ${item.price * 2}`) : (`${item.price} BNZ`)}</strong></p>
                                </div>
                              ) : ('')}
                            </div>
                          </div>
                          <div className='d-flex h-50'>
                            <button className='btn btn-secondary mx-3'>Recusar</button>
                            <button className='btn btn-green'>Aceitar</button>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <div className='line'>
                      <div className='w-75'>
                        <h6>Não existe propostas.</h6>
                      </div>
                    </div>
                  )}
                </div>
              )}


            </div>
          </Flex> */}
                <div className='dashboard-demands me-3 mt-4'>
                  <h3>Demandas</h3>
                    {demandsLoading ? (
                      <CustomSpinner />
                    ) : (
                      <div className='dashboard-demands-list'>
                        {lastDemands && lastDemands.length ? (
                          lastDemands.map((item, index) => {
                            return (
                              <div className='line'>
                                <div className='w-100' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                                  <h4>#000{item.code} - {splitDescription(item.fullDescription)}</h4>
                                  <div className='d-flex'>
                                    {item.deadline ? (
                                      <div>
                                        <label>Prazo</label>
                                        <p>{item.deadline} d/u</p>
                                      </div>
                                    ) : ('')}
                                    {item.price ? (
                                      <div className='ms-3'>
                                        <label>Valor</label>
                                        <p><strong>{userPlan ? (`R$ ${item.price * 2}`) : (`${item.price} BNZ`)}</strong></p>
                                      </div>
                                    ) : ('')}
                                  </div>
                                </div>
                                <div className='d-flex h-50'>
                                  <Link to={'/dashboard/serviços/detalhes/'+item._id}>
                                    <button className='btn btn-green'>Ver</button>
                                  </Link >
                                </div>
                              </div>
                            )
                          })
                        ) : (
                          <div className='line'>
                            <div className='w-75'>
                              <h6>Não existe demandas.</h6>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                </div>
                <div className='dashboard-demands me-3 mt-4'>
                  <h3>Transações</h3>
                  {loadingMyStatement ? (
                    <CustomSpinner />
                  ) : (
                    <div className='dashboard-demands-list'>
                      {lastStatements && lastStatements.length ? (
                        lastStatements.map((item, index) => {
                          return (
                            <div className='line'>
                              <div className='w-75'>
                                <h6>{item.description}</h6>
                                <div className='d-flex'>
                                  <div>
                                    <p>{formatDate(item.createdAt)}</p>
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex h-50'>
                                <h3>{userPlan ? (`R$ ${item.amount * 2}`) : (`${item.amount} BNZ`)}</h3>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div className='line'>
                          <div className='w-75'>
                            <h6>Sem transações recentes.</h6>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Flex>
        {!myInfoDataLoading && (
          <Modal
            open={openTerms && stillOpen}
            onClose={closeTerms}
            boxProps={{ p: 28 }}
            contentModalProps={{ width: '80vw' }}
            header={<h3>Termos de Serviço</h3>}
            id="termsModal"
          >
            <Flex flexDirection="column">
              <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: termsOfUseData?.termsOfUse?.content }} />
              <h3>Politica de privacidade</h3>
              <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: privacyPolicyData?.privacyPolicy?.content }} />
              <Flex justifyContent="flex-end">
                <Button
                  mt={3}
                  mr={3}
                  color="primary"
                  onClick={
                    () => {
                      confirmAcceptTermsPolicy()
                      setStillOpen(false)
                    }}
                >
                  Aceitar
                </Button>
              </Flex>
            </Flex>
          </Modal>
        )}
      </Flex>
    </>
  )
}

export default Dashboard
