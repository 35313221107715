import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Flex } from 'reflexbox'
import styled from 'styled-components'
import { useQuery } from '@apollo/react-hooks'
import { clientsQueries } from '../../graphql/queries'
import whatsappLogo from '../../images/whatsapp.svg'

import CreditsBalloon from '../Modal/CreditsBalloon'

const Wrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
  height: 60px !important;
  z-index: 999;
`

const WhatsappLink = styled(Link)`
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: flex-end; 
  margin-bottom: 7%; 
  margin-right: 6%;
  text-decoration: none;
`

const SvgWraper = styled.img`
  width: 53px;
  height: 53px;
  margin-right: 2%;
  margin-bottom: 1%;
`


const Cart = props => {
    const {wallet, subscription, subscriptionIdentifier} = props
    const [whatsAppNumber, setWhatsAppNumber] = useState()
    // const [packageId, setPackageId] = useContext(DashboardContext); // eslint-disable-line

    const { data } = useQuery(clientsQueries.GET_MY_WHATSAPP, {
      onCompleted() {
        setWhatsAppNumber(data?.whatsAppContactNumber.content)
      },
    })

    return (
      <Wrapper style={{background: 'transparent', border: 'none'}} className="whatsapp-wrapper">
        {subscription && subscriptionIdentifier === 'plano-essencial' ? <CreditsBalloon clientWallet={wallet} clientSubscription={subscription} clientSubscriptionIdentifier={subscriptionIdentifier} /> : ''}
        <div className='whatsapp'>
          <WhatsappLink
            to={{ pathname: `https://api.whatsapp.com/send?phone=${whatsAppNumber?.replace(/\D+/g, '')}&text=Oi%2C%20tudo%20bem%3F` }}
            target="_blank"
          >          
            <SvgWraper src={whatsappLogo} alt="whatsapp-logo" />
          </WhatsappLink>
        </div>
      </Wrapper>
    )
}

export default Cart
