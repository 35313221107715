import React, { useState, useEffect, useLayoutEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import Box from '@mui/material/Box'

import { walletQueries, clientsQueries } from '../../graphql/queries'
import Logo from "../../assets/img/logos/dataPrivacyWhite.svg"
import { Hints } from '../../components'
import CustomSpinner from '../../components/CustomSpinner'
import "./styles.css"
import View from "./views";
import Documents from '../../components/DataPrivacy/Documents/Documents'
import Users from '../../components/DataPrivacy/Users/Users'
import Chat from "../../components/DataPrivacy/Chat/Chat";
import ActivitiesStatus from '../../components/DataPrivacy/ActivitiesStatus/AcitivitiesStatus'
import ActivitiesSchedule from "../../components/DataPrivacy/ActivitiesSchedule/ActivitiesSchedule";


const DataPrivacy = () => {

  const { data: dataClientSubscription, loading: loadingClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'data-privacy'
    },
  })

  const { data: dataAccountPermissions } = useQuery(clientsQueries.GET_ACCOUNT_PERMISSIONS)
 
  const [clientSubscription, setClientSubscription] = useState('')
  const [view, setView] = useState(0);
  const [userAccountType, setUserAccountType] = useState(false)

  const history = useHistory()

  if(clientSubscription && !clientSubscription.planIdentifier && !clientSubscription.active) {
    history.push('/dashboard/data-privacy/pagamento')
  }

  useEffect(() => {
    if(dataAccountPermissions){
      setUserAccountType(dataAccountPermissions.getAccountPermissions.type)
    }
  }, [dataAccountPermissions])

  useLayoutEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  return (
    <div className="data-privacy">
      {loadingClientSubscription ? (
        <CustomSpinner />
      ) : (
        clientSubscription.planIdentifier ? (
          !clientSubscription.active && (
            <>
              <div className="banner">
                <img src={Logo} alt="Bonuz Privacy" />
                <div className="info">                    
                  <h1>Sua assinatura está inativa!</h1>
                  <p>
                    A sua assinatura para o plano
                      {clientSubscription.planIdentifier === 'data-privacy-semestral' && ' semestral '}
                      {clientSubscription.planIdentifier === 'data-privacy-anual' && ' anual '}
                    do data privacy não está ativa.
                  </p>
                  <p>Se você deseja renovar, modificar seu plano ou possui quaisquer dúvidas, entre em contato conosco.</p>
                </div>
              </div>
              <Box className="proposals-header-wrapper mt-3">
                <Hints
                  hintInfo={'A adequação à LGPD (Lei Federal nº 13.709/2018) é uma obrigação legal que deve ser atendida por todas as empresas. O DPO é uma função exigida pela LGPD, sendo o responsável por gerenciar a conformidade com as leis de proteção de dados pessoais.'}
                />
              </Box>              
            </>
          )
        ) : (
          <>
            <div className="banner">
              <img src={Logo} alt="Bonuz Privacy" />
              <div className="info">
                <h1>Pelo preço de um estagiário, você terceriza toda a parte de DPO/LGPD do seu negócio. </h1>
                <p>Quer orçar? É bem simples, basta clicar em ‘Assinar plano’ e responder nossas perguntas, dura menos de 2 minutos.</p>
              </div>
            </div>
            <Box className="proposals-header-wrapper mt-3">
              <Hints
                hintInfo={'A adequação à LGPD (Lei Federal nº 13.709/2018) é uma obrigação legal que deve ser atendida por todas as empresas. O DPO é uma função exigida pela LGPD, sendo o responsável por gerenciar a conformidade com as leis de proteção de dados pessoais.'}
              />
            </Box>
          </>
        )
      )}
      {!loadingClientSubscription && !clientSubscription.planIdentifier ? (
        <View view={view} setView={setView}/>
      ) : (
        clientSubscription.active ? (
          <div className="container">
            <h2 className="pt-4" style={{color: '#2D4677', fontWeight: '700'}}>
              Plano
              {clientSubscription.planIdentifier === 'data-privacy-semestral' && ' semestral '}
              {clientSubscription.planIdentifier === 'data-privacy-anual' && ' anual '}            
            </h2>
            <Row className="pt-4">
              <Documents clientId={clientSubscription?.clientId}/>
              {userAccountType === 'Owner' ? (
                <Users/> 
              ) : ('')}
              <Chat/>
            </Row>
            <Row className="pt-4">
              <Col sm="7">
                <ActivitiesStatus clientId={clientSubscription?.clientId}/>
              </Col>
              <Col sm="5">
                <ActivitiesSchedule />
              </Col>
            </Row>
          </div>
        ) : ('')
      )}
    </div>
   );
}
 
export default DataPrivacy;
