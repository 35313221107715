import React, { useEffect, useState, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Flex } from 'reflexbox'
import styled from 'styled-components'
import {clientsQueries, walletQueries, demandsQueries, termsQueries} from '../../graphql/queries'
import {useMutation, useQuery} from '@apollo/react-hooks'
import { Loading, AlertsVariants, PurchaseBNZ } from '../../components'
import Button from 'ui-components/lib/components/Button'
import Modal from "ui-components/lib/components/Modal";
import useModal from "ui-components/lib/hooks/useModal";
import {clientsMutations} from "../../graphql/mutations";
import useAlertStack from "ui-components/lib/hooks/useAlertStack";
import bannerIfood from '../../images/banner_ifood.svg'
import NoticeCarrousel from '../../components/NoticeCarrousel/NoticeCarrousel'
import MyBnzResume from '../../components/MyBnzResume/MyBnzResume'
import CardInfoModal from '../../components/Modal/CardInfo/CardInfo'
import CustomSpinner from '../../components/CustomSpinner'
import {formatDate} from '../../utils/formatDate'
import Carousel from 'react-bootstrap/Carousel';
import LogoDataPrivacy from "../../assets/img/logos/dataPrivacyWhite.svg"
import LogoJP from "../../assets/img/logos/juridicoFamiliarWt.svg"
import PlansMan from '../../assets/img/plansMan.png'
import IPlus from '../../assets/img/bonuzPlan/plus.js'
import {ReactComponent as IInfo} from '../../assets/img/bonuzPlan/info.svg'
import {ReactComponent as IGift} from '../../assets/img/bonuzPlan/gift.svg'
import IPerson from '../../assets/img/bonuzPlan/person.js'
import IAttorney from '../../assets/img/bonuzPlan/attorney.js'
import ILogo from '../../assets/img/bonuzPlan/logo.js'
import {ReactComponent as IDone} from '../../assets/img/bonuzPlan/done.svg'
import {ReactComponent as IInProgress} from '../../assets/img/bonuzPlan/inprogress.svg'
import {ReactComponent as IWarning} from '../../assets/img/bonuzPlan/warning.svg'
import {ReactComponent as IDemandDone} from '../../assets/img/bonuzPlan/demanddone.svg'
import {ReactComponent as IDemandPending} from '../../assets/img/bonuzPlan/demandpending.svg'
import {ReactComponent as IInProgressB} from '../../assets/img/bonuzPlan/in_progress.svg'
import Tooltip from '@mui/material/Tooltip'
import BonuzPlansModal from '../../components/Modal/BonuzPlans/BonuzPlansModal'
import RequestService from '../../components/Modal/BonuzPlans/ResquestService'
import CardInfo from '../../components/BonuzPlans/CardInfo'
import {cardsList} from './scripts'
import ChatDemand from '../../components/ChatDemand'


import './styles.css'
import './cards.css'
import NewWelcomeModal from '../../components/Modal/NewWelcome'

const BannerPartner = styled.img`
width: 100%;
max-height: 544px;
box-sizing: border-box;
`
const BonuzPlans = () => {
  const history = useHistory()

  const { showMessage } = useAlertStack()

  const [openCardInfoModal, setCardInfoModal] = useState(false);
  const [modalInfo, setmModalInfo] = useState(null);
  const [userPlan,setUserPlan] = useState(undefined)
  const [userPlanDescription, setUserPlanDescription] = useState('Freemium')
  const [classBannerBonuzPlans, setClassBannerBonuzPlans] = useState('')
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [lastStatements, setLastStatements] = useState([]);
  const [lastDemands, setLastDemands] = useState([]);
  const [countDemandsDone, setCountDemandsDone] = useState(0);
  const [countDemandsInProgress, setCountDemandsInProgress] = useState(0);
  const [countDemandsInReview, setCountDemandsInReview] = useState(0);
  const [countDemandsPending, setCountDemandsPending] = useState(0);
  const [openModalServices, setOpenModalServices] = useState(false)
  const [whatsAppNumber, setWhatsAppNumber] = useState()
  const [requestedServices] = useState(false)
  const [openModalRequestService, setOpenModalRequestService] = useState(false)
  const [openChatModal, setOpenChatModal] = useState(false);

  const sort = 'code'
  const order = 'desc'
  const offset = 4

  // Termos de uso
  const [stillOpen,setStillOpen] = useState(true)
  let { isOpen: openTerms, closeModal: closeTerms } = useModal()
  
  const { data: privacyPolicyData} = useQuery(termsQueries.GET_PRIVACY_POLICY)

  const { data: termsOfUseData} = useQuery(termsQueries.GET_TERMS_OF_USE)
  
  const { data: statusData, loading: statusLoading } = useQuery(clientsQueries.GET_STATUS, {
    fetchPolicy: 'network-only'
  })
  const { data: myInfoData, loading: myInfoDataLoading } = useQuery(clientsQueries.GET_MY_INFOS, {
    fetchPolicy: 'network-only'
  })
  const { data: partnerData } = useQuery(clientsQueries.GET_PARTNER, {
    fetchPolicy: 'network-only'
  })

  const { loading: loadingClientSubscriptionBonuz } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    },
    onCompleted(data){
      setUserPlan(data?.subscription?.planIdentifier)
      setUserPlanDescriptionText(data?.subscription?.planIdentifier)
    }
  })

  const setUserPlanDescriptionText = (text) => {
    let description = '';
    let classe = '';
    if (text === 'plano-essencial') {
      description = 'Plano Essencial'
      classe += 'essencial'
    }
    if (text === 'plano-premium') {
      description = 'Plano Premium'
      classe += 'premium'
    }
    setUserPlanDescription(description)
    setClassBannerBonuzPlans(classe)
  }
  
  const { data: walletData, loading: walletLoading } = useQuery(walletQueries.GET_MY_WALLET)
  
  const { loading: demandsLoading, data: demandsData } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      search: '',
      sort: sort,
      page: 1,
      order,
      offset,
      queryBy: '',
      query: '',
    },
  })

  const { loading: demandsLoadingDone, data: demandsDataDone } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'done',
    },
  })

  const { loading: demandsLoadingInProgrees, data: demandsDataInProgress } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_progress',
    },
  })

  const { loading: demandsLoadingInReview, data: demandsDataInReview } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'in_review',
    },
  })

  const { loading: demandsLoadingPending, data: demandsDataPending } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      query: 'pending',
    },
  })

  const { data } = useQuery(clientsQueries.GET_MY_WHATSAPP, {
    onCompleted() {
      setWhatsAppNumber(data?.whatsAppContactNumber.content)
    },
  })

  const modalOpenRequestService = () => {
    setOpenModalRequestService(!openModalRequestService)
  }

  const toRoute = (route) => history.push(route)

  const { data: dataMyStatement, loading: loadingMyStatement, refetch: refetchMyStatements } = useQuery(walletQueries.GET_MY_STATEMENTS, {
    variables: { page: 1, search: '', filterType: '', filterPeriod: '', sort: 'createdAt', order: 'desc', offset: 10 },
  })
  
  useEffect(() => {
    if (statusData && statusData?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [statusData]) // eslint-disable-line

  useEffect(() => {
    if (partnerData) {
      if (partnerData?.me?.product === 'jf' || partnerData?.me?.product === 'jp' || partnerData?.me?.partnership === 'jf' || partnerData?.me?.partnership === 'jp') {
        history.push('/dashboard/juridico-pessoal')
      }
    }
  }, [partnerData]) // eslint-disable-line

  useEffect(() => {
    if (dataMyStatement) {
      setLastStatements(dataMyStatement?.statements?.data)
      if(!myInfoDataLoading && !myInfoData?.me?.notShowPlanSelection && !myInfoData?.me?.subAccount && dataMyStatement?.statements?.data?.length === 0){
        setOpenWelcomeModal(true)
      }
    }
  }, [dataMyStatement, myInfoData, myInfoDataLoading]) // eslint-disable-line


  useEffect(() => {
    if (demandsData) {
      setLastDemands(demandsData?.listDemands?.data)
    }
  }, [demandsData]) // eslint-disable-line

  useEffect(() => {
    let valor = 0;
    if (demandsDataDone) {
      valor = demandsDataDone?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsDone(valor)
  }, [demandsDataDone])

  useEffect(() => {
    let valor = 0;
    if (demandsDataInProgress) {
      valor = demandsDataInProgress?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsInProgress(valor)
  }, [demandsDataInProgress])

  useEffect(() => {
    let valor = 0;
    if (demandsDataInReview) {
      valor = demandsDataInReview?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsInReview(valor)
  }, [demandsDataInReview])

  useEffect(() => {
    let valor = 0;
    if (demandsDataPending) {
      valor = demandsDataPending?.listDemands?.pagination?.totalRecords || 0;
    }
    setCountDemandsPending(valor)
  }, [demandsDataPending])

  const modalHandlerServices = () => {
    setOpenModalServices(!openModalServices)
  }

  const [openWelcomeModal, setOpenWelcomeModal] = useState(false)

  const handleChangeWelcome = () => {
    setOpenWelcomeModal(!openWelcomeModal)
  }
  
  const handleChangeCardInfo = (demand) => {
    setCardInfoModal(!openCardInfoModal)
    setmModalInfo(demand)
  }

  const splitDescription = (value) => {
    const splitValue = value.split("-")
    return splitValue[0]
  }

  const [acceptTermsAndPolicy] = useMutation(clientsMutations.ACCEPT_TERMS, {
    onCompleted(response) {
      showMessage({
        title: 'Termos e politicas aceitos',
        message: 'Você aceitou os termos de uso e as politicas de privacidade',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
    },
    refetchQueries: [
      {
        query: clientsQueries.GET_MY_INFOS,
      }
    ],
    onError(error) {
      showMessage({
        title: 'Erro!',
        message: 'Houve um erro ao tentar aceitar os termos e as politicas. Tente novamente',
        color: 'error',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const confirmAcceptTermsPolicy = useCallback(() => {
    acceptTermsAndPolicy()
  }, [acceptTermsAndPolicy])

  const openModal = useCallback(() => {
    setModalIsOpen(true)
  }, [])

  const closeModalPurchaseBNZ = useCallback(() => {
    setModalIsOpen(false)
    refetchMyStatements()
  }, []) // eslint-disable-line

  if(myInfoData?.me?.agreedPolicy !== true || myInfoData?.me?.agreedTerms !== true){
    openTerms = true
  }

  if (
    statusLoading || 
    walletLoading || 
    demandsLoading || 
    loadingClientSubscriptionBonuz || 
    demandsLoadingDone || 
    demandsLoadingInProgrees || 
    demandsLoadingInReview ||
    demandsLoadingPending
  ) return <Loading />

  if (statusData?.me?.status === 'pending_package') return <AlertsVariants status={statusData?.me?.status} />
  return (
    userPlan ? (
      <>
        <BonuzPlansModal 
          openModal={openModalServices}
          handleClose={modalHandlerServices}
          whatsAppNumber={whatsAppNumber}
        />
        <RequestService
          openModal={openModalRequestService}
          handleClose={modalOpenRequestService}
          requestedService={requestedServices}
        />
        <NewWelcomeModal
          openModal={openWelcomeModal}
          handleClose={handleChangeWelcome}
        />
        <CardInfoModal
          openModal={openCardInfoModal}
          handleClose={handleChangeCardInfo}
          demand={modalInfo}
        />
        <ChatDemand
          handleClose={()=>setOpenChatModal(false)}
          openModal={openChatModal}
          subscription={'planos-bonuz'}
        />
        <Carousel>
          <Carousel.Item>
            <div className={`bonuz-plan ${classBannerBonuzPlans}`}>
              <div className='banner'>
                <div>
                  {(userPlan && <p style={{textTransform: 'uppercase', marginBottom: 0}}>{userPlanDescription}</p>)}
                  <h1 style={{marginTop: 0}}>Assessoria Jurídica para o seu negócio!</h1>
                  <p>Conheça nossos planos de Assessoria Jurídica mensal, perfeitos para STARTUPS, pequenas e médias empresas.</p>
                </div>
                <div>
                  {/* {!PlansMan && <button>Saiba mais</button>} */}
                  <img alt="A man using a suit handling a tablet while picks up a call on his phone" src={PlansMan}/>
                </div>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item>
          <div className='juridico-pessoal'>
            <div className="banner">
              <img src={LogoJP} alt="Bonuz Privacy" />
              <div className="info">
                  <h1>Deixe a parte chata conosco e foque na sua qualidade de vida!</h1>
                  <p>*Os serviços e descontos variam conforme o plano escolhido. </p>
              </div>
            </div>
          </div>
          </Carousel.Item>
          <Carousel.Item>
            <div className='data-privacy'>
              <div className="banner">
                <img src={LogoDataPrivacy} alt="Bonuz Privacy" />
                  <div className="info">
                    <h1 className='mb-0'>Já possui Data Privacy? </h1>
                    <p>Pelo preço de um estagiário, você terceriza toda a parte de DPO/LGPD do seu negócio. </p>
                    <Link to='dashboard/data-privacy'><button className='btn btn-blue px-3 py-2'>Saiba mais</button></Link >
                  </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
        <Flex alignItems={'center'} flexDirection={'column'}>
          {userPlan === undefined?
            (
              <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3}>
              {(partnerData && partnerData?.me?.partnership === 'Ifood') && (
                <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3}>
                  <BannerPartner src={bannerIfood} alt="Banner Ifood" />
                </Flex>
              )}
              <Flex width={1} alignItems={'center'} justifyContent={'center'} mt={3} >
                <Flex width={1/3} alignItems={'center'} justifyContent={'center'}>
                  <NoticeCarrousel/>
                </Flex>
                <Flex width={2/3} alignItems={'center'} justifyContent={'center'}>
                  <MyBnzResume balance={walletData?.wallet?.balance} reserved={walletData?.wallet?.reserved}/>
                </Flex>
              </Flex>
              </Flex>
            ):""}

          {userPlan === undefined && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'}>
              {/* <Flex width={1/2} flexDirection={'column'} mt={1} p={3}>
                <div className='dashboard-demands'>
                  <h3>Propostas</h3>
                  {demandsLoading ? (
                    <CustomSpinner />
                  ) : (
                    <div className='dashboard-demands-list'>
                      {lastProposals && lastProposals.length ? (
                        lastProposals.map((item, index) => {
                          return (
                            <div className='line'>
                              <div className='w-100' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                                <h4>#000{item.code} - {splitDescription(item.fullDescription)}</h4>
                                <div className='d-flex'>
                                  {item.deadline ? (
                                    <div>
                                      <label>Prazo</label>
                                      <p>{item.deadline} d/u</p>
                                    </div>
                                  ) : ('')}
                                  {item.price ? (
                                    <div className='ms-3'>
                                      <label>Valor</label>
                                      <p><strong>{userPlan ? (`R$ ${item.price * 2}`) : (`${item.price} BNZ`)}</strong></p>
                                    </div>
                                  ) : ('')}
                                </div>
                              </div>
                              <div className='d-flex h-50'>
                                <button className='btn btn-secondary mx-3'>Recusar</button>
                                <button className='btn btn-green'>Aceitar</button>
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div className='line'>
                          <div className='w-75'>
                            <h6>Não existe propostas.</h6>
                          </div>
                        </div>
                      )}
                    </div>
                  )}


                </div>
              </Flex> */}
              <div className='dashboard-demands me-3 mt-4'>
                <h3>Demandas</h3>
                  {demandsLoading ? (
                    <CustomSpinner />
                  ) : (
                    <div className='dashboard-demands-list'>
                      {lastDemands && lastDemands.length ? (
                        lastDemands.map((item, index) => {
                          return (
                            <div className='line'>
                              <div className='w-100' style={{whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
                                <h4>#000{item.code} - {splitDescription(item.fullDescription)}</h4>
                                <div className='d-flex'>
                                  {item.deadline ? (
                                    <div>
                                      <label>Prazo</label>
                                      <p>{item.deadline} d/u</p>
                                    </div>
                                  ) : ('')}
                                  {item.price ? (
                                    <div className='ms-3'>
                                      <label>Valor</label>
                                      <p><strong>{userPlan ? (`R$ ${item.price * 2}`) : (`${item.price} BNZ`)}</strong></p>
                                    </div>
                                  ) : ('')}
                                </div>
                              </div>
                              <div className='d-flex h-50'>
                                <Link to={'/dashboard/serviços/detalhes/'+item._id}>
                                  <button className='btn btn-green'>Ver</button>
                                </Link >
                              </div>
                            </div>
                          )
                        })
                      ) : (
                        <div className='line'>
                          <div className='w-75'>
                            <h6>Não existe demandas.</h6>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
              </div>
              <div className='dashboard-demands me-3 mt-4'>
                <h3>Transações</h3>
                {loadingMyStatement ? (
                  <CustomSpinner />
                ) : (
                  <div className='dashboard-demands-list'>
                    {lastStatements && lastStatements.length ? (
                      lastStatements.map((item, index) => {
                        return (
                          <div className='line'>
                            <div className='w-75'>
                              <h6>{item.description}</h6>
                              <div className='d-flex'>
                                <div>
                                  <p>{formatDate(item.createdAt)}</p>
                                </div>
                              </div>
                            </div>
                            <div className='d-flex h-50'>
                              <h3>{userPlan ? (`R$ ${item.amount * 2}`) : (`${item.amount} BNZ`)}</h3>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div className='line'>
                        <div className='w-75'>
                          <h6>Sem transações recentes.</h6>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </Flex>
          )}
          {(userPlan === 'plano-essencial' || userPlan === 'plano-premium') && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'}>
              <Flex width={1} flexDirection={'column'} mt={1} p={2} className='services'>
                Serviços
              </Flex>
              <Flex width={1/2} flexDirection={'column'} mt={1} p={2}>
                <button type="button" className={userPlan === 'pĺano-essencial' ? 'open-demand' : 'open-demand-premium'} onClick={() => toRoute('/dashboard/serviços/solicitar')}>
                  {userPlan === 'plano-premium' && (
                    <IPlus style={{marginRight: 5}} />
                  )}
                  <span style={{marginLeft: '5px'}}>Abra uma demanda</span>
                </button>
              </Flex>
              <Flex width={1/2} flexDirection={'column'} mt={1} p={2}>
                <buytton type="button" className={userPlan === 'plano-premium' ? 'open-service-premium' : 'open-service'} onClick={modalHandlerServices}>
                  <IPlus cor={userPlan === 'plano-premium' ? '#000000' : '#FFFFFF'} /> 
                  <span style={{marginLeft: '5px'}}>Inclua serviços ao seu plano</span>
                </buytton>
              </Flex>
              {userPlan === 'plano-essencial' && (
                <Flex width={1/2} flexDirection={'column'} mt={1} p={2}>
                  <button type="button" className="open-upgrade" onClick={modalOpenRequestService}>Faça o upgrade do seu plano</button>
                </Flex>
              )}
            </Flex>
          )}
          {(userPlan === 'plano-essencial' || userPlan === 'plano-premium') && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'} pt={4}>
              {userPlan === 'plano-essencial' && (
                <div className={`bonuz-cards border-${userPlan}`}>
                  <Tooltip 
                    title={
                      <>
                        Você poderá utilizar até 1.350 BNZ mensalmente
                      </>
                    }
                    placement="right"
                    className='group-two'
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#7683F6',
                          '& .MuiTooltip-arrow': {
                            color: '#7683F6',
                          },
                        },
                      },
                    }}
                  >
                    <IInfo style={{cursor: 'pointer'}} />
                  </Tooltip>
                  <div className='card-frame'>
                    <div className='card-one'>
                      <div className='card-two'><IGift /></div>
                    </div>
                    <div className='card-description-one'>
                      1.350 BNZ para uso (mensal)
                    </div>
                  </div>
                  <button type='button' className={`card-solicitar color-${userPlan}`} onClick={() => toRoute('/dashboard/serviços/solicitar')}>Solicitar</button>
                </div>
              )}
              <div className={`bonuz-cards border-${userPlan}`}>
                <Tooltip 
                  title={
                    <>
                      Solicite 1 call por mês para consultoria com Advogado. Você poderá comprar um call adicional sempre que precisar!
                    </>
                  }
                  placement="right"
                  className='group-two'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#7683F6',
                        '& .MuiTooltip-arrow': {
                          color: '#7683F6',
                        },
                      },
                    },
                  }}
                >
                  <IInfo style={{cursor: 'pointer'}} />
                </Tooltip>
                <div className='card-frame'>
                  <div className='card-one'>
                    <div className='card-two'><IPerson cor={userPlan === 'plano-essencial' ? '#FB6D06' : '#000000'} /></div>
                  </div>
                  <div className='card-description-one'>
                    Solicite um call
                  </div>
                </div>
                <button type='button' className={`card-solicitar color-${userPlan}`} onClick={() => toRoute('/dashboard/fala-advogado')}>Solicitar</button>
              </div>
              {userPlan === 'plano-premium' && (
                <div className={`bonuz-cards border-${userPlan}`}>
                  <Tooltip 
                    title={
                      <>
                        Um advogado da Bonuz que estará como responsável em receber demandas da sua empresa e estará acompanhando seu negócio de perto.
                      </>
                    }
                    placement="right"
                    className='group-two'
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#7683F6',
                          '& .MuiTooltip-arrow': {
                            color: '#7683F6',
                          },
                        },
                      },
                    }}
                  >
                    <IInfo style={{cursor: 'pointer'}} />
                  </Tooltip>
                  <div className='card-frame'>
                    <div className='card-one'>
                      <div className='card-two'><IAttorney cor={userPlan === 'plano-essencial' ? '#FB6D06' : '#000000'}/></div>
                    </div>
                    <div className='card-description-one'>
                      Tenha um Legal Account Manager
                    </div>
                  </div>
                  <a href='https://api.whatsapp.com/send?phone=5548991548687&text=Eu quero um Legal Account Manager' target='_blank' rel="noopener noreferrer" className='entrar-em-contato'>
                    ENTRAR EM CONTATO
                  </a>
                </div>
              )}
              <div className={`bonuz-cards border-${userPlan}`}>
                <Tooltip 
                  title={
                    <>
                      Compre BNZ adicional
                    </>
                  }
                  placement="right"
                  className='group-two'
                  componentsProps={{
                    tooltip: {
                      sx: {
                        bgcolor: '#7683F6',
                        '& .MuiTooltip-arrow': {
                          color: '#7683F6',
                        },
                      },
                    },
                  }}
                >
                  <IInfo style={{cursor: 'pointer'}} />
                </Tooltip>
                <div className='card-frame'>
                  <div className='card-two'><ILogo cor={userPlan === 'plano-essencial' ? '#FB6D06' : '#000000'} /></div>
                  <div className='card-description'>
                    <span className='card-text'>
                      Comprar BNZ
                      <br/>
                      <br/>
                    </span>
                    <span className='card-text-2'>
                      10%off
                    </span>
                  </div>
                </div>
                <button type='button' className={`card-comprar color-${userPlan}`} onClick={openModal}>Comprar</button>
              </div>
              {userPlan === 'plano-essencial' && (
                <div className={`bonuz-cards border-${userPlan}`}>
                  <Tooltip 
                    title={
                      <>
                        Um advogado da Bonuz que estará como responsável em receber demandas da sua empresa e estará acompanhando seu negócio de perto.
                      </>
                    }
                    placement="right"
                    className='group-two'
                    componentsProps={{
                      tooltip: {
                        sx: {
                          bgcolor: '#7683F6',
                          '& .MuiTooltip-arrow': {
                            color: '#7683F6',
                          },
                        },
                      },
                    }}
                  >
                    <IInfo style={{cursor: 'pointer'}} />
                  </Tooltip>
                  <div className='card-frame'>
                    <div className='card-one'>
                      <div className='card-two'><IAttorney cor={userPlan === 'plano-essencial' ? '#FB6D06' : '#000000'}/></div>
                    </div>
                    <div className='card-description-one'>
                      Tenha um Legal Account Manager
                    </div>
                  </div>
                  <button type='button' className='card-btn-upgrade' onClick={modalOpenRequestService}>
                    Upgrade
                  </button>
                </div>
              )}
            </Flex>
          )}
          {(userPlan === 'plano-essencial' || userPlan === 'plano-premium') && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'} pt={4}>
              <div className='card-andamento'>
                <span className='andamento-geral'>Andamento das demandas gerais</span>
                {
                  (countDemandsDone + countDemandsInProgress + countDemandsInReview) > 0 && (
                    <>
                      <div className='card-bar'>
                        <div className='success' style={{width: `${countDemandsDone / (countDemandsDone + countDemandsInProgress + countDemandsInReview) * 100 }%`}}></div>
                        <div className='in-progress' style={{width: `${countDemandsInProgress / (countDemandsDone + countDemandsInProgress + countDemandsInReview) * 100 }%`}}></div>
                        <div className='warning' style={{width: `${countDemandsInReview / (countDemandsDone + countDemandsInProgress + countDemandsInReview) * 100 }%`}}></div>
                      </div>
                      <div className='legend'>
                        {countDemandsDone > 0 && (
                          <button type='button' className='status' onClick={() => toRoute('/dashboard/serviços?status=done')}><IDone /> Concluído ({countDemandsDone})</button>
                        )}
                        {countDemandsInProgress > 0 && (
                          <button type='button' className='status' onClick={() => toRoute('/dashboard/serviços?status=in_progress')}><IInProgress /> Em Progresso ({countDemandsInProgress})</button>
                        )}
                        {countDemandsInReview > 0 && (
                          <button type='button' className='status' onClick={() => toRoute('/dashboard/serviços?status=in_review')}><IWarning /> Revisar ({countDemandsInReview})</button>
                        )}
                      </div>  
                    </>
                )}
                {
                  (countDemandsDone + countDemandsInProgress + countDemandsInReview) === 0 && (
                    <div className='legend'>
                      <span className='status'>Nenhuma demanda foi encontrada</span>
                    </div>
                  )
                }
              </div>
            </Flex>
          )}
          {(userPlan === 'plano-essencial' || userPlan === 'plano-premium') && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'} pt={4}>
              <div className='card-demands'>
                <span className='demand'>
                  Demandas concluídas gerais
                </span>
                <div className='icon'><IDemandDone /></div>
                <span className='number'>{countDemandsDone}</span> 
                {countDemandsDone > 0 && (
                  <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços?status=done')}>Ver detalhes</button>
                )}
              </div>
            </Flex>
          )}

          {userPlan === 'plano-essencial' && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'} pt={3}>
              <div className='card-demands'>
                <span className='demand'>
                  Pendências gerais
                </span>
                <div className='icon'><IDemandPending /></div>
                <span className='number'>{countDemandsPending}</span>
                {countDemandsPending > 0 && (
                  <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços?status=pending')}>Ver detalhes</button>
                )}
              </div>
            </Flex>
          )}

          {userPlan === 'plano-premium' && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'} pt={3}>
              <div className='card-demands'>
                <span className='demand'>
                  Em Revisão  
                </span>
                <div className='icon'><IDemandPending /></div>
                <span className='number'>{countDemandsInReview}</span>
                {countDemandsInReview > 0 && (
                  <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços?status=in_review')}>Ver detalhes</button>
                )}
              </div>
            </Flex>
          )}

          {userPlan === 'plano-premium' && (
            <Flex width={1} alignItems={'start'} justifyContent={'flex-start'} pt={3}>
              <div className='card-demands'>
                <span className='demand'>
                  Em andamento  
                </span>
                <div className='icon'><IInProgressB /></div>
                <span className='number'>{countDemandsInProgress}</span>
                {countDemandsInProgress > 0 && (
                  <button type='button' className='details' onClick={() => toRoute('/dashboard/serviços?status=in_progress')}>Ver detalhes</button>
                )}
              </div>
            </Flex>
          )}

          {!myInfoDataLoading && (
            <Modal
              open={openTerms && stillOpen}
              onClose={closeTerms}
              boxProps={{ p: 28 }}
              contentModalProps={{ width: '80vw' }}
              header={<h3>Termos de Serviço</h3>}
              id="termsModal"
            >
              <Flex flexDirection="column">
                <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: termsOfUseData?.termsOfUse?.content }} />
                <h3>Politica de privacidade</h3>
                <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: privacyPolicyData?.privacyPolicy?.content }} />
                <Flex justifyContent="flex-end">
                  <Button
                    mt={3}
                    mr={3}
                    color="primary"
                    onClick={
                      () => {
                        confirmAcceptTermsPolicy()
                        setStillOpen(false)
                      }}
                  >
                    Aceitar
                  </Button>
                </Flex>
              </Flex>
            </Modal>
          )}
          <Modal
            open={modalIsOpen}
            onClose={() => {
              closeModalPurchaseBNZ()
            }}
            disableAutoFocus
            sx={{zIndex: '9999'}}
          >
            <PurchaseBNZ
              closeParentModal={() => {
                closeModalPurchaseBNZ()
              }}
            />
          </Modal>
        </Flex>
      </>
    )
    : (
      <div className='bonuz-plan'>
        <div className='banner'>
          <div>
            <h1 style={{marginTop: 0}}>Assessoria Jurídica para o seu negócio!</h1>
            <p>Conheça nossos planos de Assessoria Jurídica mensal, perfeitos para STARTUPS, pequenas e médias empresas.</p>
          </div>
          <div>
            {!PlansMan && <button>Saiba mais</button>}
            <img alt="A man using a suit handling a tablet while picks up a call on his phone" src={PlansMan}/>
          </div>
        </div>
        <div className='cards'>
          {cardsList.map(info => (
            <CardInfo info={info}/>
          ))}
        </div>
      </div>
    )
  )
}

export default BonuzPlans
