import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Col, InputGroup } from 'react-bootstrap';
import { FaMinus, FaPlus } from 'react-icons/fa'

const EmailManager = ({ onEmailsChange, required = true, initialEmails }) => {
  const [emails, setEmails] = useState(['']);
   const [errors, setErrors] = useState([]); 
  
  useEffect(() => {
    setEmails(initialEmails)
  }, [initialEmails])

  const handleEmailChange = (index, event) => {
    const email = event.target.value;
    const newEmails = [...emails];
    newEmails[index] = email;
    const newErrors = [ ...errors];
    const valid = validateEmail(email);
    const exists = newErrors.findIndex(item => Number(item) === Number(index));
    if (exists >= 0) {
      newErrors.splice(exists, 1);
    }
    if (!valid) {
      newErrors.push(index);
    }
    setErrors(newErrors);
    setEmails(newEmails);
    onEmailsChange(newEmails);
  };

  const handleAddEmail = () => {
    setEmails([...emails, '']);
  };

  const handleRemoveEmail = (index) => {
    const newEmails = emails.filter((_, i) => i !== index);
    setEmails(newEmails);
  };

  const validateEmail = (email) => {
    if (String(email) === '') {
      return true;
    }
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };


  return (
    <>
    {emails.map((email, index) => (
      <Form.Group as={Row} className="mb-3" key={index}>
        <Form.Label className="required">E-mail { index + 1 }</Form.Label>
        <Col sm={5}>
          <InputGroup>
            <Form.Control
              type="email"
              placeholder="email@exemplo.com"
              value={email}
              onChange={(event) => handleEmailChange(index, event)}
              className={`${errors.findIndex(item => Number(item) === Number(index)) >= 0 && required ? 'is-invalid' : ''}`}
            />
            <Button variant="danger" onClick={() => handleRemoveEmail(index)} disabled={emails.length === 1}>
              <FaMinus />
            </Button>
            {emails.length === index + 1 && (
              <Button variant="primary" onClick={handleAddEmail}>
                <FaPlus />
              </Button>
            )}
            {errors.findIndex(item => Number(item) === Number(index)) >= 0 && required && (
              <div class="invalid-feedback">E-mail inválido</div>
            )}
          </InputGroup>
        </Col>
      </Form.Group>
    ))}
  </>
  );
};

export default EmailManager;
