import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useQuery} from '@apollo/react-hooks'
import { clientsQueries, demandsQueries, walletQueries } from '../../graphql/queries'
import UiBox from 'ui-components/lib/components/Box'
import Breadcumb from 'ui-components/lib/components/BreadCrumb'
import Table from 'ui-components/lib/components/Table'
import Pagination from 'ui-components/lib/components/Pagination'
import Select from 'ui-components/lib/components/Select'
import SearchField from 'ui-components/lib/components/SearchField'
import useSearchTerm from 'ui-components/lib/hooks/useSearchTerm'
import ChipStatusVariants from '../../components/ChipStatusVariants'
import HalfLoading from '../../components/HalfLoading'
import noDemandsPlaceholder from '../../images/placeholder-without-services.svg'
import { isMobile } from 'react-device-detect'
import { ReactComponent as Info } from '../../assets/icons/info.svg'
import InfoDemand from '../../components/Modal/InfoDemand'
import ApoioBonuz from "../../assets/icons/bonuz-apoio.svg"
import "./styles.css"
import {DefendantName} from "../../components";
import queryString from 'query-string';

const Services = (props) => {
  const history = useHistory()
  const [sort, setSort] = useState('code')
  const [order, setOrder] = useState('desc')
  const [statusFilter, setStatusFilter] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [searchTerm, lazySearchTerm, { handleChangeSearchTerm }] = useSearchTerm('', 400)
  const [openInfoDemand, setOpenInfoDemand] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [demandId] = useState(0);
  const offset = 10
  const [statusUrl, setStatusUrl] = useState('');

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })


  const { data: dataStatus } = useQuery(clientsQueries.GET_STATUS)

  useEffect(() => {
    if (dataStatus && dataStatus?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [dataStatus]) // eslint-disable-line

  const { loading: demandsLoading, data: demandsData, refetch: refetchDemands } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      search: lazySearchTerm,
      sort: sort,
      page: currentPage,
      order,
      offset,
      queryBy: 'status',
      query: statusFilter,
    },
  })

  const handleChangeOrder = (item) => {
    if (item.key === sort) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setSort(item.key)
      setOrder('asc')
    }
  }

  const handleChangeNext = () => {
    const nextPage = currentPage + 1
    if (nextPage <= demandsData?.listDemands?.pagination?.pagesAmount) {
      setCurrentPage(nextPage)
    }
  }

  const handleChangePrev = () => {
    const prevPage = currentPage - 1
    if (prevPage > 0) setCurrentPage(currentPage - 1)
  }

  const handleFilterChange = useCallback(
    (event) => {
      setStatusFilter(event.target.value)
    },
    [setStatusFilter]
  )

  useEffect(() => {
    refetchDemands()
  }, [refetchDemands, statusFilter, lazySearchTerm])

  useEffect(() => {
    const extrairParametro = () => {
      const queryParams = queryString.parse(props.location.search);
      const parametroValue = queryParams.status;
      setStatusUrl(parametroValue);
    };

    extrairParametro();
  }, [props.location.search]);

  useEffect(() => {
    setStatusFilter(statusUrl)
  }, [statusUrl])

  const { push } = useHistory()

  const breadcrumbLinks = [
    { label: 'Home', onClick: () => push('/dashboard') },
    { label: 'Serviços', onClick: () => push('/dashboard/serviços') },
  ]

  return (
    <UiBox display={isMobile ? 'block' : 'flex'} noBorder flexDirection={'column'} maxHeight={'100%'}>
      <InfoDemand
          openModal={openInfoDemand}
          handleClose={() => setOpenInfoDemand(false)}
          id={demandId}
          isJp={dataClientSubscription !== undefined}
        />
      <Box>
        <Breadcumb color={'primary'} navigationLinks={breadcrumbLinks} ml={'-1rem'} />
      </Box>
      <div className='card card-info'>
          <div style={{borderRight: "2px #7683F6 solid"}}>
            <Info className='icon'/>
          </div>
          <div>
            <p>Caso encontre alguma divergência no serviço, você poderá solicitar uma revisão!</p>
          </div>
      </div>
      <div className='d-flex align-items-center justify-content-center my-3'>
        <div style={{marginRight: '15px'}}>
          <Select value={statusFilter} onChange={handleFilterChange}>
            <option value="">Selecionar um status</option>
            <option value="pending">Pendente</option>
            <option value="in_progress">Em Progresso</option>
            <option value="stand_by">Standby</option>
            <option value="waiting_approval">Aguardando Aprovação</option>
            <option value="in_estimating">Aguardando Orçamento</option>
            <option value="draft">Pendente de informação</option>
            <option value="demand_sent">Demanda Enviada</option>
            <option value="in_review">Aguardando revisão</option>
            <option value="canceled">Cancelado</option>
            <option value="done">Concluído</option>
            <option value="waiting_estimate_approval">Aguardando aprovação de orçamento</option>
            <option value="waiting_lawyer_acceptance">Aguardando aceite do advogado</option>
          </Select>
        </div>
        <div>
          <SearchField
            id="search"
            name="search"
            placeholder="Buscar serviço"
            className="input"
            value={searchTerm}
            onChange={(event) => handleChangeSearchTerm(event.target.value)}
          />
        </div>
      </div>
      <div className='d-flex flex-column card table-d'>
        <Flex justifyContent="center" overflow={'auto'} flex={1}>
          {demandsLoading && <HalfLoading />}
          {!demandsLoading && demandsData?.listDemands?.data.length === 0 && (
            <Flex marginY={5} flexDirection="column" justifyContent={'center'} alignItems={'center'}>
              <h2 style={{ fontSize: '23px', color: '#9D9D9D' }}>
                {' '}
                {statusFilter || lazySearchTerm ? 'Nenhum serviço encontrado.' : 'Você ainda não solicitou nenhum serviço.'}
              </h2>
              <img style={{ marginTop: '20px', width: '300px' }} src={noDemandsPlaceholder} alt="Nenhum serviço encontrado" />
            </Flex>
          )}
          {!demandsLoading && demandsData?.listDemands?.data.length !== 0 && (
            <Table
              flex={1}
              width={1}
              onChangeOrder={handleChangeOrder}
              loading={demandsLoading}
              overflow={'auto'}
              className="demand-table"
              pagination={{
                sort,
                order,
              }}
              style={{ cursor: 'pointer' }}
              headers={[
                {
                  title: 'Número',
                  key: 'code',
                  sort: true,
                  align: 'left',
                  width: '30px',
                  cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`} style={{ color: 'red', textDecoration: 'underline' }}>{value.padStart(6, '0')}</Link>
                },
                {
                  title: 'Status',
                  key: 'status',
                  sort: true,
                  align: 'left',
                  width: '30px',
                  cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}><ChipStatusVariants style={{cursor: 'pointer'}} status={value} /></Link>,
                },
                {
                  title: 'Vencimento',
                  key: 'finalDate',
                  sort: true,
                  align: 'left',
                  width: '30px',
                  cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{
                    new Date(parseInt(value)).toLocaleDateString('pt-BR') === 'Invalid Date'?'Sem data definida':new Date(parseInt(value)).toLocaleDateString('pt-BR')}</Link>,
                },
                {
                  title: 'Demanda',
                  key: 'fullDescription',
                  sort: true,
                  align: 'center',
                  minWidth: '350',
                  cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{value}</Link> || '-',
                },
                {
                  title: 'Demandado',
                  key: 'fullDescription',
                  sort: true,
                  align: 'center',
                  minWidth: '200',
                  cellComponent: ({ row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{row?.lawyer?.displayAs}</Link> || '-',
                },
                {
                  title: 'Demandante',
                  key: 'fullDescription',
                  sort: true,
                  align: 'center',
                  minWidth: '200',
                  cellComponent: ({ row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{<DefendantName clientId={row.belongsToSubaccountId ?? row.clientId}/>}</Link> || '-',
                },
                {
                  title: 'Apoio Bonuz',
                  key: 'fullDescription',
                  sort: true,
                  align: 'center',
                  width: '35px',
                  cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}><img src={ApoioBonuz} alt="Apoio bonuz"/></Link> || '-',
                },
                {
                  title: 'Prazo (em dias úteis)',
                  key: 'deadline',
                  align: 'left',
                  width: '150px',
                  cellComponent: ({ value, row }) => value ? <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{value}</Link>  : '-',
                },
                (
                  dataClientSubscription === undefined ?
                  {
                    title: 'BNZ',
                    key: 'price',
                    align: 'left',
                    width: '30px',
                    cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{value}</Link>  || '-',
                  }
                  :
                    {
                      title: 'R$',
                      key: 'price',
                      align: 'left',
                      width: '30px',
                      cellComponent: ({ value, row }) => <Link to={`/dashboard/serviços/detalhes/${row._id}`}>{value * 2}</Link>  || '-',
                    }),                // {
                //   title: 'Avaliação',
                //   key: 'status',
                //   align: 'left',
                //   cellComponent: ({ value, row }) => <ServiceEvaluation status={value} serviceId={row} lineId={row._id} />,
                // },
              ]}
              data={demandsData?.listDemands?.data}
            />
          )}
        </Flex>
        {demandsData?.listDemands?.pagination?.pagesAmount > 1 && (
          <Flex justifyContent="center">
            <Pagination
              color="primary"
              m={20}
              onChangePage={(item) => setCurrentPage(item)}
              onPrevPage={(item) => handleChangePrev()}
              onNextPage={(item) => handleChangeNext()}
              total={demandsData?.listDemands?.pagination?.pagesAmount}
              page={demandsData?.listDemands?.pagination?.current || 1}
            />
          </Flex>
        )}
      </div>
    </UiBox>
  )
}

export default Services
