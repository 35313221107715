class EvaluateDemand {
  constructor(data = {}) {
    this.id = data.id || null
    this.demandId = data.demandId || null
    this.lawyerId = data.lawyerId || null
    this.evaluation = data.evaluation || 0
    this.createdAt = Date.now()
    this.updatedAt = Date.now()
  }
}
export default EvaluateDemand
