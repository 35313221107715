import React, { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import { Flex, Box } from 'reflexbox'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../graphql/mutations'
import { servicesQueries, walletQueries, demandsQueries } from '../../graphql/queries'
import Modal from '@mui/material/Modal'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Alert from 'ui-components/lib/components/Alert'
import Switch from 'ui-components/lib/components/Switch'
import TextField from 'ui-components/lib/components/TextField'
import SelectField from 'ui-components/lib/components/SelectField'
import Typography from 'ui-components/lib/components/Typography'
import Button from 'ui-components/lib/components/Button'
import Spinner from 'ui-components/lib/components/Spinner'
import Tooltip from 'ui-components/lib/components/Tooltip'
// import Modal from 'ui-components/lib/components/Modal'
// import useModal from 'ui-components/lib/hooks/useModal'
import { faExclamationTriangle, faInfoCircle, faQuestionCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFormik } from 'formik'
import { Demand } from '../../models'
import FormLoading from './FormLoading'
import { Label } from './Label'
import { PurchaseBNZ } from '../../components'
import DropzoneServices from './DropzoneServices'
import { DemandSchema } from '../../validate'
import './style.css'
const QuestionForm = (params) => {
  let demandId = params.demandId
  let serviceId = params.serviceId
  let subservice = params.subservice
  let awnsers = params.awnsers
  let demandStatus = params.demandStatus || ''
  let awnsersFormated

  if(awnsers !== undefined){
    awnsersFormated  = awnsers.map((awnser)=> {
      let list = {
        _id: awnser._id,
        questionId: awnser.questionId,
        attachments: awnser.documents,
        answer: awnser.answer
      }
      return list
    })
  }else{
    awnsersFormated = null
  }

  const handleDemandFinalPrice = params.handleFinalPrice

  const handleDemandUrgentDeadline = params.handleExtraUrgentDemand

  const { showMessage } = useAlertStack()
  const [extraUrgent, setExtraUrgent] = useState(null)
  const [extraLanguage, setExtraLanguage] = useState(null)
  // const [ speakLawer, setSpeakLawer] = useState(false)
  const [extraBicolumn, setExtraBicolumn] = useState(null)
  // const [draft, setDraft] = useState(false)
  const [finalPrice, setFinalPrice] = useState(0)
  // const { isOpen, openModal, closeModal } = useModal()
  const { push } = useHistory()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [servicePrice, setServicePrice] = useState('')
  const [clientSubscription, setClientSubscription] = useState('')

  const [isFilesUploading, setFilesUploading] = useState(false)

  const { data: walletData, refetch: refetchMyWallet } = useQuery(walletQueries.GET_MY_WALLET)

  const { data: subservicesQuestionsData, loading: subservicesQuestionsLoading } = useQuery(servicesQueries.GET_SUBSERVICES_QUESTIONS, {
    variables: {
      subserviceId: subservice._id,
    },
    fetchPolicy: 'cache-and-network',
  })

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

  const [createDemand, { loading: loadingCreateDemand }] = useMutation((demandId !== undefined ? demandsMutations.UNDRAFT_DEMAND : demandsMutations.CREATE_DEMAND), {
    variables:{
      demandId:demandId
    },
    onCompleted() {
      // if(draft){
      //   showMessage({
      //     title: 'Rascunho salvo',
      //     message: 'Rascunho salvo com sucesso.',
      //     color: 'success',
      //     position: 'bottom-left',
      //     time: 4000,
      //   })
      // } else {
        showMessage({
          title: 'Serviço solicitado',
          message: 'Novo serviço solicitado com sucesso.',
          color: 'success',
          position: 'bottom-left',
          time: 4000,
        })
      // }
      push('/dashboard/serviços')
    },
    onError({ graphQLErrors }) {
      showMessage({
        title: 'Erro ao abrir serviço',
        message: graphQLErrors[0]?.extensions?.response?.body?.message || 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
    refetchQueries: [
      {
        query: walletQueries.GET_MY_WALLET,
      },
      {
        query: walletQueries.GET_MY_STATEMENTS,
      },
      {
        query: demandsQueries.GET_DEMANDS,
      },
    ],
  })

  const formik = useFormik({
    initialValues: new Demand(),
    validationSchema: DemandSchema,
    onSubmit: (values) => {
      createDemand({
        variables: {
          ...values,
          //remove required from answers
          answers: demandId !== undefined ? awnsersFormated:values.answers.map(({ __required: _, ...rest }) => ({
            ...rest,
          })),
          urgent: extraUrgent || false,
          bicolumn: extraBicolumn || false,
          extraLanguage: extraLanguage || false,
          draft: false
        },
      })
    },
  })

  useEffect(() => {
    setExtraUrgent(subservice?.extraUrgentPrice || false)
    setExtraLanguage(subservice?.extraLanguagePrice || false)
    setExtraBicolumn(subservice?.extraBicolumnPrice || false)

    setFinalPrice(subservice?.clientPrice?.amount || 0)
    if(handleDemandFinalPrice){
      handleDemandFinalPrice(subservice?.clientPrice?.amount || 0)
    }
    if(handleDemandUrgentDeadline){
      handleDemandUrgentDeadline(subservice?.extraUrgentPrice || false)
    }

    formik.resetForm(new Demand())
  }, [subservice]) // eslint-disable-line

  const handleQuestionChange = (event, questionIndex) => {
    const { value } = event.target
    if(demandId !== undefined){
      awnsers[questionIndex].answer = value
      awnsersFormated[questionIndex].answer = value
    }
    const currentAnswers = formik.values.answers

    currentAnswers[questionIndex] = {
      ...formik.values.answers[questionIndex],
      answer: value,
    }

    formik.setValues({
      ...formik.values,
      answers: currentAnswers,
    })
  }

  const loadAwnserFromScratch = (value, questionIndex) => {
    if(demandId !== undefined){
      awnsers[questionIndex].answer = value
      awnsersFormated[questionIndex].answer = value
    }
    const currentAnswers = formik.values.answers

    currentAnswers[questionIndex] = {
      ...formik.values.answers[questionIndex],
      answer: value,
    }

    formik.setValues({
      ...formik.values,
      answers: currentAnswers,
    })
  }

  useEffect(() => {
    if (subservicesQuestionsData) {
      const initialAnswers = subservicesQuestionsData?.subServicesQuestions?.map(({ _id, required: __required }) => ({
        questionId: _id,
        attachments: [],
        answer: null,
        __required,
      }))

      const questionsWithAnwsers = demandStatus === 'draft'? subservicesQuestionsData?.subServicesQuestions.map((question, questionIndex) => {
        return loadAwnserFromScratch(awnsers[questionIndex]?.answer, questionIndex)
      }) : initialAnswers

      formik.setValues({
        ...formik.values,
        serviceId,
        subserviceId: subservice._id,
        answers: questionsWithAnwsers,
      })
    }
  }, [subservicesQuestionsData]) // eslint-disable-line

  useEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  const openModal = useCallback((price) => {
    setServicePrice(price)
    setModalIsOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setModalIsOpen(false)
    refetchMyWallet()
  }, []) // eslint-disable-line
  
  const handleExtraUrgentChange = useCallback(() => {
    const { urgentExtraBNZ: extraUrgentPrice } = subservice

    if(!extraUrgent) {
      setFinalPrice((oldPrice) => { 
        return parseInt(oldPrice) + Number(extraUrgentPrice) 
      })
      if(handleDemandFinalPrice){
        handleDemandFinalPrice((oldPrice) => { 
          return parseInt(oldPrice) + Number(extraUrgentPrice) 
        })
      }
    } else {
      setFinalPrice((oldPrice) => { 
        return parseInt(oldPrice) - Number(extraUrgentPrice) 
      })
      if(handleDemandFinalPrice){
        handleDemandFinalPrice((oldPrice) => { 
          return parseInt(oldPrice) - Number(extraUrgentPrice) 
        })
      }  
    }

    setExtraUrgent(!extraUrgent)
    handleDemandUrgentDeadline(!extraUrgent)
  }, [extraUrgent, subservice]) // eslint-disable-line

  // const handleExtraBicolumnChange = useCallback(() => {
  //   const { bicolunado: extraBicolumnPrice } = subservice

  //   if(!extraBicolumn) {
  //     setFinalPrice((oldPrice) => {
  //       return parseInt(oldPrice) + Number(extraBicolumnPrice)
  //     })
  //     if(handleDemandFinalPrice){
  //       handleDemandFinalPrice((oldPrice) => {
  //         return parseInt(oldPrice) + Number(extraBicolumnPrice)
  //       })
  //     }  
  //   } else {
  //     setFinalPrice((oldPrice) => {
  //       return parseInt(oldPrice) - Number(extraBicolumnPrice)
  //     })
  //     if(handleDemandFinalPrice){
  //       handleDemandFinalPrice((oldPrice) => {
  //         return parseInt(oldPrice) - Number(extraBicolumnPrice)
  //       })
  //     }  
  //   }

  //   setExtraBicolumn(!extraBicolumn)
  // }, [extraBicolumn, subservice]) // eslint-disable-line

  const handleExtraLanguageChange = useCallback(() => {
    const { extraLanguage: extraLanguagePrice } = subservice

    if(!extraLanguage) {
      setFinalPrice((oldPrice) => {
        return parseInt(oldPrice) + Number(extraLanguagePrice)
      })
      if(handleDemandFinalPrice){
        handleDemandFinalPrice((oldPrice) => {
          return parseInt(oldPrice) + Number(extraLanguagePrice)
        })
      }  
    } else {
      setFinalPrice((oldPrice) => {
        return parseInt(oldPrice) - extraLanguagePrice
      })
      if(handleDemandFinalPrice){
        handleDemandFinalPrice((oldPrice) => {
          return parseInt(oldPrice) - extraLanguagePrice
        })
      }  
    }

    setExtraLanguage(!extraLanguage)
  }, [extraLanguage, subservice]) // eslint-disable-line

  // const handleSpeakLawer = () => {
  //   setSpeakLawer(!speakLawer)
  // }

  // const saveAsDraft = () => {
  //   setDraft(true)
  //   document.getElementById('save-btn').style.display = 'none'
  //   formik.handleSubmit()
  // }

  const save = () => {
    ///document.getElementById('save-btn-draft').style.display = 'none'
    formik.handleSubmit()
  }

  if (subservicesQuestionsLoading) return <FormLoading />

  if (!subservice.clientPrice && !subservice.needsEstimate) {
    return (
      <Flex mt={4} width={1} flexDirection="column">
        <Alert color="danger" title="Precificação de plano não atribuida" message="Contate o administrador do sistema" icon={faExclamationTriangle} />
      </Flex>
    )
  }

  if (subservicesQuestionsData?.subServicesQuestions?.length === 0 && !subservicesQuestionsLoading) {
    return (
      <Flex mt={4} width={1} flexDirection="column">
        <Alert
          color="danger"
          title="Este subserviço está indisponível"
          message="Ainda não foram adicionas perguntas ao subserviço, por favor escolha outro"
          icon={faExclamationTriangle}
        />
      </Flex>
    )
  }

  return (
    <>
      <Flex mt={2} flexDirection="column" width={1}>
        {(walletData?.wallet.balance + walletData?.wallet.creditsBonuzPlans) < finalPrice && !subservice?.needsEstimate && (
          <Alert mt={1} color="danger" shortAlert>
            <Flex p={2} justifyContent="space-between" alignItems="center" width="100%">
              <Flex alignItems="center">
                <FontAwesomeIcon icon={faInfoCircle} />
                <Typography m={0} ml={2}>
                  Seu saldo {!clientSubscription ? `de BNZs` : ``} é insuficiente para este serviço!
                </Typography>
              </Flex>
              <Flex alignItems="center">
                <Button p={2} mt={1} onClick={() => openModal(finalPrice)} color="primary">
                {clientSubscription ? 'Pagamento' : 'Adquirir BNZs'}
                </Button>
              </Flex>
            </Flex>
          </Alert>
        )}
        <form
          onSubmit={(event) => {
            event.preventDefault()
            formik.handleSubmit()
          }}
          className="card card-service"
        >
          {
            ((walletData?.wallet.balance + walletData?.wallet.creditsBonuzPlans) < finalPrice) && (
              <div className='insuficient-founds'>

              </div>
            )
          }

          {subservicesQuestionsLoading && <FormLoading />}
          <div className='card-title'>
              <h6>Formulário</h6>
          </div>
          <div className='card-body'>
            {(subservice?.urgentExtraBNZ || subservice?.bicolunado || subservice?.extraLanguage || subservice?.isDraft) && (
              <Flex flexDirection="column">
                <h6>Extras</h6>
                <Flex flexWrap="wrap" alignItems="center">
                  {(!!subservice?.urgentExtraBNZ || subservice?.isDraft) && (
                    <Box justifyContent="center" alignItems="center" mr={4}>
                      <Flex>
                        <Label>Urgente</Label>
                        <Tooltip
                          ml={1}
                          direction={'bottom'}
                          text="Entregaremos o serviço em um prazo especial a você, dando prioridade máxima (você será debitado a mais)"
                        >
                          <FontAwesomeIcon color="grey" icon={faQuestionCircle} />
                        </Tooltip>
                      </Flex>
                      <Switch id="urgent" checked={extraUrgent} value={extraUrgent} onChange={handleExtraUrgentChange} />
                    </Box>
                  )}
                  {/* {!!subservice?.bicolunado && (
                    <Box justifyContent="center" alignItems="center" mr={4}>
                      <Flex>
                        <Label>Bicolunado</Label>
                        <Tooltip
                          ml={1}
                          text="Neste caso o documento será elaborado em dois idiomas, um português e outro de sua escolha (neste caso você será debitado a mais e favor indicar qual o segundo idioma nas perguntas)"
                        >
                          <FontAwesomeIcon color="grey" icon={faQuestionCircle} />
                        </Tooltip>
                      </Flex>
                      <Switch id="bicolumn" checked={extraBicolumn} value={extraBicolumn} onChange={handleExtraBicolumnChange} />
                    </Box>
                  )} */}
                  {!!subservice?.extraLanguage && (
                    <Box justifyContent="center" alignItems="center" mr={4}>
                      <Flex>
                        <Label>Idioma</Label>
                        <Tooltip
                          direction={'bottom'}
                          ml={1}
                          text="O documento será inteiro em um outro idioma sem ser português (neste caso vocẽ será debitado a mais e favor indicar qual será o idioma nas perguntas)"
                        >
                          <FontAwesomeIcon color="grey" icon={faQuestionCircle} />
                        </Tooltip>
                      </Flex>
                      <Switch id="extraLanguage" checked={extraLanguage} value={extraLanguage} onChange={handleExtraLanguageChange} />
                    </Box>
                  )}
                  {/* <Box justifyContent="center" alignItems="center">
                    <Flex>
                      <Label>Falar com Advogado</Label>
                      <Tooltip
                        ml={1}
                        text="Call com Advogado (30min)
                        R$100,00, este valor será debitado 
                        do seu saldo BNZ. "
                      >
                        <FontAwesomeIcon color="grey" icon={faQuestionCircle} />
                      </Tooltip>
                    </Flex>
                    <Switch id="speakLawer" checked={speakLawer} value={speakLawer} onChange={handleSpeakLawer} />
                  </Box> */}
                </Flex>
              </Flex>
            )}

            <h6 className='mt-4'>Por favor, preencha o formulário abaixo:</h6>
            {awnsers !== undefined ?
              (
                subservicesQuestionsData?.subServicesQuestions &&
                !subservicesQuestionsLoading &&
                subservicesQuestionsData?.subServicesQuestions.map((question, questionIndex) => {
                  return question.kind === 'text' ? (
                    <Flex key={awnsers[questionIndex]?.questionId} flexDirection="column" my={3} mb={4} width={1}>
                      <Box mb={2} my={3} width={[1, 1, 1, 1]}>
                        <TextField
                          onChange={(event) => handleQuestionChange(event, questionIndex)}
                          value={awnsers[questionIndex]?.answer}
                          mt={2}
                          label={awnsers[questionIndex]?.description}
                          placeholder={question.tip}
                          required={question.required}
                          id={awnsers[questionIndex]?.questionId}
                          error={formik?.errors?.answers?.[questionIndex]?.answer}
                          multiline
                        />
                      </Box>

                      {question.allowClientUpload && (
                        <Box width={1} key={question._id} mb={2}>
                          <DropzoneServices
                            question={question}
                            questionIndex={questionIndex}
                            formik={formik}
                            onLoad={(loading) => setFilesUploading(loading)}
                          />
                        </Box>
                      )}

                      {question.attachment && (
                        <Flex key={question._id} flexDirection="column">
                          <Label mb={4}>{question.description}</Label>
                          <a href={question.attachment} target="noopener norefer _blank" download>
                            Clique para baixar
                          </a>
                        </Flex>
                      )}
                    </Flex>
                  ) : (
                    <Flex key={question._id} flexDirection="column" my={3} mb={4} width={1}>
                      <Box mb={2} width={[1, 1, 1, 1]}>
                        <SelectField
                          id={awnsers[questionIndex]?._id}
                          onChange={(event) => handleQuestionChange(event, questionIndex)}
                          label={awnsers[questionIndex]?.description}
                          required={question.required}
                          mr={3}
                          error={formik?.errors?.answers?.[questionIndex]?.answer}
                        >
                          <option value="">Selecione</option>
                          {question.options?.map((option) => (
                            <option 
                              key={option} 
                              value={option}
                              selected={awnsers[questionIndex]?.answer === option ? true : false}
                            >
                              {option}
                            </option>
                          ))}
                        </SelectField>
                      </Box>
                      {!!question.tip && (
                        <Box mb={2} width={[1, 1, 1, 1]}>
                          <small style={{ fontStyle: 'italic' }}>{question.tip}</small>
                        </Box>
                      )}

                      {question.allowClientUpload && (
                        <Box width={1} key={question._id} mb={2}>
                          <DropzoneServices
                            question={question}
                            questionIndex={questionIndex}
                            formik={formik}
                            onLoad={(loading) => setFilesUploading(loading)}
                          />
                        </Box>
                      )}

                      {question.attachment && (
                        <Flex key={question._id} flexDirection="column" mt={3}>
                          <Label mb={4}>{question.description}</Label>
                          <a href={question.attachment} target="noopener norefer _blank" download>
                            Clique para baixar
                          </a>
                        </Flex>
                      )}
                    </Flex>
                  )
                })
              )
            :
              (
            subservicesQuestionsData?.subServicesQuestions &&
              !subservicesQuestionsLoading &&
              subservicesQuestionsData?.subServicesQuestions.map((question, questionIndex) => {
                return question.kind === 'text' ? (
                  <Flex key={question._id} flexDirection="column" my={3} mb={4} width={1}>
                    <Box mb={2} my={3} width={[1, 1, 1, 1]}>
                      <TextField
                        onChange={(event) => handleQuestionChange(event, questionIndex)}
                        value={formik?.values?.answers[questionIndex]?._id}
                        mt={2}
                        label={question.description}
                        placeholder={question.tip}
                        required={question.required}
                        id={question._id}
                        error={formik?.errors?.answers?.[questionIndex]?.answer}
                        multiline
                      />
                    </Box>

                    {question.allowClientUpload && (
                      <Box width={1} key={question._id} mb={2}>
                        <DropzoneServices
                          question={question}
                          questionIndex={questionIndex}
                          formik={formik}
                          onLoad={(loading) => setFilesUploading(loading)}
                        />
                      </Box>
                    )}

                    {question.attachment && (
                      <Flex key={question._id} flexDirection="column">
                        <Label mb={4}>{question.description}</Label>
                        <a href={question.attachment} target="noopener norefer _blank" download>
                          Clique para baixar
                        </a>
                      </Flex>
                    )}
                  </Flex>
                ) : (
                  <Flex key={question._id} flexDirection="column" my={3} mb={4} width={1}>
                    <Box mb={2} width={[1, 1, 1, 1]}>
                      <SelectField
                        id={question._id}
                        onChange={(event) => handleQuestionChange(event, questionIndex)}
                        value={formik?.values?.answers[questionIndex]?._id}
                        label={question.description}
                        required={question.required}
                        mr={3}
                        error={formik?.errors?.answers?.[questionIndex]?.answer}
                      >
                        <option value="">Selecione</option>
                        {question.options?.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </SelectField>
                    </Box>
                    {!!question.tip && (
                      <Box mb={2} width={[1, 1, 1, 1]}>
                        <small style={{ fontStyle: 'italic' }}>{question.tip}</small>
                      </Box>
                    )}

                    {question.allowClientUpload && (
                      <Box width={1} key={question._id} mb={2}>
                        <DropzoneServices
                          question={question}
                          questionIndex={questionIndex}
                          formik={formik}
                          onLoad={(loading) => setFilesUploading(loading)}
                        />
                      </Box>
                    )}

                    {question.attachment && (
                      <Flex key={question._id} flexDirection="column" mt={3}>
                        <Label mb={4}>{question.description}</Label>
                        <a href={question.attachment} target="noopener norefer _blank" download>
                          Clique para baixar
                        </a>
                      </Flex>
                    )}
                  </Flex>
                )
              })
            )}

            <Flex justifyContent="flex-end">
              {/* <Button id="save-btn-draft" disabled={loadingCreateDemand || isFilesUploading} className="btn btn-out-red" type="button" onClick={() => saveAsDraft()}>
                {loadingCreateDemand ? <Spinner color="primary" /> : demandId ? 'Atualizar rascunho' : 'Salvar como rascunho'}
              </Button>
              &nbsp; */}
              <Button id="save-btn" disabled={loadingCreateDemand || isFilesUploading} className="btn btn-concluid" type="button" onClick={()=>save()}>
                {loadingCreateDemand ? <Spinner color="primary" /> : 'Enviar'}
              </Button>
            </Flex>
          </div>
        </form>
        <Modal
          open={modalIsOpen}
          onClose={() => {
            closeModal()
          }}
          disableAutoFocus
          sx={{zIndex: '9999'}}
        >
          <PurchaseBNZ
            closeParentModal={() => {
              closeModal()
            }}
            isJp={clientSubscription || false}
            servicePrice={servicePrice}
          />
        </Modal>        
      </Flex>
    </>
  )
}

QuestionForm.propTypes = {
  subservice: PropTypes.shape({
    id: PropTypes.string,
    needsEstimate: PropTypes.bool,
    deadline: PropTypes.number,
  }).isRequired,
  serviceId: PropTypes.string.isRequired,
}

export default QuestionForm
