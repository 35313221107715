import React, { useState } from "react";
import { Modal } from "@mui/material";

import WelcomeView from "./views/Welcome";
import PlansView from "./views/Plans";

const NewWelcomeModal = ({openModal, handleClose}) => {
  const [plan, setPlan] = useState('')
  const handleView = (plan = '') => {
    setPlan(plan)
  }

  return (
    <Modal
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="new-welcome"
    >
      { !plan
        ? <WelcomeView 
          onSelectPlan={handleView}
        />
        : <PlansView 
            onViewChange={handleView}
            planSelected={plan}
        />
      }
    </Modal>
  )
}

export default NewWelcomeModal
