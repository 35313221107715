import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useLazyQuery, useQuery, useMutation } from '@apollo/react-hooks'
import { clientsQueries, walletQueries } from '../../graphql/queries'
import { clientsMutations } from '../../graphql/mutations'
import { termsQueries } from '../../graphql/queries'
import Modal from 'ui-components/lib/components/Modal'
import useModal from 'ui-components/lib/hooks/useModal'
import Alert from 'ui-components/lib/components/Alert'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Button from 'ui-components/lib/components/Button'
import Spinner from 'ui-components/lib/components/Spinner'
import NaturalPersonAccountForm from './NaturalPerson'
import LegalPersonAccountForm from './LegalPerson'
import AccountUserModal from '../../components/Modal/AccountUserModal'
import { Loading } from '../../components'
import Breadcumb from 'ui-components/lib/components/BreadCrumb'

const MyAccount = () => {
  const history = useHistory()
  const { showMessage } = useAlertStack()
  const [clientSubscription, setClientSubscription] = useState('')
  const [openAccountUserModal, setOpenAccountUserModal] = useState(false)
  const [userType, setUserType] = useState('')

  const { isOpen: openClose, openModal: openCloseModal, closeModal: closeCloseAccount } = useModal()

  const { isOpen: openTerms, openModal: openTermsModal, closeModal: closeTerms } = useModal()
  const { isOpen: openPolicy, openModal: openPolicyModal, closeModal: closePolicy } = useModal()

  const breadcrumbLinks = [
    {
      label: 'Home',
      onClick: () => history.push('/dashboard')
    },
    {
      label: 'Minha conta'
    },
  ]

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

  const { data: privacyPolicyData, loading: privacyPolicyLoading } = useQuery(termsQueries.GET_PRIVACY_POLICY)

  const { data: termsOfUseData, loading: termsOfUseLoading } = useQuery(termsQueries.GET_TERMS_OF_USE)

  const { data: statusData } = useQuery(clientsQueries.GET_STATUS)
  const [fetchMyType, { data: dataMyType, loading: loadingMyType }] = useLazyQuery(clientsQueries.GET_TYPE)
  const [deleteClient, { loading: loadingDelete }] = useMutation(clientsMutations.DELETE_CLIENT, {
    onCompleted() {
      closeCloseAccount()
      showMessage({
        title: 'Sua conta foi encerrada com sucesso',
        message: 'Conta encerrada',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      history.push('/sair')
    },
    onError(error) {
      closeCloseAccount()
    },
  })

  const myType = dataMyType?.me?.type

  const abrirCloseModal = () => {
    openCloseModal()
  }

  const handleOpenAddUserModal = (value) => {
    setOpenAccountUserModal(true)
    setUserType(value)
  }

  useEffect(() => {
    fetchMyType()
  }, [fetchMyType])

  useEffect(() => {
    if (statusData && statusData?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [statusData]) // eslint-disable-line

  useEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription.active)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  const confirmCloseAccount = useCallback(() => {
    deleteClient()
  }, [deleteClient])

  if (loadingMyType) return <Loading />

  return (
    <>
      <Box>
        <Breadcumb color={'primary'} navigationLinks={breadcrumbLinks} ml={'-1rem'} mb={'2.5rem'} />
      </Box>
      {/* <Flex width={1} justifyContent="center" maxWidth="1200px"> */}
        <Flex width={1} flexDirection="column">
          <Box>
            <Alert
              mb={3}
              color="black"
              message="Caso deseje alterar alguma informação abaixo, modifique o campo desejado e clique no botão salvar ao final do formulário."
              noShadow
              title="Seus dados pessoais"
            />

            {myType === 'natural' ? <NaturalPersonAccountForm /> : <LegalPersonAccountForm />}

            <Flex mt={5} justifyContent="center" width="100%" style={{ border: '1px solid lightGray', borderRadius: '5px'}}>
              <Button
                m={3}
                onClick={() => handleOpenAddUserModal('Usuario')}
                style={{
                  backgroundColor: '#2D4677', 
                  color: '#FFFFFF',
                  fontWeight: 'bold',
                  fontSize: '16px',
                  borderRadius: '8px',
                  padding: '8px 20px'
                }}>
                {'Adicionar usuário'}
              </Button>

              {clientSubscription ? (
                <Button
                  m={3}
                  onClick={() => handleOpenAddUserModal('Dependentes')}
                  style={{
                    backgroundColor: '#2D4677', 
                    color: '#FFFFFF',
                    fontWeight: 'bold',
                    fontSize: '16px',
                    borderRadius: '8px',
                    padding: '8px 20px'
                  }}>
                  {'Adicionar dependente'}
                </Button>
              ) : ('')}

              <Button linkButton underline color={'gray'} m={3} pr={2} onClick={() => openTermsModal()} disabled={!!termsOfUseLoading}>
                Termos de Serviço
              </Button>

              <Button linkButton underline color={'gray'} m={3} pr={2} onClick={() => openPolicyModal()} disabled={!!privacyPolicyLoading}>
                Política de Privacidade
              </Button>

              <Button linkButton underline color={'red'} m={3} pr={2} onClick={abrirCloseModal}>
                <strong>Encerrar conta</strong>
              </Button>
            </Flex>
          </Box>
        </Flex>
      {/* </Flex> */}

      <Modal
        id="closeModal"
        open={openClose}
        onClose={closeCloseAccount}
        header={<h4 style={{ padding: 0, margin: 10 }}>Deseja realmente encerrar sua conta?</h4>}
        footer={
          <Flex justifyContent="center">
            {!loadingDelete && (
              <Button m={10} onClick={closeCloseAccount} disabled={loadingDelete}>
                Cancelar
              </Button>
            )}
            <Button m={10} color={'primary'} onClick={confirmCloseAccount} disabled={loadingDelete}>
              {loadingDelete ? <Spinner /> : 'Confirmar'}
            </Button>
          </Flex>
        }
      >
      </Modal>

      <Modal
        open={openTerms}
        onClose={closeTerms}
        boxProps={{ p: 28 }}
        contentModalProps={{ width: '80vw' }}
        header={<h3>Termos de Serviço</h3>}
        id="termsModal"
      >
        <Flex flexDirection="column">
          <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: termsOfUseData?.termsOfUse?.content }} />
          <Flex justifyContent="flex-end">
            <Button mt={5} mr={3} color="primary" onClick={() => closeTerms()}>
              Fechar
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <Modal
        open={openPolicy}
        onClose={closePolicy}
        boxProps={{ p: 28 }}
        contentModalProps={{ width: '80vw' }}
        header={<h3>Política de Privacidade</h3>}
        id="policyModal"
      >
        <Flex flexDirection="column">
          <Flex mt={3} flexDirection="column" dangerouslySetInnerHTML={{ __html: privacyPolicyData?.privacyPolicy?.content }} />
          <Flex justifyContent="flex-end">
            <Button mt={5} mr={3} color="primary" onClick={() => closePolicy()}>
              Fechar
            </Button>
          </Flex>
        </Flex>
      </Modal>

      <AccountUserModal
        openModal={openAccountUserModal}
        handleClose={() => setOpenAccountUserModal(false)}
        userType={userType}
      />
    </>
  )
}

export default MyAccount
