import React, {useState} from 'react';
import {Card, Col, Container, Form, Row} from "react-bootstrap";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import SendIcon from '@mui/icons-material/Send';
import FolderIcon from '@mui/icons-material/Folder';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import ContactsIcon from '@mui/icons-material/Contacts';
import SortIcon from '@mui/icons-material/Sort';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import FileSender from "../../components/BonuzSign/FileSender/FileSender";
import PersonIcon from '@mui/icons-material/Person';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import './BonuzSign.css'
import {Flex} from "reflexbox";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import Button from "ui-components/lib/components/Button";
import Modal from 'react-bootstrap/Modal';
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from 'date-fns/locale/pt-BR';
import SuccessBonuzSign from '../../assets/BonuzSIgn/success-bonuz-sign.svg'
import {useMutation, useQuery} from "@apollo/react-hooks";
import {documentsMutations} from "../../graphql/mutations";
import Alert from "ui-components/lib/components/Alert";
import Typography from "ui-components/lib/components/Typography";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {walletQueries} from "../../graphql/queries";

const BonuzSign = () => {

  registerLocale('ptBR', ptBR);
  const [files,setFiles] = useState([]);
  const [activeMenu, setActiveMenu] = useState(0);
  const [openAddSignatoriesModal, setOpenAddSignatoriesModal] = useState(false);

  const handleClose = () => setOpenAddSignatoriesModal(false);
  const handleShow = () => setOpenAddSignatoriesModal(true);

  const [email,setEmail] = useState("");
  const [name,setName] = useState("");
  const [socialNumber,setSocialNumber] = useState("");
  const [birthDate,setBirthDate] = useState("");
  const [foreign,setForeign] = useState(false);
  const [saveInNotebook,setSaveInNotebook] = useState(false);

  const [signatories,setSignatories] = useState([]);
  const [deadline, setDeadline] = useState("");
  const [remindInterval, setRemindInterval] = useState(1);
  const [locale,setLocale] = useState("pt-BR");
  const [message,setMessage] = useState("");

  const { data: walletData } = useQuery(walletQueries.GET_MY_WALLET)


  const [createSignedContract] = useMutation(documentsMutations.CREATE_SIGNED_CONTRACT, {
    variables:{
        contracts:files,
        deadlineAt:deadline,
        signatories:signatories,
        remindInterval:remindInterval,
        locale:locale,
        message:message
    },
      onCompleted(){
        setActiveMenu(4)
      },
      onError(){
        alert('Erro')
      }
  })

  function handleAddSignatory(e){
    e.preventDefault()

    if(!validateAddSignatoryFields()){
      alert("Verifique os campos informados")
      return false
    }

    const signatory = {
      email,
      name,
      socialNumber,
      birthDate,
      foreign,
      saveInNotebook
    }
    setSignatories([...signatories,signatory]);

    clearSignatoryFields()
  }

  function validateAddSignatoryFields(){
    return !(email === "" || name === "" || socialNumber === "" || birthDate === "");
  }

  function clearSignatoryFields(){
    setEmail("");
    setName("");
    setSocialNumber("");
    setBirthDate("");
    setForeign(false);
    setSaveInNotebook(false);
    handleClose()
  }

  function handleRemoveSignatory(index){
    const signatoryToRemove = signatories[index];
    const updatedSignatories = signatories.filter(item => item !== signatoryToRemove);
    setSignatories(updatedSignatories)
  }

  function compare( a, b ) {
    if ( a.email < b.email ){
      return -1;
    }
    if ( a.email > b.email ){
      return 1;
    }
    return 0;
  }

  function handleSortSignatories(){
    const sortSignatories = signatories.sort(compare);
    setSignatories([...sortSignatories])
  }

  function setFilesHandler(file){
    let actualFilesList = files;

    actualFilesList.push(file);
    let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
      attachment: '',
      name,
      randomFileName,
      mimeType,
      extension,
      size,
    }))
    setFiles(actualFilesListFiltered);
  }

  function handleSendDocumentsToSign(){
      createSignedContract()
  }


  const mainMenu = [
    {
      img:<FilePresentIcon className={"rounded-icon"} sx={[{ color: "#9997A0" }, { fontSize: 64 }]}/>,
      title:"Adicionar documento",
      active:false
    },
    {
      img:<GroupIcon className={"rounded-icon"} sx={[{ color: "#9997A0" }, { fontSize: 64 }]}/>,
      title:"Adicionar signatários",
      active:false
    },
    {
      img:<SettingsIcon className={"rounded-icon"} sx={[{ color: "#9997A0" }, { fontSize: 64 }]}/>,
      title:"Adicionar configurações",
      active:false
    },
    {
      img:<SendIcon className={"rounded-icon"} sx={[{ color: "#9997A0" }, { fontSize: 64 }]}/>,
      title:"Mensagem de envio",
      active:false
    }
  ]

  if(walletData?.wallet?.contractCredits <= 0){
    return (
      <Alert mt={3} color="danger" shortAlert>
        <Flex p={2} justifyContent="space-between" alignItems="center" width="100%">
          <Flex alignItems="center">
            <FontAwesomeIcon icon={faInfoCircle} />
            <Typography m={0} ml={2}>
              Você não possui créditos para criar esse contrato
            </Typography>
          </Flex>
          <Flex alignItems="center">
            <Button p={2} mt={1} onClick={() => window.location.href = '/dashboard/bonuz-sign/purchase'} color="primary">
              Comprar
            </Button>
          </Flex>
        </Flex>
      </Alert>
    )
  }else{
    return (
      <div id={"bonuz-sign"}>
        <Container style={{marginTop:'40px',marginBottom:'30px', padding:'10px'}} className={activeMenu === 4? 'd-none':''}>
          <Card body>
            <Row>
              {mainMenu.map((menuOption,counter)=>
                (
                  <Col
                    key={counter}
                    className={activeMenu === counter ? "active-menu" : ""}
                    style={{
                      justifyContent:'space-between',
                      textAlign:'center',
                      display:'flex',
                      flexDirection:'column',
                      alignItems:'center'
                    }}>
                    <Row>
                      {menuOption.img}
                    </Row>
                    <Row>
                      {menuOption.title}
                    </Row>
                  </Col>
                )
              )}
            </Row>
          </Card>
        </Container>
        <div id="files" className={activeMenu === 0 ? "current-page" : "non-current-page"}>
          <Card body>
            <h4><FolderIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> <strong>Documentos</strong></h4>
            <FileSender  onComplete={(file)=>setFilesHandler(file)}/>
            <Row>
              <Col
                md={{ span: 2, offset: 10 }}
                style={{
                  justifyContent:'space-evenly',
                  textAlign:'center',
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'end',
                  marginTop:'20px',
                  marginBottom:'20px'
                }}
              >
                {/*disabled={files.length < 1} className={"bonuz-sign-primary"}*/}
                <Button p={[1, 2]}  className={"bonuz-sign-primary"}   onClick={()=>setActiveMenu(1)}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Avançar
                  </Flex>
                </Button>
              </Col>
            </Row>
          </Card>
          <Card body style={{marginTop:'20px'}}>
            <h4 className={files.length > 0 && "overlined-title" }><PictureAsPdfIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> <strong>Documentos</strong></h4>
            <ul className="file-sended-list">
              {
                files.length > 0 && files.map((file, counter)=>(
                  <li key={counter}><DescriptionIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> {file.name}</li>
                ))
              }
            </ul>
            {
              files.length === 0 && (
                <center>
                  <h5 style={{color:'#4E4B59'}}>
                    Visualize documentos para assinatura aqui.
                  </h5>
                </center>
              )
            }
          </Card>
        </div>
        <div id="signatories" className={activeMenu === 1 ? "current-page" : "non-current-page"}>
          <Card body>
            <Row>
              <Col
                style={{
                  justifyContent:'space-between',
                  textAlign:'center',
                  display:'flex',
                  flexDirection:'column',
                  alignItems:'center'
                }}>
                <Row>
                  <PeopleOutlineIcon sx={[{ color: "#9997A0" }, { fontSize: 98 }]}/>,
                </Row>
                <Row>
                  <h4><strong>Adicionar signatários</strong></h4>
                </Row>
              </Col>
            </Row>
            <Row>
              <div className="signatories-menu">
                <Button onClick={handleShow}><PersonAddIcon sx={[{ color: "#9997A0" }, { fontSize: 18 }]}/> Signatário novo</Button>
                <Button onClick={()=>alert("Em breve")}><ContactsIcon sx={[{ color: "#9997A0" }, { fontSize: 18 }]}/> Signatário da agenda</Button>
                <Button onClick={() => handleSortSignatories()}><SortIcon sx={[{ color: "#9997A0" }, { fontSize: 18 }]}/>Ordenar assinaturas</Button>
              </div>
              <div className={signatories.length === 0 ? "border border-secondary" : "d-none"}>
                <p className="text-center m-2">
                  <PersonIcon sx={[{ color: "#9997A0" }, { fontSize: 18 }]}/> Adicione um <strong>Signatário novo</strong> ou um <strong>Signatário da agenda</strong>
                </p>
              </div>
              <div id="signatories-list">
                {signatories.map((signatory,key)=>(
                  <Row className="signatory" key={key}>
                    <Col>
                      <Row>
                        <Col xs={1}>
                          <PersonOutlineIcon sx={[{ color: "#F14F5C" }, { fontSize: 38 }]}/>
                        </Col>
                        <Col>
                          <strong>{signatory.name}</strong>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1}>
                          <SendIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/>
                        </Col>
                        <Col>
                          <strong>Envio via:</strong> E-mail
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={1}>
                          <VerifiedUserIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/>
                        </Col>
                        <Col>
                          <strong>Autenticar usando: Token via E-mail</strong>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={7}></Col>
                    <Col xs={1}>
                      <DeleteOutlineIcon sx={[{ color: "#F14F5C" }, { fontSize: 38 }]} onClick={() => handleRemoveSignatory(key)}/>
                    </Col>
                  </Row>
                ))}
              </div>
            </Row>
            <Row>
              <Col
                md={{ span: 2, offset: 10 }}
                style={{
                  justifyContent:'space-evenly',
                  textAlign:'center',
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'end',
                  marginTop:'20px',
                  marginBottom:'20px'
                }}
              >
                <Button p={[1, 2]} onClick={()=>setActiveMenu(0)}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Voltar
                  </Flex>
                </Button>
                <Button p={[1, 2]} className={"bonuz-sign-primary"} onClick={()=>setActiveMenu(2)}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Avançar
                  </Flex>
                </Button>
              </Col>
            </Row>
          </Card>
          <Card body style={{marginTop:'20px'}}>
            <h4 className={files.length > 0 && "overlined-title" }><PictureAsPdfIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> <strong>Documentos</strong></h4>
            <ul className="file-sended-list">
              {
                files.length > 0 && files.map((file, counter)=>(
                  <li key={counter}><DescriptionIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> {file.name}</li>
                ))
              }
            </ul>
            {
              files.length === 0 && (
                <center>
                  <h5 style={{color:'#4E4B59'}}>
                    Visualize documentos para assinatura aqui.
                  </h5>
                </center>
              )
            }
          </Card>
        </div>
        <div id="signatories-modals">
          <div id="add-signatories-modal">
            <Modal
              show={openAddSignatoriesModal}
              onHide={handleClose}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Form id="form-add-signatory">
                <Modal.Header closeButton>
                  <Modal.Title>Signatário novo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Container>
                    <Row>
                      <Col>
                        <Row>
                          <span><strong>DADOS PESSOAIS</strong></span>
                        </Row>
                        <Row>
                          <label htmlFor="input-signatory-email">Email</label>
                          <Form.Control
                            required
                            value={email}
                            placeholder="Digite o e-mail do signatário"
                            id={"input-signatory-email"}
                            onChange={(e)=>setEmail(e.target.value)}/>
                        </Row>
                        <Row>
                          <label htmlFor="input-signatory-name">Nome completo</label>
                          <Form.Control
                            required
                            value={name}
                            placeholder="Ex: José Silva"
                            id={"input-signatory-name"}
                            onChange={(e)=>setName(e.target.value)}/>
                        </Row>
                        <Row>
                          <Col>
                            <Row>
                              <label htmlFor="input-signatory-name">CPF</label>
                              <Form.Control
                                required
                                value={socialNumber}
                                placeholder="999.999.999-99"
                                id={"input-signatory-social-number"}
                                onChange={(e)=>setSocialNumber(e.target.value)}/>
                            </Row>
                          </Col>
                          <Col>
                            <Row>
                              <label htmlFor="input-signatory-name">Data de nascimento</label>
                            </Row>
                            <Row>
                              <DatePicker
                                required
                                selected={birthDate}
                                id={"input-signatory-birthdate"}
                                placeholderText="DD/MM/AAAA"
                                dateFormat="dd/MM/yyyy"
                                locale="ptBR"
                                onChange={(date)=>setBirthDate(date)}
                              />
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mt-2 mb-2">
                          <Col xs={1}>
                            <Form.Check
                              id={"input-foreign"}
                              checked={foreign}
                              onChange={()=>setForeign(!foreign)}
                            />
                          </Col>
                          <Col xs={11}>
                            <label htmlFor={"input-foreign"}>
                              Este destinatário é estrangeiro
                            </label>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={1}>
                            <Form.Check
                              id={"input-foreign"}
                              checked={saveInNotebook}
                              onChange={()=>setSaveInNotebook(!saveInNotebook)}
                            />
                          </Col>
                          <Col xs={11}>
                            <label htmlFor={"input-foreign"}>
                              Salvar na agenda
                            </label>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Cancelar
                  </Button>
                  <Button variant="primary" onClick={(e) => handleAddSignatory(e)}>
                    Avançar
                  </Button>
                </Modal.Footer>
              </Form>
            </Modal>
          </div>
        </div>
        <div id="settings" className={activeMenu === 2 ? "current-page" : "non-current-page"}>
          <Card body>
            <Row>
              <Col>
                <h3>Configurações</h3>
              </Col>
            </Row>
            <Form id={"form-document-settings"}>
              <Row className={"settings-box"}>
                <Col>
                  <span><strong>DATA LIMITE PARA ASSINATURAS</strong></span>
                  <p>Assinatuas não poderão ser efetuadas após atingida a data limite</p>
                  <DatePicker
                    required
                    selected={deadline}
                    id={"input-signatory-birthdate"}
                    placeholderText="DD/MM/AAAA"
                    dateFormat="dd/MM/yyyy"
                    locale="ptBR"
                    onChange={(date)=>setDeadline(date)}
                  />
                </Col>
              </Row>
              <Row className={"settings-box"}>
                <Col>
                  <span><strong>LEMBRETES AUTOMÁTICOS VIA E-MAIL</strong></span>
                  <p>Envio de até 3 lembretes por e-mail</p>
                  <Row>
                    <Col xs={3}>
                      <p style={{display:'flex',flexDirection:'row',alignItems:'center'}}> <Form.Check
                        id={"input-foreign"}
                        checked={foreign}
                        onChange={()=>setForeign(!foreign)}
                      />
                        &nbsp; Enviar lembretes automaticamente a cada</p>
                    </Col>
                    <Col xs={1}>
                      <Form.Select onChange={(e)=>setRemindInterval(parseInt(e.target.value))}>
                        <option value="1">1 dia</option>
                        <option value="2">2 dia</option>
                        <option value="3">3 dias</option>
                        <option value="7">7 dias</option>
                        <option value="14">14 dias</option>
                      </Form.Select>
                    </Col>
                    <Col xs={8}></Col>
                  </Row>
                </Col>
              </Row>
              <Row className={"settings-box"}>
                <Row>
                  <Col>
                    <span><strong>IDIOMA</strong></span>
                    <p>A página de assinatura e os e-mails serão exibidos no idioma selecionado</p>
                  </Col>
                </Row>
                <Row>
                  <Col xs={2}>
                    <Form.Select onChange={(e)=>setLocale(e.target.value)}>
                      <option value="pt-BR">Portguês</option>
                      <option value="en-US">Inglês</option>
                    </Form.Select>
                  </Col>
                </Row>
              </Row>
              <Row className={"settings-box"}>
                <Row>
                  <Col>
                    <span><strong>FINALIZAÇÃO</strong></span>
                    <p>Quando os documentos enviados devem ser finalizados?</p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Após todos assinarem, automaticamente</p>
                  </Col>
                </Row>
              </Row>
            </Form>
            <Row>
              <Col
                md={{ span: 2, offset: 10 }}
                style={{
                  justifyContent:'space-evenly',
                  textAlign:'center',
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'end',
                  marginTop:'20px',
                  marginBottom:'20px'
                }}
              >
                <Button p={[1, 2]} onClick={()=>setActiveMenu(1)}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Voltar
                  </Flex>
                </Button>
                <Button p={[1, 2]} className={"bonuz-sign-primary"} onClick={()=>setActiveMenu(3)}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Avançar
                  </Flex>
                </Button>
              </Col>
            </Row>
          </Card>
        </div>
        <div id="broadcast-message" className={activeMenu === 3 ? "current-page" : "non-current-page"}>
          <Card body>
            <Row>
              <SendIcon sx={[{ color: "#4E4B59" }, { fontSize: 88 }]}/>
            </Row>
            <Row>
              <h3><strong>Mensagem e envio</strong></h3>
              <p>
                Esta mensagem irá para todos os signatários. Se a mensagem for respondida, será encaminhada diretamente para o seu e-mail.
              </p>
            </Row>
            <Row>
              <Form.Control
                onChange={(e)=>setMessage(e.target.value)}
                as="textarea"
                style={{ height: '150px' }}
              />
            </Row>
          </Card>
          <Card footer>
            <Row>
              <Col
                md={{ span: 2, offset: 10 }}
                style={{
                  justifyContent:'space-evenly',
                  textAlign:'center',
                  display:'flex',
                  flexDirection:'row',
                  alignItems:'end',
                  marginTop:'20px',
                  marginBottom:'20px'
                }}
              >
                <Button p={[1, 2]} onClick={()=>setActiveMenu(2)}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Voltar
                  </Flex>
                </Button>
                <Button p={[1, 2]} className={"bonuz-sign-primary"} onClick={()=>handleSendDocumentsToSign()}>
                  <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                    Avançar
                  </Flex>
                </Button>
              </Col>
            </Row>
          </Card>
          <Row>
            <Card body style={{marginTop:'20px'}}>
              <h4 className={files.length > 0 && "overlined-title" }><PictureAsPdfIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> <strong>Documentos</strong></h4>
              <ul className="file-sended-list">
                {
                  files.length > 0 && files.map((file, counter)=>(
                    <li key={counter}><DescriptionIcon sx={[{ color: "#9997A0" }, { fontSize: 20 }]}/> {file.name}</li>
                  ))
                }
              </ul>
              {
                files.length === 0 && (
                  <center>
                    <h5 style={{color:'#4E4B59'}}>
                      Visualize documentos para assinatura aqui.
                    </h5>
                  </center>
                )
              }
            </Card>
          </Row>
        </div>
        <div id="success-screen" className={activeMenu === 4 ? "current-page" : "non-current-page"}>
          <Card body className={"mt-5"}>
            <Row>
              <img src={SuccessBonuzSign} alt="Coruja encima de uma caixa de correio, ambas vermelhas"/>
            </Row>
            <Row>
              <h3><strong>Pronto!</strong></h3>
              <p>
                Seus documentos serão enviados para assinatura!
              </p>
            </Row>
            <Row>
              <Button p={[1, 2]} className={"bonuz-sign-primary"} onClick={()=> window.location.href = '/dashboard/gestao-contratual'}>
                <Flex justifyContent="center" alignItems="center" flexWrap="wrap">
                  Ver documentos
                </Flex>
              </Button>
            </Row>
          </Card>
        </div>
      </div>
    )
  }
}

export default BonuzSign;
