import React, { useCallback, useState, useEffect } from 'react'
import styled from 'styled-components'
import { Flex } from 'reflexbox'
import Label from 'ui-components/lib/components/Label'
import Spinner from 'ui-components/lib/components/Spinner'

const Container = styled.ul`
  list-style: none;
  margin: 0;
  padding: 5px 10px;
  border: solid 1px #eee;
  border-radius: 0 0 6px 6px;
  max-height: 350px;
  overflow: auto;

  li {
    min-width: 100%;
  }
`

const Button = styled.button`
  border: 0;
  width: 100%;
  padding: 10px;

  display: flex;
  align-items: flex-start;
  background: #fff;
  border-radius: 6px;

  :focus {
    outline: 0;
  }
  :hover {
    background: #eee;
  }
`

const SearchSuggestions = ({ suggestions, loading, setSearchResult, searchBlur, searchResult, handleChangeSearchTerm, setSearchTerm }) => {
  const [hidden, setHidden] = useState(false)

  const handleSelectedSubservice = useCallback(
    async ({ serviceId, subservice, title, description }) => {
      setSearchResult({
        serviceId,
        subservice,
        title,
        description,
      })
      setHidden(true)
      setSearchTerm(title)
    },
    [setSearchResult, searchResult, hidden] //eslint-disable-line
  )

  useEffect(() => {
    if (!searchBlur) setHidden(false)
  }, [searchBlur])

  if (suggestions?.length === 0)
    return (
      <Container hidden={searchBlur}>
        <li>
          <Label color="#aaa">Nenhum serviço foi encontrado.</Label>
        </li>
      </Container>
    )

  if (loading)
    return (
      <Flex as={Container} mt={1} width={1} alignItems="center" justifyContent="center">
        <Spinner color="primary" />
      </Flex>
    )

  return (
    <Container hidden={hidden && searchBlur}>
      {suggestions?.length > 0 &&
        suggestions?.map((suggestion) => (
          <li key={suggestion._id}>
            <Button
              onClick={() =>
                handleSelectedSubservice({
                  serviceId: suggestion.service._id,
                  subservice: suggestion,
                  title: suggestion.serviceFullName,
                  description: suggestion.description,
                })
              }
            >
              <Label color="#aaa">{suggestion.serviceFullName}</Label>
            </Button>
          </li>
        ))}
    </Container>
  )
}

export default SearchSuggestions
