import React from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import styled from 'styled-components'
import Typography from 'ui-components/lib/components/Typography'
import Spinner from 'ui-components/lib/components/Spinner'
import ProfileMenu from '../ProfileMenu'
import Notifications from '../Notifications'
import bnz_coin_icon from '../../images/coin.svg'


const Wrapper = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  width: 100%;
  backgroundColor: #FFFFFF;
`

const BoxLeft = styled(Box)`
  display: flex; 
  align-items: center;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    display: none!important;
  }
`

const BoxCenter = styled(Box)`
  display: flex; 
  align-items: center; 
  justify-content: center;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
  }
`

const BoxRight = styled(Box)`
  display: flex; 
  align-items: flex-end; 
  justify-content: flex-end;

  @media only screen and (max-width: 600px) {
    display: none!important;
  }
`

const BnzCoin = styled.img`
  width: 38px;
  height: 38px;
  box-sizing: border-box;
`

const FakeButton = styled.button`
  border: none;
  cursor: pointer;
  background: transparent;
`

const CustomText = styled.h4`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: #F14F5C;
  text-transform: uppercase;
`

const NavHeader = props => {
    const history = useHistory()
    if(props.jpLoading || props.plansLoading || props.dataPrivacyLoading){
      return (
        <div className="h-100 justify-content-center align-items-center d-flex">
          <Spinner color="primary" />
        </div>
      )
    }
    let planText = 'Plano Freemium';
    if (props.dataBonuzPlan) {
      if (props.dataBonuzPlan?.subscription?.planIdentifier === 'plano-essencial') {
        planText = 'Plano Essencial'
      } else if (props.dataBonuzPlan?.subscription?.planIdentifier === 'plano-premium') {
        planText = 'Plano Premium'
      }
    }
    if (props.dataPrivacy) {
      if (props.dataPrivacy?.subscription?.planIdentifier === 'data-privacy-semestral') {
        planText = 'Plano Data Privacy Semestral'
      } else if (props.dataPrivacy?.subscription?.planIdentifier === 'data-privacy-anual') {
        planText = 'Plano Data Privacy Anual'
      }
    }
    if (props.dataJp) {
      if (props.dataJp?.subscription?.planIdentifier === 'juridico-pessoal-basico') {
        planText = 'Plano Jurídico Familiar Básico'
      } else if (props.dataJp?.subscription?.planIdentifier === 'juridico-pessoal-intermediario') {
        planText = 'Plano Jurídico Familiar Intermediário'
      } else if (props.dataJp?.subscription?.planIdentifier === 'juridico-pessoal-vip') {
        planText = 'Plano Jurídico Familiar VIP'
      }
    }
    return (
      <Wrapper>
        <BoxLeft width={[1/3]} style={{color: "#F14F5C", fontWeight: 600, fontSize: 24}}>
          {planText}
        </BoxLeft>
        <BoxCenter width={[1/3]} className={!props.isJp ? "" : "d-none"}>
          <FakeButton onClick={() => history.push('/dashboard/carteira')} style={{marginTop: '4px', marginRight: '5px'}}>
            <BnzCoin src={bnz_coin_icon} alt="Moeda B.onuz" />
          </FakeButton>  
          <CustomText>{props.bnzBalance} BNZ</CustomText>
        </BoxCenter>
        <BoxRight width={[1/3]}>
          <Typography display={'flex'} alignItems={'center'} justifyContent={'flex-end'} 
            style={{
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontWeight: '400',
              color: 'rgba(0,0,0,0.4)',
            }}
          >
            { props.clientName ? props.clientName : <Spinner color="primary" /> }
          </Typography>              
          <ProfileMenu menuItems={props.profileMenuItems} style={{width: '42px', justifyContent: 'center', paddingBottom: '5px'}} />
          <Notifications menuItems={props.profileMenuItems} style={{width: '42px', justifyContent: 'center', paddingBottom: '5px'}} />
        </BoxRight>     
      </Wrapper>
    )
}

export default NavHeader
