import React, { useState, useEffect, useCallback } from 'react'
import InputMask from 'react-input-mask'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { walletQueries } from '../../graphql/queries'
import { walletsMutations } from '../../graphql/mutations'
import { termsQueries } from '../../graphql/queries'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import Select from 'ui-components/lib/components/Select'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import useModal from 'ui-components/lib/hooks/useModal'
import Modal from 'ui-components/lib/components/Modal'
import { useHistory } from 'react-router-dom'
import { Flex } from 'reflexbox'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CustomTextField from '../../components/CustomTextField'
import CustomSpinner from '../../components/CustomSpinner'
import PlansPresentation from '../../components/Modal/DataPrivacy/PlansPresentation'
import SuccessModal from '../../components/Modal/DataPrivacy/SuccessModal'
import { ReactComponent as BankSlipIcon } from "../../images/icons/bank_slip_icon.svg"
import { ReactComponent as CreditCardIcon } from "../../images/icons/credit_card_icon.svg"
import { ReactComponent as ModalCloseIcon } from "../../images/icons/modal_close_icon.svg"
import { ReactComponent as CheckedIcon } from "../../images/icons/checked_icon.svg"
import ImgWrapper from '../../components/ImageWrapper/ImgWrapper'
import header_modal from '../../images/header_modal_small.svg'
import { LoginButtonForm } from '../../components'
import { useDetectAdBlock } from '../../utils';
import styled from 'styled-components'
import "./PurchaseDataPrivacy.css"
import Terms from './terms'
const { REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID, REACT_APP_PAYMENT_GATEWAY_SANDBOX } = process.env


const FakeButton = styled.button`
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  outline:none;
  background: transparent;
`

const PurchaseDataPrivacy = (props) => {
  const { Iugu } = window

  const history = useHistory()
  const { showMessage } = useAlertStack()

  const [newCard, setNewCard] = useState(false)
  const [invoiceSuccess, setInvoiceSuccess] = useState(false)
  const [cardChecked, setCardChecked] = useState(false)
  const [creditCardChecked, setCreditCardChecked] = useState(false)
  const [bankSlipChecked, setBankSlipChecked] = useState(false)
  const [cardToken, setCardToken] = useState(null)
  const [cardBrandMask, setCardBrandMask] = useState('9999 9999 9999 9999')
  const [expirationMask, setExpirationMask] = useState('99/99')
  const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false)
  const [clientSubscription, setClientSubscription] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState('')
  const [subscriptionPrice, setSubscriptionPrice] = useState(0)
  const [subscriptionOriginalPrice, setSubscriptionOriginalPrice] = useState(0)  
  const [openPlanPresentationModal, setOpenPlanPresentationModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')

  const [plans, setPlans] = useState('')

  const adBlockDetected = useDetectAdBlock()

  const { isOpen: openTerms, openModal: openTermsModal, closeModal: closeTerms } = useModal()

  const { data: dataClientCards, refetch: cardsRefetch } = useQuery(walletQueries.GET_CARDS)
  
  const { loading: termsOfUseLoading } = useQuery(termsQueries.GET_TERMS_OF_USE)

  const { data: dataClientSubscription, loading: loadingClientSubscription, refetch: refetchMySubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'data-privacy'
    },
  })

  const [getVoucher, { data: dataVoucher, error: errorVoucher, loading: loadingVoucher }] = useLazyQuery(walletQueries.GET_VOUCHER)

  const [purchaseClientSubscription, { loading: loadingPurchaseSubscription }] = useMutation(walletsMutations.PURCHASE_SUBSCRIPTION, {
    onCompleted(response) {
      const { purchaseSubscription } = response
      const { invoiceUrl = '' } = purchaseSubscription

      if (invoiceUrl) {
        handleOpenSuccessModal()
        setInvoiceSuccess(invoiceUrl)
      }
    },
    onError(error) {
      showMessage({
        title: 'Assinatura não realizada',
        message: 'Ocorreu um erro ao processar sua assinatura, tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
    update: graphqlHelpers.deleteItemsFromCache('Card'),
  })


  const [saveCard, { loading: loadingSaveCard }] = useMutation(walletsMutations.SAVE_CARD, {
    onCompleted() {
      cardsRefetch()
      setNewCard(false)
    },
    update: graphqlHelpers.deleteItemsFromCache('Card'),
    onError(error) {
      alert('Erro desconhecido. Verifique as informações.')
    },
  })

  useEffect(() => {
    if(Iugu){
      const isSandbox = REACT_APP_PAYMENT_GATEWAY_SANDBOX === 'true' ? true : false
      Iugu.setAccountID(REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID)
      Iugu.setTestMode(isSandbox)
    }
  }, [Iugu]) // eslint-disable

  const handleChangeCard = () => {
    const element = document.getElementById('newPaymentCard')

    Iugu.createPaymentToken(element, (response) => {
      if (response.errors) {
        alert('Erro com o cartão. Por favor, verifique as informações.')
      } else {
        const { id: cardToken, extra_info } = response
        const { brand, display_number: displayNumber, holder_name: holderName, month, year } = extra_info

        saveCard({ variables: { token: cardToken, isDefault: true, brand, displayNumber, holderName, month, year } })
      }
    })
  }

  useEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription)

      const subscriptionPremium = (dataClientSubscription.subscription.price * 20) / 100

      setPlans([
        {
          plan: 'data-privacy-semestral',
          originalPrice: dataClientSubscription.subscription.price + subscriptionPremium,
          price: dataClientSubscription.subscription.price + subscriptionPremium
        },
        {
          plan: 'data-privacy-anual',
          originalPrice: dataClientSubscription.subscription.price,
          price: dataClientSubscription.subscription.price
        }
      ])
    }
  }, [dataClientSubscription]) //eslint-disable-line

  useEffect(() => {
    if(clientSubscription && clientSubscription.planIdentifier && clientSubscription.active) {
      history.push('/dashboard/data-privacy')
    }
  }, [clientSubscription]) //eslint-disable-line

  useEffect(() => {
    if (dataVoucher){
        const discountedPlans = plans.map((item, index) => {
          const discount = (item.originalPrice * Number(dataVoucher.voucher.discount)) / 100
          const discountedPrice = Math.round((item.originalPrice - discount), -1)

          return {
            ...item,
            price: discountedPrice
          }
        })

        setPlans(discountedPlans)
    }

    if (errorVoucher){
      const discountedPlans = plans.map((item, index) => {
        return { 
          ...item,
          price: item.originalPrice
        }
      })

      setPlans(discountedPlans)
    }

    setSubscriptionPlan('')
    setSubscriptionPrice('')
  }, [dataVoucher, errorVoucher]) //eslint-disable-line  

  const handleCardChecked = useCallback((event) => {
    const token = event.target.value

    setCardToken(token)

    setBankSlipChecked(false)
    setCardChecked(true)
  }, [])

  const handleCreditCardChecked = useCallback((event) => {
    setCreditCardChecked(true)
    setBankSlipChecked(false)
  }, [])

  const handleBankSlipChecked = useCallback((event) => {
    setCardChecked(false)
    setCardToken(null)
    setBankSlipChecked(true)
    setCreditCardChecked(false)
  }, [])

  const handleOpenPlanPresentationModal = useCallback((planType) => {
    if(planType === 'data-privacy-semestral'){
      setSelectedPlan('biannual')
    }

    if(planType === 'data-privacy-anual'){
      setSelectedPlan('annual')
    }

    setOpenPlanPresentationModal(!openPlanPresentationModal)
  }, []) //eslint-disable-line

  const handleAcceptTermsOfUse = () => {
    setAcceptedTermsOfUse(!acceptedTermsOfUse)
  }

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
        purchaseClientSubscription({
          variables: {
            subscription: 'data-privacy',
            plan: subscriptionPlan, 
            price: subscriptionPrice,
            originalPrice: subscriptionOriginalPrice,            
            cardToken,
            isBankSlip: bankSlipChecked,
            voucherId: dataVoucher ? dataVoucher.voucher.id : null
          },
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptionPlan, subscriptionPrice, bankSlipChecked, cardToken]
  )

  const updateCardBrandMask = useCallback((event) => {
    let brand
    const cardNumber = document.getElementById('number')

    if (cardNumber && Iugu) {
      brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber.value.replace(/\D/g, ''))
    }

    if(event.key === "Backspace"){
      setCardBrandMask("9999999999999999")
    } else {
      if (brand === 'amex') {
        setCardBrandMask('9999 9999999 9999')
      } else if (brand === 'diners') {
        setCardBrandMask('9999 999999 9999')
      } else {
        setCardBrandMask('9999 9999 9999 9999')
      }
    }
  }, []) //eslint-disable-line

  const updateExpirationMask = useCallback((event) => {
    if(event.key === "Backspace"){
      setExpirationMask("9999")
    } else {
      setExpirationMask('99/99')
    }
  }, [])

  const handleCheckVoucherCode = useCallback((event) => {
    const voucherCode = event.target.value

    if(voucherCode.length === 8){
      getVoucher({ variables: { voucherCode: voucherCode, subscriptionType: 'data-privacy' } })
    }
  }, []) //eslint-disable-line

  const handleSelectSubscription = (planIdentifier, planPrice, originalPrice) => {
    setSubscriptionPlan(planIdentifier)
    setSubscriptionPrice(planPrice)
    setSubscriptionOriginalPrice(originalPrice)    
  }

  const handleOpenSuccessModal = useCallback(() => {
    setOpenSuccessModal(!openSuccessModal)
  }, []) //eslint-disable-line

  const handleCloseModal = useCallback(() => {
    setOpenSuccessModal(false)
    refetchMySubscription()
  }, []) //eslint-disable-line
  


  if (!clientSubscription || loadingClientSubscription || clientSubscription.active) {
    return (
      <Box className="purchase-data-privacy-wrapper">
        <Box className="purchase-data-privacy-wrapper-active-subscription">
          {loadingClientSubscription && (
            <CustomSpinner />
          )}
          {clientSubscription.active && (
            <>
              <h2><b>Sua assinatura está ativa!</b></h2>
              <h6>
                Você possui uma assinatura ativa para o plano
                {clientSubscription.planIdentifier === 'data-privacy-semestral' && ' semestral '}
                {clientSubscription.planIdentifier === 'data-privacy-anual' && ' anual '}
                do data privacy.
              </h6>
              <h6>Para quaisquer dúvidas, solicitações e assuntos relacionados ao data privacy entre em contato conosco.</h6>
            </>
          )}
          {!clientSubscription && !loadingClientSubscription && (
            <>
              <h2><b>Assinatura data privacy</b></h2>            
              <h6>Você deve preencher as perguntas antes de prosseguir com o pagamento!</h6>
              <Button
                name="button-purchase-data-privacy-secondary"
                onClick={() => { history.goBack() }}
                className="purchase-data-privacy-secondary-button"
              >
                Voltar
              </Button>
            </>
          )}
        </Box>
      </Box>
    )
  }


  return (
    <Box className="purchase-data-privacy-wrapper">
      <Box className="purchase-data-privacy-inner-wrapper">
        <Box className="purchase-data-privacy-left-container">
          Você Selecionou:
          {plans.map((item, index) => (
            <FakeButton onClick={() => handleSelectSubscription(item.plan, item.price, item.originalPrice)}>
              <Box
                className="purchase-data-privacy-box-plans" 
                style={
                  subscriptionPlan === item.plan ? {borderColor:'#2C4474'} : {borderColor:'#9997A0'}
                }>
                <input
                  name="plano-data-privacy"
                  type="radio"
                  value="data-privacy"
                  checked={subscriptionPlan === item.plan}
                  // onChange={handleSelectSubscription}
                  style={{marginTop: '7px'}}
                />
                <Box className="purchase-data-privacy-box-plans-section-left">
                  <h2>
                    {`Plano `}
                    {item.plan === 'data-privacy-semestral' && 'Semestral'}
                    {item.plan === 'data-privacy-anual' && 'Anual'}
                  </h2>
                  <h4>Data Privacy</h4>
                  {item.plan === 'data-privacy-anual' && (
                    <p>Tenha acesso completo ao dashboard.</p>
                  )}
                </Box>
                <Box className="purchase-data-privacy-box-plans-section-right">
                  <h2>R$ {item.price},00</h2>
                  <h4>mensal</h4>
                  <input 
                    name="button-show-plano-basico"
                    type="button"
                    class="purchase-data-privacy-link-button-secondary"
                    value="Ver plano"
                    onClick={() => handleOpenPlanPresentationModal(item.plan)}
                  />
                </Box>
              </Box>
            </FakeButton>
          ))}
          <Box className="purchase-data-privacy-coupon-container" style={{marginTop:'15px'}}>
            CUPOM DE DESCONTO
            <Box className="purchase-data-privacy-coupon-field">
              <CustomTextField
                id="voucherCode"
                name='voucherCode'
                placeholder='CUPOM10%'
                helperText={errorVoucher && (
                  (((((errorVoucher.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).error
                )}
                onChange={handleCheckVoucherCode}
              />
              {loadingVoucher && (
                <CustomSpinner />
              )}
              {errorVoucher && (
                <ModalCloseIcon width={26} height={24} style={{marginTop:'7px'}} />
              )}
              {dataVoucher && (
                <CheckedIcon width={26} height={24} style={{marginTop:'7px'}} />
              )}
            </Box>
          </Box>
          <Box className="purchase-data-privacy-left-container-bottom-section">
            <input
              name="checkbox-policy-terms"
              type="checkbox"
              onClick={() => handleAcceptTermsOfUse()}
              checked={acceptedTermsOfUse}
            />
              Li e aceito os
            <input
              name="button-modal-policy-terms"
              type="button"
              class="purchase-data-privacy-link-button"
              value="Termos de Uso"
              onClick={() => openTermsModal()}
              disabled={termsOfUseLoading}
            />
          </Box>
        </Box>
        <Box className="purchase-data-privacy-right-container">
          <form onSubmit={handleSubmit}>
            <Box className="purchase-data-privacy-payment-wrapper">
              <Box className="purchase-data-privacy-payment-wrapper-header">
                Formas de pagamento
              </Box>
              <Box className="purchase-data-privacy-payment-wrapper-middle">
                <Box className="purchase-data-privacy-payment-wrapper-type-select">
                  <input
                    id="paymentTypeBankSlip"
                    className="purchase-data-privacy-radio-button"
                    name="paymentType"
                    onClick={() => handleBankSlipChecked()}
                    checked={bankSlipChecked}
                    type="radio"
                  />
                  <BankSlipIcon style={{width: '40px', height: '24px', margin: '0 12px 0 16px'}} />
                  <Box>
                    Boleto bancário
                  </Box>
                </Box>
                <Box className="purchase-data-privacy-payment-wrapper-type-select">
                  <input 
                    id="paymentTypeCreditCard"
                    className="purchase-data-privacy-radio-button"
                    name="paymentType"
                    onClick={() => handleCreditCardChecked()}
                    checked={creditCardChecked}
                    type="radio"
                  />
                  <CreditCardIcon style={{width: '40px', height: '30px', margin: '0 12px 0 16px'}} />
                  <Box>
                    Cartão de Crédito
                  </Box>
                </Box>
                <Box className="purchase-data-privacy-creditcard-wrapper">
                  {!newCard ? (
                    creditCardChecked && (
                      <Box className="purchase-data-privacy-creditcard-select">
                        Selecionar cartão
                        <Select
                          name="select-payment-card"
                          onChange={handleCardChecked}
                          defaultValue=""
                        >
                          {(!!dataClientCards?.cards?.length) ? (
                            [...dataClientCards?.cards]?.map((card, index) => (
                              index === 0 ? (
                                <>
                                  <option style={{width: '100%'}} key={""} value={""} disabled>
                                    {'Selecione'}
                                  </option>
                                  <option key={card.paymentMethodId || card.token} value={card.paymentMethodId || card.token}>
                                    {card.brand} - {card.displayNumber}
                                  </option>
                                </>
                              ) : (
                                  <option key={card.paymentMethodId || card.token} value={card.paymentMethodId || card.token}>
                                    {card.brand} - {card.displayNumber}
                                  </option>
                                )
                            ))
                          ) : (
                            <option key={""} value={""} disabled>
                              Nenhum cartão cadastrado
                            </option>
                          )}
                        </Select>
                      </Box>
                    )
                  ) : (
                    <Box className="purchase-data-privacy-add-card-container">
                      <form id="newPaymentCard">
                        <Box className="purchase-data-privacy-add-card-fields-container">
                          <Box className="purchase-data-privacy-add-card-field">
                            Número do cartão
                            <InputMask mask={cardBrandMask} onChange={updateCardBrandMask} onKeyDown={updateCardBrandMask} maskChar={null}>
                              {(inputCardProps) => (
                                <CustomTextField
                                  id="number"
                                  name="number"
                                  placeholder="**** **** **** ****"
                                  inputProps={{ 'data-iugu': 'number' }}
                                  {...inputCardProps}
                                />
                              )}
                            </InputMask>
                          </Box>
                          <Box className="purchase-data-privacy-add-card-field">
                            Nome do cartão
                            <CustomTextField
                              id="cardName"
                              name='cardName'
                              placeholder='Nome do cartão'
                              inputProps={{ 'data-iugu': 'full_name' }}
                            />
                          </Box>
                        </Box>
                          <Box className="purchase-data-privacy-add-card-fields-container">
                            <Box className="purchase-data-privacy-add-card-field">
                              Vencimento
                              <InputMask mask={expirationMask} maskChar={null} onChange={updateExpirationMask} onKeyDown={updateExpirationMask}>
                                {(inputCardProps) => (
                                  <CustomTextField
                                    id="cardExpire"
                                    name='cardExpire'
                                    placeholder='xx/xx'
                                    inputProps={{ 'data-iugu': 'expiration' }}
                                    {...inputCardProps}
                                  />
                                )}
                              </InputMask>
                            </Box>
                            <Box className="purchase-data-privacy-add-card-field">
                              Cód. segurança
                              <InputMask mask="999?" formatChars={{ "9": "[0-9]", "?": "[0-9 ]" }} maskChar={null} >
                                {(inputProps) => (
                                  <CustomTextField
                                    id='safe_number'
                                    name='safe_number'
                                    inputProps={{ 'data-iugu': 'verification_value' }}
                                    placeholder="***"
                                  />
                                )}
                              </InputMask>
                            </Box>
                          </Box>
                      </form>
                    </Box>
                  )}
                </Box>
                <Box className="purchase-data-privacy-payment-new-creditcard">
                  <Button
                    name="button-purchase-data-privacy-secondary"
                    onClick={() => setNewCard(!newCard)}
                    className="purchase-data-privacy-secondary-button"
                  >
                    {!newCard ? 'Adicionar novo cartão de crédito' : 'Usar outro método de pagamento'}
                  </Button>
                </Box>
                <Box className="purchase-data-privacy-payment-disclaimer">
                  *O pagamento é mensal e será debitado do cartão cadastrado se selecionado.
                </Box>
              </Box>
              {adBlockDetected ? (
                <Box className="purchase-data-privacy-detectadblock-section">
                  * Você possui um bloqueador de anúncios ativado no seu navegador. Por favor desabilite o bloqueador de 
                    anúncios durante a compra pois podem ocorrer erros inesperados para adicionar novo cartão de crédito ou 
                    durante o processamento das transações.
                </Box>
              ) : (
                ''
              )}
              <Box className="purchase-data-privacy-payment-buttons-container">
                {newCard ? (
                  <>
                    <Button
                      name="button-purchase-data-privacy-secondary"
                      onClick={() => setNewCard(!newCard)}
                      className="purchase-data-privacy-secondary-button"
                    >
                      Cancelar
                    </Button>
                    <Button
                      name="button-purchase-data-privacy-main"
                      disabled={loadingSaveCard}
                      onClick={() => handleChangeCard()}
                      className="purchase-data-privacy-main-button"
                    >
                      {loadingSaveCard ? <CustomSpinner color="#FFFFFF" /> : 'Adicionar'}
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      name="button-purchase-data-privacy-secondary"
                      onClick={() => { history.goBack() }}
                      className="purchase-data-privacy-secondary-button"
                    >
                      Voltar
                    </Button>
                    <Button
                      name="button-purchase-data-privacy-main"
                      disabled={(!cardChecked && !bankSlipChecked) || !acceptedTermsOfUse || !subscriptionPlan}
                      className="purchase-data-privacy-main-button"
                      type="submit"
                    >
                      {loadingPurchaseSubscription ? <CustomSpinner color="#FFFFFF" /> : 'Comprar'}
                    </Button>
                  </>
                )}
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Modal
        id="termsModal"
        open={openTerms}
        onClose={closeTerms}
        boxProps={{ p: 28 }}
        contentModalProps={{ width: '80vw' }}
      >
        <Flex flexDirection="column" alignItems={'center'}>
          <ImgWrapper src={header_modal} alt={'header modal - bonuz'} style={{width: '100%', borderRadius: '8px'}} />
          <Flex mt={3} flexDirection="column"
           style={{fontFamily: "'Inter', Helvetica, sans-serif", fontSize: 14, fontWeight: 400, color: '#4E4B59'}}
          >
            <Terms/>
          </Flex>
          <Flex width={1/5} mt={4} alignItems="flex-end">
            <LoginButtonForm
              name="button-close-policy-terms"
              buttonType={'primaryButton'}
              onClick={() => closeTerms()}
            >
              Fechar
            </LoginButtonForm>
          </Flex>
        </Flex>
      </Modal>
      <PlansPresentation
        openModal={openPlanPresentationModal}
        handleClose={() => setOpenPlanPresentationModal(false)}
        planType={selectedPlan}
      />
      <SuccessModal
        openModal={openSuccessModal}
        handleClose={handleCloseModal}
        invoiceLink={invoiceSuccess}
      />
    </Box>
  )
}

export default PurchaseDataPrivacy
