import React, { useEffect, useState } from 'react'
import UiBox from 'ui-components/lib/components/Box'
import './style.css'
import { MeetTopic, MeetDate, Payment, Confirm } from './views.js';
import {ReactComponent as Chat} from '../../assets/icons/TalkLawer/chat.svg'
import {ReactComponent as Calender} from '../../assets/icons/TalkLawer/calender.svg'
//import {ReactComponent as Clock} from '../../assets/icons/TalkLawer/clock.svg'
import {ReactComponent as Wallet} from '../../assets/icons/TalkLawer/wallet.svg'
import {ReactComponent as Check} from '../../assets/icons/TalkLawer/confirm.svg'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { clientsQueries, servicesQueries, walletQueries } from '../../graphql/queries'
import Spinner from 'ui-components/lib/components/Spinner'
import { demandsMutations } from '../../graphql/mutations'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
const TalkToLawer = (props) => {

    const { showMessage } = useAlertStack()

    const [cardView, setCardView] = useState(0);

    const changeView = (move) => {
      setCardView(move)
    }

    const [meetSubject,setMeetSubject] = useState(props.match.params.subject??"");
    const [meetDate,setMeetDate] = useState('');
    const [googleAgenda,setGoogleAgenda] = useState('');
    // const [meetSugestionHour,setMeetSugestionHour] = useState('');
    const [subservice,setSubservice] = useState({});
    const [subserviceQuestion,setSubserviceQuestion] = useState({})

    const { data: dataWallet, loading: loadingWallet } = useQuery(walletQueries.GET_MY_WALLET)

    const { data: dataMyName } = useQuery(clientsQueries.GET_MY_NAME)

    const { data: dataMyInfos, loading: loadingMyInfos} = useQuery(clientsQueries.GET_MY_INFOS)

    const [searchSubservice, {loading: loadingSearch }] = useLazyQuery(servicesQueries.SEARCH_SUBSERVICE, {
      onCompleted(data) {
        setSubservice(data.searchSubservice[0])

      },
    })

    const [getQuestions,{loading: subservicesQuestionsLoading}] = useLazyQuery(servicesQueries.GET_SUBSERVICES_QUESTIONS, {
      onCompleted(data){
        setSubserviceQuestion(data.subServicesQuestions)
      }
    })

    const { data: dataClientSubscription, loading:dataClientSubscriptionLoading} = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
      variables: {
        subscriptionType: 'juridico-pessoal'
      },
    })


    useEffect(()=>{
      searchSubservice({
          variables: { search: dataClientSubscription !== undefined ? 'Atendimento Jurídico Familiar' : 'fale com advogado bonuz' },
      })

      if(subservice !== {}){
        getQuestions({
          variables: { subserviceId: subservice?._id }
        })
      }

    },[searchSubservice,subservice,getQuestions,dataClientSubscription])


    const [createDemand, { loading: loadingCreateDemand }] = useMutation((demandsMutations.CREATE_DEMAND), {
      onCompleted() {
          showMessage({
            title: 'Serviço solicitado',
            message: 'Novo serviço solicitado com sucesso.',
            color: 'success',
            position: 'bottom-left',
            time: 4000,
          })
          setCardView(3)
      },
      onError() {
        showMessage({
          title: 'Erro ao abrir serviço',
          message: 'Tente novamente mais tarde.',
          color: 'danger',
          position: 'bottom-left',
          time: 4000,
        })
      }
    })

    const createNewDemand = () => {
      let answers = [
        'TEMA DA REUNIÃO: ' + meetSubject,
        'Nome dos Participantes: ' + dataMyName?.me?.firstName + ' ' + dataMyName?.me?.lastName,
        `
              Email dos participantes: ${dataMyInfos?.me?.email}
              Google agenda: ${googleAgenda}
              `,

        `
                Disponibilidade: 
                Data e horários sugeridos: ${meetDate}
                
              `
              //Horários sugeridos: ${meetSugestionHour}
      ]

      let questionWithAnswers = subserviceQuestion.map((question, counter) => {
        return {
          "questionId": question._id,
          "attachments": [],
          "answer": answers[counter]
        }
      })

      let createObject = {
        "serviceId": subservice?.service?._id,
        "subserviceId": subservice?._id,
        "urgent": false,
        "bicolumn": false,
        "extraLanguage": false,
        "draft": false,
        "answers": questionWithAnswers
      }

      createDemand({
        variables: createObject
      })
    }


    const views = [
        {
            id: 0,
            title: 'Assunto da reunião',
            icon: <Chat className="icon"/>,
            view: <MeetTopic meetSubject={setMeetSubject} subjectName={meetSubject} walletData={dataWallet}  subservice={subservice} changeView={changeView} isJp={dataClientSubscription !== undefined}/>,
        },
        {
            id: 1,
            title: 'Data e hora da reunião',
            icon: <Calender className="icon"/>,
            view: <MeetDate meeetDate={setMeetDate} googleAgenda={setGoogleAgenda} changeView={changeView}/>,
        },
        {
            id: 2,
            title: 'Pagamento',
            icon: <Wallet className="icon"/>,
            view: <Payment  subservice={subservice} createDemand={createNewDemand} changeView={changeView} isJp={dataClientSubscription !== undefined}/>,
        },
        {
            id: 3,
            title: 'Confirmação',
            icon: <Check className="icon"/>,
            view: <Confirm changeView={changeView}/>,
        },
    ];

    if(loadingWallet || loadingSearch || subservicesQuestionsLoading || loadingMyInfos || dataClientSubscriptionLoading){
      return (
        <UiBox noBorder flexDirection={'column'} maxHeight={'100%'} className="mt-5 d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </UiBox>
      );
    }

    if(loadingCreateDemand){
      return (
        <UiBox noBorder flexDirection={'column'} maxHeight={'100%'} className="mt-5 d-flex justify-content-center align-items-center">
          <Spinner color="primary" />
        </UiBox>
      );
    }

    return (
        <UiBox noBorder flexDirection={'column'} maxHeight={'100%'} className="mt-5 d-flex justify-content-center align-items-center">
            <div className="talk-container card">
                <div className="lines">
                {views.map((section) => {
                    return (
                        <div className={cardView >= section.id ? "line active" : "line"} key={section.id}>
                            <h3>{section.title}</h3> <div className="rounded circle">{section.icon}</div><div className="circle-check"></div>
                        </div>
                    )
                })}

                </div>
                <div className="views">
                    {views[cardView].view}
                </div>
            </div>
        </UiBox>
    )
}

export default TalkToLawer;
