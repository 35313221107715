import React, { useState, useEffect, useCallback } from 'react'
import InputMask from 'react-input-mask'
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks'
import { walletQueries } from '../../graphql/queries'
import { walletsMutations } from '../../graphql/mutations'
import { termsQueries } from '../../graphql/queries'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import Select from 'ui-components/lib/components/Select'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import useModal from 'ui-components/lib/hooks/useModal'
import Modal from 'ui-components/lib/components/Modal'
import { useHistory } from 'react-router-dom'
import { Flex } from 'reflexbox'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CustomTextField from '../../components/CustomTextField'
import CustomSpinner from '../../components/CustomSpinner'
import PlansPresentation from '../../components/Modal/BonuzPlans/PlansPresentation'
import { ReactComponent as CreditCardIcon } from "../../images/icons/credit_card_icon.svg"
import { ReactComponent as ModalCloseIcon } from "../../images/icons/modal_close_icon.svg"
import { ReactComponent as CheckedIcon } from "../../images/icons/checked_icon.svg"
import ImgWrapper from '../../components/ImageWrapper/ImgWrapper'
import header_modal from '../../images/header_modal_small.svg'
import { LoginButtonForm } from '../../components'
import { useDetectAdBlock, formatNumberToBRL } from '../../utils';
import styled from 'styled-components'
import "./PurchaseBonuzPlans.css"
import Terms from './terms'
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min'
const { REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID, REACT_APP_PAYMENT_GATEWAY_SANDBOX } = process.env


const FakeButton = styled.button`
  width: 100%;
  cursor: pointer;
  margin: 0;
  padding: 0;
  border: 0;
  outline:none;
  background: transparent;
`

const PurchaseBonuzPlans = (props) => {
  const { Iugu } = window

  const history = useHistory()
  const { showMessage } = useAlertStack()
  const urlParams = new URLSearchParams(useLocation().search) 

  const urlSelectedPlan = urlParams.get('selectedPlan') || ''

  const [newCard, setNewCard] = useState(false)
  const [cardChecked, setCardChecked] = useState(false)
  const [creditCardChecked, setCreditCardChecked] = useState(false)
  const [cardToken, setCardToken] = useState(null)
  const [cardBrandMask, setCardBrandMask] = useState('9999 9999 9999 9999')
  const [expirationMask, setExpirationMask] = useState('99/99')
  const [acceptedTermsOfUse, setAcceptedTermsOfUse] = useState(false)
  const [clientSubscription, setClientSubscription] = useState('')
  const [subscriptionPlan, setSubscriptionPlan] = useState(urlSelectedPlan)
  const [subscriptionPrice, setSubscriptionPrice] = useState(0)
  const [subscriptionOriginalPrice, setSubscriptionOriginalPrice] = useState(0)  
  const [openPlanPresentationModal, setOpenPlanPresentationModal] = useState(false)
  const [selectedPlan, setSelectedPlan] = useState('')

  const [plans, setPlans] = useState([
    {
      plan: 'plano-essencial',
      originalPrice: 60000,
      price: 60000,
      borderColor: {borderColor:'#FB6D06'}
    },
    {
      plan: 'plano-premium',
      originalPrice: 130000,
      price: 130000,
      borderColor: {borderColor:'#3F3F3F'}
    },
  ])

  const adBlockDetected = useDetectAdBlock()

  const { isOpen: openTerms, openModal: openTermsModal, closeModal: closeTerms } = useModal()

  const { data: dataClientCards, refetch: cardsRefetch } = useQuery(walletQueries.GET_CARDS)
  
  const { loading: termsOfUseLoading } = useQuery(termsQueries.GET_TERMS_OF_USE)

  const { data: dataClientSubscription, loading: loadingClientSubscription, refetch: refetchMySubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'planos-bonuz',
      planIdentifier: 'bonuz-plans'
    },
  })

  const [getVoucher, { data: dataVoucher, error: errorVoucher, loading: loadingVoucher }] = useLazyQuery(walletQueries.GET_VOUCHER)

  const [purchaseClientSubscription, { loading: loadingPurchaseSubscription }] = useMutation(walletsMutations.PURCHASE_SUBSCRIPTION, {
    onCompleted(response) {
      const { purchaseSubscription } = response
      const { invoiceUrl = '' } = purchaseSubscription

      if (invoiceUrl) {
        showMessage({
          title: 'Assinatura realizada com sucesso',
          message: 'Sua assinatura foi realizada com sucesso.',
          color: 'success',
          position: 'bottom-left',
          time: 4000,
        })
        refetchMySubscription()
      }
    },
    onError(error) {
      showMessage({
        title: 'Assinatura não realizada',
        message: 'Ocorreu um erro ao processar sua assinatura, tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
    update: graphqlHelpers.deleteItemsFromCache('Card'),
  })


  const [saveCard, { loading: loadingSaveCard }] = useMutation(walletsMutations.SAVE_CARD, {
    onCompleted() {
      cardsRefetch()
      setNewCard(false)
    },
    update: graphqlHelpers.deleteItemsFromCache('Card'),
    onError(error) {
      alert('Erro desconhecido. Verifique as informações.')
    },
  })

  useEffect(() => {
    const { Iugu } = window

    const isSandbox = REACT_APP_PAYMENT_GATEWAY_SANDBOX === 'true' ? true : false
    Iugu.setAccountID(REACT_APP_PAYMENT_GATEWAY_ACCOUNT_ID)
    Iugu.setTestMode(isSandbox)
  }, []) // eslint-disable

  const handleChangeCard = () => {
    const element = document.getElementById('newPaymentCard')

    Iugu.createPaymentToken(element, (response) => {
      if (response.errors) {
        alert('Erro com o cartão. Por favor, verifique as informações.')
      } else {
        const { id: cardToken, extra_info } = response
        const { brand, display_number: displayNumber, holder_name: holderName, month, year } = extra_info

        saveCard({ variables: { token: cardToken, isDefault: true, brand, displayNumber, holderName, month, year } })
      }
    })
  }

  useEffect(() => {
    if (dataClientSubscription){
      setClientSubscription(dataClientSubscription.subscription)
    }
  }, [dataClientSubscription]) //eslint-disable-line

  const handleCardChecked = useCallback((event) => {
    const token = event.target.value

    setCardToken(token)
    setCardChecked(true)
  }, [])

  useEffect(() => {
    if (dataVoucher){
        const discountedPlans = plans.map((item, index) => {
          const discount = (item.originalPrice * Number(dataVoucher.voucher.discount)) / 100
          const discountedPrice = Math.round((item.originalPrice - discount), -1)

          return {
            ...item,
            price: discountedPrice
          }
        })

        setPlans(discountedPlans)
    }

    if (errorVoucher){
      const discountedPlans = plans.map((item, index) => {
        return { 
          ...item,
          price: item.originalPrice
        }
      })

      setPlans(discountedPlans)
    }

    setSubscriptionPlan(urlSelectedPlan)
    setSubscriptionPrice('')
  }, [dataVoucher, errorVoucher]) //eslint-disable-line

  const handleCreditCardChecked = useCallback((event) => {
    setCreditCardChecked(true)
  }, [])

  const handleOpenPlanPresentationModal = useCallback((planType) => {
    setSelectedPlan(planType)
    setOpenPlanPresentationModal(!openPlanPresentationModal)
  }, []) //eslint-disable-line

  const handleAcceptTermsOfUse = () => {
    setAcceptedTermsOfUse(!acceptedTermsOfUse)
  }

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault()
        purchaseClientSubscription({
          variables: {
            subscription: 'planos-bonuz',
            plan: subscriptionPlan, 
            price: subscriptionPrice,
            originalPrice: subscriptionOriginalPrice,            
            cardToken,
            isBankSlip: false,
            voucherId: dataVoucher ? dataVoucher.voucher.id : null
          },
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [subscriptionPlan, subscriptionPrice, cardToken]
  )

  const updateCardBrandMask = useCallback((event) => {
    let brand
    const cardNumber = document.getElementById('number')

    if (cardNumber && Iugu) {
      brand = Iugu.utils.getBrandByCreditCardNumber(cardNumber.value.replace(/\D/g, ''))
    }

    if(event.key === "Backspace"){
      setCardBrandMask("9999999999999999")
    } else {
      if (brand === 'amex') {
        setCardBrandMask('9999 9999999 9999')
      } else if (brand === 'diners') {
        setCardBrandMask('9999 999999 9999')
      } else {
        setCardBrandMask('9999 9999 9999 9999')
      }
    }
  }, []) //eslint-disable-line

  const updateExpirationMask = useCallback((event) => {
    if(event.key === "Backspace"){
      setExpirationMask("9999")
    } else {
      setExpirationMask('99/99')
    }
  }, [])

  const handleSelectSubscription = (planIdentifier, planPrice, originalPrice) => {
    setSubscriptionPlan(planIdentifier)
    setSubscriptionPrice(planPrice)
    setSubscriptionOriginalPrice(originalPrice)    
  }

  const handleCheckVoucherCode = useCallback((event) => {
    const voucherCode = event.target.value

    if(voucherCode.length === 8){
      getVoucher({ variables: { voucherCode: voucherCode, subscriptionType: 'juridico-pessoal' } })
    }
  }, []) //eslint-disable-line


  return (
    <Box className="purchase-bonuz-plans-wrapper">
      {loadingClientSubscription || clientSubscription.active ? (
        <Box className="purchase-bonuz-plans-wrapper-active-subscription">
          {loadingClientSubscription && (
            <CustomSpinner />
          )}
          {clientSubscription.active && (
            <>
              <h2><b>Sua assinatura está ativa!</b></h2>
              <h6>Para quaisquer dúvidas e assuntos relacionados aos nossos planos entre em contato conosco.</h6>
            </>
          )}
        </Box>
      ) : (
        <Box className="purchase-bonuz-plans-inner-wrapper">
          <Box className="purchase-bonuz-plans-left-container">
            Você Selecionou:
            {plans.map((item, index) => (
              <FakeButton onClick={() => handleSelectSubscription(item.plan, item.price, item.originalPrice)}>
                <Box 
                  className="purchase-bonuz-plans-box-plans" 
                  style={
                    subscriptionPlan === item.plan ? item.borderColor : {borderColor:'#9997A0'}
                  }>
                  <input
                    name="plano-basico"
                    type="radio"
                    className='radio-input'
                    value="juridico-pessoal-basico" 
                    checked={subscriptionPlan === item.plan}
                    // onChange={handleSelectSubscription}
                    style={{marginTop: '7px'}} 
                  />
                  <Box className="purchase-bonuz-plans-box-plans-section-left">
                    <h2>
                      {`Plano `}
                      {item.plan === 'plano-essencial' && 'Essencial'}
                      {item.plan === 'plano-premium' && 'Premium'}
                    </h2>
                    <h4>Jurídico empresarial</h4>
                  </Box>
                  <Box className="purchase-bonuz-plans-box-plans-section-right">
                    <Box className="purchase-bonuz-plans-box-plans-section-right-inner-box">
                      <h2>{formatNumberToBRL(item.price / 100)}</h2>
                      <h4 style={{marginLeft:'2px'}}>Mês</h4>
                    </Box>
                    <input 
                      name="button-show-plano-basico"
                      type="button" 
                      class="purchase-bonuz-plans-link-button-secondary"
                      value="Ver entregáveis"
                      onClick={() => handleOpenPlanPresentationModal(item.plan)}
                    />
                  </Box>
                </Box>
              </FakeButton>
            ))}
            <Box className="purchase-bonuz-plans-coupon-container" style={{marginTop:'15px'}}>
              CUPOM DE DESCONTO
              <Box className="purchase-bonuz-plans-coupon-field">
                <CustomTextField
                  id="voucherCode"
                  name='voucherCode'
                  placeholder='CUPOM10%'
                  helperText={errorVoucher && (
                    (((((errorVoucher.graphQLErrors || [])[0] || {}).extensions || {}).response || {}).body || {}).error
                  )}
                  onChange={handleCheckVoucherCode}
                />
                {loadingVoucher && (
                  <CustomSpinner />
                )}
                {errorVoucher && (
                  <ModalCloseIcon width={26} height={24} style={{marginTop:'7px'}} />
                )}
                {dataVoucher && (
                  <CheckedIcon width={26} height={24} style={{marginTop:'7px'}} />
                )}
              </Box>
            </Box>
            <Box className="purchase-bonuz-plans-left-container-bottom-section">
              <input
                name="checkbox-policy-terms"
                type="checkbox"
                onClick={() => handleAcceptTermsOfUse()}
                checked={acceptedTermsOfUse} 
              />
                Li e aceito os
              <input 
                name="button-modal-policy-terms"
                type="button" 
                class="purchase-bonuz-plans-link-button"
                value="Termos de Uso"
                onClick={() => openTermsModal()} 
                disabled={termsOfUseLoading}
              />
            </Box>
          </Box>
          <Box className="purchase-bonuz-plans-right-container">
            <form onSubmit={handleSubmit}>
              <Box className="purchase-bonuz-plans-payment-wrapper">
                <Box className="purchase-bonuz-plans-payment-wrapper-header">
                  Formas de pagamento
                </Box>
                <Box className="purchase-bonuz-plans-payment-wrapper-middle">
                  <Box className="purchase-bonuz-plans-payment-wrapper-type-select">
                    <input 
                      id="paymentTypeCreditCard"
                      className="purchase-bonuz-plans-radio-button"
                      name="paymentType"
                      onClick={() => handleCreditCardChecked()}
                      checked={creditCardChecked}
                      type="radio"
                    />
                    <CreditCardIcon style={{width: '40px', height: '30px', margin: '0 12px 0 16px'}} />
                    <Box>
                      Cartão de Crédito
                    </Box>
                  </Box>
                  <Box className="purchase-bonuz-plans-creditcard-wrapper">
                    {!newCard ? (
                      creditCardChecked && (
                        <Box className="purchase-bonuz-plans-creditcard-select">
                          Selecionar cartão
                          <Select
                            name="select-payment-card"
                            onChange={handleCardChecked}
                            defaultValue=""
                          >
                            {(!!dataClientCards?.cards?.length) ? (
                              [...dataClientCards?.cards]?.map((card, index) => (
                                index === 0 ? (
                                  <>
                                    <option style={{width: '100%'}} key={""} value={""} disabled>
                                      {'Selecione'}
                                    </option>
                                    <option key={card.paymentMethodId || card.token} value={card.paymentMethodId || card.token}>
                                      {card.brand} - {card.displayNumber}
                                    </option>
                                  </>
                                ) : (
                                    <option key={card.paymentMethodId || card.token} value={card.paymentMethodId || card.token}>
                                      {card.brand} - {card.displayNumber}
                                    </option>
                                  )
                              ))
                            ) : (
                              <option key={""} value={""} disabled>
                                Nenhum cartão cadastrado
                              </option>                          
                            )}
                          </Select>
                        </Box>
                      )
                    ) : (
                      <Box className="purchase-bonuz-plans-add-card-container">
                        <form id="newPaymentCard">
                          <Box className="purchase-bonuz-plans-add-card-fields-container">
                            <Box className="purchase-bonuz-plans-add-card-field">
                              Número do cartão
                              <InputMask mask={cardBrandMask} onChange={updateCardBrandMask} onKeyDown={updateCardBrandMask} maskChar={null}>
                                {(inputCardProps) => (
                                  <CustomTextField
                                    id="number"
                                    name="number"
                                    placeholder="**** **** **** ****"
                                    inputProps={{ 'data-iugu': 'number' }}
                                    {...inputCardProps}
                                  />
                                )}
                              </InputMask>
                            </Box>
                            <Box className="purchase-bonuz-plans-add-card-field">
                              Nome do cartão
                              <CustomTextField
                                id="cardName"
                                name='cardName'
                                placeholder='Nome do cartão'
                                inputProps={{ 'data-iugu': 'full_name' }}
                              />
                            </Box>
                          </Box>
                            <Box className="purchase-bonuz-plans-add-card-fields-container">
                              <Box className="purchase-bonuz-plans-add-card-field">
                                Vencimento
                                <InputMask mask={expirationMask} maskChar={null} onChange={updateExpirationMask} onKeyDown={updateExpirationMask}>
                                  {(inputCardProps) => (
                                    <CustomTextField 
                                      id="cardExpire"
                                      name='cardExpire'
                                      placeholder='xx/xx'
                                      inputProps={{ 'data-iugu': 'expiration' }}
                                      {...inputCardProps}
                                    />
                                  )}
                                </InputMask>
                              </Box>
                              <Box className="purchase-bonuz-plans-add-card-field">
                                Cód. segurança
                                <InputMask mask="999?" formatChars={{ "9": "[0-9]", "?": "[0-9 ]" }} maskChar={null} >
                                  {(inputProps) => (
                                    <CustomTextField 
                                      id='safe_number' 
                                      name='safe_number'
                                      inputProps={{ 'data-iugu': 'verification_value' }} 
                                      placeholder="***"
                                    />
                                  )}
                                </InputMask>
                              </Box>
                            </Box>
                        </form>   
                      </Box>
                    )}
                  </Box>
                  <Box className="purchase-bonuz-plans-payment-new-creditcard">
                    <Button
                      name="button-purchase-bonuz-plans-secondary"
                      onClick={() => setNewCard(!newCard)} 
                      className="purchase-bonuz-plans-secondary-button"
                    >
                      {!newCard ? 'Adicionar novo cartão de crédito' : 'Usar outro método de pagamento'}
                    </Button>
                  </Box>
                  <Box className="purchase-bonuz-plans-payment-disclaimer">
                    *O pagamento é mensal e será debitado do cartão cadastrado.
                  </Box>
                </Box>
                {adBlockDetected ? (
                  <Box className="purchase-bonuz-plans-detectadblock-section">
                    * Você possui um bloqueador de anúncios ativado no seu navegador. Por favor desabilite o bloqueador de 
                      anúncios durante a compra pois podem ocorrer erros inesperados para adicionar novo cartão de crédito ou 
                      durante o processamento das transações.
                  </Box>
                ) : (
                  ''
                )}
                <Box className="purchase-bonuz-plans-payment-buttons-container">
                  {newCard ? (
                    <>
                      <Button
                        name="button-purchase-bonuz-plans-secondary"
                        onClick={() => setNewCard(!newCard)}
                        className="purchase-bonuz-plans-secondary-button"
                      >
                        Cancelar
                      </Button>                  
                      <Button
                        name="button-purchase-bonuz-plans-main"                  
                        disabled={loadingSaveCard} 
                        onClick={() => handleChangeCard()} 
                        className="purchase-bonuz-plans-main-button"
                      >
                        {loadingSaveCard ? <CustomSpinner color="#FFFFFF" /> : 'Adicionar'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        name="button-purchase-bonuz-plans-secondary"
                        onClick={() => { history.goBack() }}
                        className="purchase-bonuz-plans-secondary-button"
                      >
                        Voltar
                      </Button>
                      <Button
                        name="button-purchase-bonuz-plans-main"                  
                        disabled={!acceptedTermsOfUse || !subscriptionPlan || !cardChecked}
                        className="purchase-bonuz-plans-main-button"
                        type="submit"
                      >
                        {loadingPurchaseSubscription ? <CustomSpinner color="#FFFFFF" /> : 'Comprar'}                      
                      </Button>
                    </>
                  )}
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      )}
      <Modal
        id="termsModal"
        open={openTerms}
        onClose={closeTerms}
        boxProps={{ p: 28 }}
        contentModalProps={{ width: '80vw' }}
      >
        <Flex flexDirection="column" alignItems={'center'}>
          <ImgWrapper src={header_modal} alt={'header modal - bonuz'} style={{width: '100%', borderRadius: '8px'}} />
          <Flex 
            mt={3} 
            flexDirection="column" 
            style={{fontFamily: "'Inter', Helvetica, sans-serif", fontSize: 14, fontWeight: 400, color: '#4E4B59'}}
          >
            <Terms/>
          </Flex>
          <Flex width={1/5} mt={4} alignItems="flex-end">
            <LoginButtonForm 
              name="button-close-policy-terms"
              buttonType={'primaryButton'} 
              onClick={() => closeTerms()}
            >
              Fechar
            </LoginButtonForm>
          </Flex>
        </Flex>
      </Modal>
      <PlansPresentation 
        openModal={openPlanPresentationModal}
        handleClose={() => setOpenPlanPresentationModal(false)}
        planType={selectedPlan}
      />
    </Box>
  )
}

export default PurchaseBonuzPlans
