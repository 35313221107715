export const cardsList = [
  {
    title: 'essencial',
    colors: {
      title: '#751F91',
      line: '#751F91',
      background: '#FB6D06',
      button: '#041181'
    },
    monthlyValue: 600,
    options: [
      {
        title: '10% off na Gestão contratual',
        info: 'ANEXE CONTRATOS ANTERIORES JÁ assinados e AO FIM DO prazo NÓS TE SINALIZAREMOS DO VENCIMENTO, e customize alertas conforme o interesse'
      },
      {
        title: '50BNZs call com o advogado',
        info: 'Solicite call para consultoria com Advogado, por 30 minutos (50BNZs/call).\n' +
          'Você poderá comprar um call adicional sempre que precisar!'
      },
      {
        title: '1.350 BNZs para uso (mensal)',
        info: 'Use 1350BNZs/mês (R$2.700,00) em serviços jurídicos.'
      },
      {
        title: '10% off na compra de BNZs ',
        info: 'Possuímos mais de 141 serviços. Caso queira solicitar um serviço não listado nós garantimos 10% de desconto.'
      },
      {
        title: 'Legal account manager',
        info: 'Obtenha um advogado as a service especialista para sua empresa!',
        notCheck: true
      },
    ]
  },
  {
    title: 'PREMIUM',
    colors: {
      title: '#FFF',
      line: '#DBB412',
      background: '#252525',
      button: '#FFF'
    },
    monthlyValue: 1300,
    options: [
      {
        title: '20% off na Gestão contratual',
        info: 'ANEXE CONTRATOS ANTERIORES JÁ assinados e AO FIM DO prazo NÓS TE SINALIZAREMOS DO VENCIMENTO, e customize alertas conforme o interesse'
      },
      {
        title: '15 calls com o advogado',
        info: 'Solicite call para consultoria com Advogado, por 30 minutos (50BNZs/call).\n' +
          'Você poderá comprar um call adicional sempre que precisar!'
      },
      {
        title: 'Cobrança dinâmica com tabela premium',
        info: 'Pague no final do mês de acordo com os serviços utilizados, com uma tabela especial para você.'
      },
      {
        title: '20% off na compra de BNZs',
        info: 'Possuímos mais de 141 serviços. Caso queira solicitar um serviço não listado nós garantimos 20% de desconto.'
      },
      {
        title: 'Legal account manager',
        info: 'Obtenha um Advogado as a service especialista para sua empresa!'
      },
    ]
  }
]
