import React, { useState } from "react";
import { Box } from "@mui/material";

import IpEssencial from '../../../../../assets/img/pEssencial.png'
import IpFreemium from '../../../../../assets/img/pFreemium.png'
import IpPremium from '../../../../../assets/img/pPremium.png'
import './styles.css'
import { Link } from "react-router-dom";
import {  Table } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";

const WelcomeView = ({onSelectPlan}) => {
  const [view, setView] = useState(0)

  const handleView = () => {
    setView(1)
  }

  const FirstView = () => {
    return (
      <div className="d-flex justify-content-around mb-2">
        <div className="gray-card">
          <img src={IpFreemium} alt='' />
          <p>Indicado para demandas pontuais. Pague somente quando utilizar.</p>
          <button onClick={handleView} className="btn btn-green">Quero este plano</button>
        </div>
        <div className="gray-card">
          <img src={IpEssencial} alt='' />
          <p>Indicado para você que tem um certo volume de contratos por mês. Economize muito, em relação aos pedidos avulsos.</p>
          <button onClick={handleView} className="btn btn-green">Quero este plano</button>
        </div>
        <div className="gray-card">
          <img src={IpPremium} alt='' />
          <p>Indicado para você que precisa de um adv acompanhando a sua operação. Conte com uma assessoria jurídica que conhece o seu negócio.</p>
          <button onClick={handleView} className="btn btn-green">Quero este plano</button>
        </div>
    </div>
    )
  }

  const SecondView = () => (
    <Table borderless>
      <thead>
        <th>
          <div></div>
        </th>
        <th>
          <div>
            <h3>Freemium</h3>
            <p><small>Indicado para demandas pontuais. Pague somente quando utilizar.</small></p>
          </div>
        </th>
        <th>
          <div>
            <h3>Essencial</h3>
            <p><small>Indicado para você que tem um certo volume de contratos por mês. Economize muito, em relação aos pedidos avulsos.</small></p>
          </div>
        </th>
        <th>
          <div>
            <h3>Premium</h3>
            <p><small>Indicado para você que precisa de um adv acompanhando a sua operação. Conte com uma assessoria jurídica que conhece o seu negócio.</small></p>
          </div>
        </th>
      </thead>
      <tbody>
        <tr>
          <td>
            <div><h3>Serviços inclusos</h3></div>
          </td>
          <td>
            <div><span className="x"><FaTimes/></span></div>
          </td>
          <td>
            <div>
              <p>
                1350BNZs mensais para uso
              </p>
              <small>consulta os serviços inclusos ao clicar no botão</small>
            </div>
          </td>
          <td>
            <div>
              <p>Tabela dinânimca</p>
              <small>consulte a tabela ao clicar no botão</small>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <div><h3>Legal Account Manager</h3></div>
          </td>
          <td>
            <div><span className="x"><FaTimes/></span></div>
          </td>
          <td>
            <div><span className="x"><FaTimes/></span></div>
          </td>
          <td>
            <div><span className="check"><FaCheck/></span></div>
          </td>
        </tr>
        <tr>
          <td>
            <div><h3>Usuários</h3></div>
          </td>
          <td>
            <div><p>ILIMITADO</p></div>
          </td>
          <td>
            <div><p>ILIMITADO</p></div>
          </td>
          <td>
            <div><p>ILIMITADO</p></div>
          </td>
        </tr>
        <tr>
          <td>
            <div><h3>Desconto em serviços</h3></div>
          </td>
          <td>
            <div><span className="x"><FaTimes/></span></div>
          </td>
          <td>
            <div><p>até 10% na compra de BNZs</p></div>
          </td>
          <td>
            <div><p>até 20% na compra de BNZs</p></div>
          </td>
        </tr>
        <tr>
          <td>
            <div><h3>Call com Advogado</h3></div>
          </td>
          <td>
            <div><p>50BNZs</p></div>
          </td>
          <td>
            <div><p>50BNZs</p></div>
          </td>
          <td>
            <div><p>15 p/mês</p></div>
          </td>
        </tr>
        <tr>
          <td>
            <div><h3>Gestão Contratual</h3></div>
          </td>
          <td>
            <div><p>Sem desconto</p></div>
          </td>
          <td>
            <div><p>10% de desconto</p></div>
          </td>
          <td>
            <div><p>20% de desconto</p></div>
          </td>
        </tr>
        <tr>
          <td>
            <div><h3>SLA</h3></div>
          </td>
          <td>
            <div><p>2 a 6 dias úteis</p></div>
          </td>
          <td>
            <div><p>2 a 6 dias úteis</p></div>
          </td>
          <td>
            <div><p>à sua preferência</p></div>
          </td>
        </tr>
        <tr>
          <td>
            <div></div>
          </td>
          <td>
            <div>
              <button 
                className="btn btn-green"
                onClick={() => onSelectPlan('freemium')}
              >
                Quero este plano
              </button>
            </div>
          </td>
          <td>
            <div>
              <button 
                className="btn btn-green"
                onClick={() => onSelectPlan('essencial')}
              >
                Quero este plano
              </button>
            </div>
          </td>
          <td>
            <div>
              <button 
                onClick={() => onSelectPlan('premium')}
                className="btn btn-green"
              >
                Quero este plano
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </Table>
  )
  

  return (
    <Box className="box welcome-view">
      <h2>Bem-vindo à nossa plataforma!!</h2>
      <p>Selecione o plano ideal para você:</p>
      { view === 0 
      ? <FirstView /> 
      : <SecondView/>
      }
      <Link to='/dashboard/juridico-pessoal'>Quero serviços para pessoa física</Link>
    </Box>
  )
}

export default WelcomeView
