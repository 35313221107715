import React,{useState}from "react";
import { Flex, Box } from 'reflexbox';
import Typography from "ui-components/lib/components/Typography";
import FakeButton from "../Dashboard/FakeButton";
import Card from "ui-components/lib/components/Card";
import Modal from 'ui-components/lib/components/Modal'
import useModal from 'ui-components/lib/hooks/useModal'
import {useQuery} from "@apollo/react-hooks";
import {demandsQueries,documentsQueries, walletQueries} from "../../graphql/queries";
import { Markup } from 'interweave';
import './ClientDemandPanel.css'
import DynamicChart from "./DynamicChart";
import Spinner from "ui-components/lib/components/Spinner";


const ClientDemandsPanel = () => {
  let loading = true;

  const [period,setPeriod] = useState("last_30_days")
  const [userPlan,setUserPlan] = useState("")

  //Quering demands
  let demands = {
    pendingDemands:[],
    inProgressDemands:[],
    standbyDemands:[],
    waitingApprovalDemands:[],
    inEstimatingDemands:[],
    draftDemands:[],
    demandSentDemands:[],
    inReviewDemands:[],
    canceledDemands:[],
    doneDemands:[],
    waitingEstimateApprovalDemands:[],
    waitingLawyerAcceptanceDemands:[],
    clientPendencices:[],
    statuses:[
      'Pendente',
      'Em progresso',
      'Standby',
      'Aguardando aprovação',      
      'Aguardando Orçamento',
      'Pendente de informação',
      'Demanda Enviada',
      'Aguardando revisão',
      'Cancelado',
      'Concluído',
      'Aguardando aprovação de orçamento',
      'Aguardando aceite do advogado'
    ]
  }

  const { data: demandsData } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      queryBy: 'status',
      offset:999999
    }
  })

  let demandsToFilter = demandsData?.listDemands?.data;

  if(demandsToFilter !== undefined){
    demandsToFilter.filter((demand)=>{
        if(demand.status === 'pending'){
          demand.statuses = 'Pendente';
          demands.pendingDemands.push(demand)
        }
        if(demand.status === 'in_progress'){
            demand.statuses = 'Em Progresso';
            demands.inProgressDemands.push(demand)
        }
        if(demand.status === 'standby'){
          demand.statuses = 'Standby';
          demands.standbyDemands.push(demand)
        }
        if(demand.status === 'waiting_approval'){
          demand.statuses = 'Aguardando Aprovação';
          demands.waitingApprovalDemands.push(demand)
        }
        if(demand.status === 'in_estimating'){
          demand.statuses = 'Aguardando Orçamento';
          demands.inEstimatingDemands.push(demand)
        }
        if(demand.status === 'draft'){
          demand.statuses = 'Pendente de Informação';
          demands.draftDemands.push(demand)
        }
        if(demand.status === 'demand_sent'){
          demand.statuses = 'Demanda Enviada';
          demands.demandSentDemands.push(demand)
        }
        if(demand.status === 'in_review'){
          demand.statuses = 'Aguardando Revisão';
          demands.inReviewDemands.push(demand)
        }
        if(demand.status === 'canceled'){
          demand.statuses = 'Cancelado';
          demands.canceledDemands.push(demand)
        }
        if(demand.status === 'done'){
          demand.statuses = 'Concluído';
          demands.doneDemands.push(demand)
        }
        if(demand.status === 'waiting_estimate_approval'){
          demand.statuses = 'Aguardando Aprovação de Orçamento';
          demands.waitingEstimateApprovalDemands.push(demand)
        }
        if(demand.status === 'waiting_lawyer_acceptance'){
          demand.statuses = 'Aguardando Aceite do Advogado';
          demands.waitingLawyerAcceptanceDemands.push(demand)
        }
        return true
    });
    loading = false
  }

  //Quering documents
  let documents = {
    folderIds:[],
    folderNames:[],
    numberOfDocsInFolders:[]
  }

  const { data: documentsData } = useQuery(documentsQueries.GET_DOCUMENTS,{
    variables: {
      queryBy: 'status',
      offset:999999
    }
  })

  let foldersAndFilesToFilter = documentsData?.repositories?.folders;

  if(foldersAndFilesToFilter !== undefined){
    foldersAndFilesToFilter.filter((folderAndFile)=> {
        documents.folderIds.push(folderAndFile._id)
        documents.folderNames.push(folderAndFile.name);
        documents.numberOfDocsInFolders.push(folderAndFile.numberOfDocs)
        return true;
    })
  }

  let transactions = {
    type:[],
    date:[],
    description:[],
    valueReserve:[],
    valueDebit:[],
    valueCredit:[],
    totalExpended:0.0
  }


  const { data: walletData,  refetch: refetchMyStatements } = useQuery(walletQueries.GET_MY_STATEMENTS, {
    variables: {
      queryBy: 'type',
      offset:999999,
      filterPeriod:period
    }
  })

  const { loading: loadingClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
    onCompleted(data){
      setUserPlan(data?.subscription)
    }
  })

  function changePeriod(periodToChange){
      setPeriod(periodToChange)
  }

  let transactionsToFilter = walletData?.statements?.data;

  if(transactionsToFilter !== undefined){
    transactionsToFilter.filter((transaction)=>{
        let formatDate = new Date(transaction.createdAt).toLocaleDateString('pt-BR');
        let formatType = () => {
          if(transaction.type === 'reserve'){
            transactions.valueReserve.push(transaction.amount)
            return "reserva"
          }else if(transaction.type === 'debit'){
            transactions.valueDebit.push(transaction.amount)
            return "débito"
          }else if(transaction.type === 'credit'){
            transactions.valueCredit.push(transaction.amount)
            return "crédito"
          }
        }
        transactions.type.push(formatType())
        transactions.date.push(formatDate)
        transactions.description.push(transaction.description)
        transactions.totalExpended += transaction.amount
      return true
    })
  }
  function transformInList(array){
      if(array.length === 0){
        return "<h1>Sem demandas com este status</h1>"
      }
      let html = "<ul class='modal-list'>";
      array.forEach((item,counter)=>{
        html += `
            <li>
                <a href="/dashboard/serviços/detalhes/${item._id}" target="_blank">
                <p>
                    Código: <strong>#000${item.code}</strong> 
                    <br/>
                    <br/>
                    Descrição: <strong>${item.fullDescription}</strong> 
                    <br/>
                    <br/>
                    Status: <strong> ${item.statuses} </strong>
                </p>
                </a>
            </li>
        `;
      })
      html += "</ul>";
      return html;
  }


  const { isOpen, openModal, closeModal, params: modalParams } = useModal();

  if(loadingClientSubscription){
    return (
      <div className="h-100 justify-content-center align-items-center d-flex">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <div id="panel">
      <>
        <Modal open={isOpen} onClose={closeModal} header={<h3>{(modalParams || {}).title}</h3>}>
          {
            <Markup content={(modalParams || {}).content}/>
          }
        </Modal>
      </>
      <div id="demands-statistics">
        <h1>Estatisticas</h1>
          <h2>Demandas</h2>
          <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
            <Box mb={2}>
              <Typography style={{textTransform: 'uppercase'}} fontWeight="bold" m={0}>
                Suas pendências
              </Typography>
              <FakeButton onClick={() => openModal({ title:'Demandas pendentes',content:transformInList(demands.clientPendencices.concat(demands.waitingApprovalDemands,demands.waitingEstimateApprovalDemands,demands.draftDemands,demands.inReviewDemands))})} >
                <Box mr={4} ml={0} my={3} width={[1]}>
                  <Card
                    style={{backgroundColor: '#fd4f5d'}}
                    color="#fd4f5d"
                    counterColor="#fff"
                    textColor="#fff"
                    title={
                      <Typography m={0} fontSize={2} textAlign="left">
                        <strong>Pendências</strong>
                      </Typography>
                    }
                    count={
                      <Typography m={0} fontSize={5}>
                        {
                          loading ? ( <Spinner color="primary" /> ):demands.clientPendencices.concat(demands.waitingApprovalDemands,demands.waitingEstimateApprovalDemands,demands.draftDemands,demands.inReviewDemands).length
                        }
                      </Typography>
                    }
                  />
                </Box>
              </FakeButton>
            </Box>
            <Box mb={2}>
              <Typography style={{textTransform: 'uppercase'}} fontWeight="bold" m={0}>
                Gráfico geral de demandas
              </Typography>
              <div id="chart">
                <DynamicChart
                  type={"Bar"}
                  labels={demands.statuses}
                  labelTitle={"Tipos de demandas solicitadas"}
                  dataValues={
                    [
                      demands.pendingDemands.length,
                      demands.inProgressDemands.length,
                      demands.standbyDemands.length,
                      demands.waitingApprovalDemands.length,
                      demands.inEstimatingDemands.length,
                      demands.draftDemands.length,
                      demands.demandSentDemands.length,
                      demands.inReviewDemands.length,
                      demands.doneDemands.length,
                      demands.canceledDemands.length,
                      demands.waitingEstimateApprovalDemands.length,
                      demands.waitingLawyerAcceptanceDemands.length
                    ]
                  }
                />
              </div>
            </Box>
          </Flex>
        <Typography style={{textTransform: 'uppercase'}} fontWeight="bold" m={0}>
          Demais demandas
        </Typography>
        <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
          {/*Demandas pendentes*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas pendentes',content:transformInList(demands.pendingDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#E9D3FB'}}
                  color="#E9D3FB"
                  counterColor="#350A7C"
                  textColor="#350A7C"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Pendentes</strong>
                    </Typography>
                  }
                  customIcon={""}
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.pendingDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas em progresso*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas em progresso',content:transformInList(demands.inProgressDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#D6DAFF'}}
                  color="#D6DAFF"
                  counterColor="#5A67DF"
                  textColor="#5A67DF"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Em Progresso</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.inProgressDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas em standby*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas em standby',content:transformInList(demands.standbyDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#FFF9D7'}}
                  color="#FFF9D7"
                  counterColor="#F4C51F"
                  textColor="#F4C51F"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Stand-by</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.standbyDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas aguarndando aprovação*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas aguardando Aprovação',content:transformInList(demands.waitingApprovalDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#DCDCDC'}}
                  color="#DCDCDC"
                  counterColor="#5A5A5A"
                  textColor="#5A5A5A"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Aguardando Aprovação</strong>
                    </Typography>
                  }
                  customIcon={""}
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.waitingApprovalDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
        </Flex>
        {/*Segunda linha*/}
        <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
          {/*Demandas em orçamento*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas em Orçamento',content:transformInList(demands.inEstimatingDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#FFF6D7'}}
                  color="#FFF6D7"
                  counterColor="#F0BA06"
                  textColor="#F0BA06"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Aguardando Orçamento</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.inEstimatingDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas pendentes de informação*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas pendentes de informação',content:transformInList(demands.draftDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#FFE7D6'}}
                  color="#FFE7D6"
                  counterColor="#FF6007"
                  textColor="#FF6007"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Pendentes de Informação</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.draftDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>     
          {/*Demandas enviadas*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas enviadas',content:transformInList(demands.demandSentDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#D3FBE5'}}
                  color="#D3FBE5"
                  counterColor="#3AC59B"
                  textColor="#3AC59B"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Demanda Enviada</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.demandSentDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas aguardando revisão*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas aguardando revisão',content:transformInList(demands.inReviewDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#FBEDD3'}}
                  color="#FBEDD3"
                  counterColor="#68480A"
                  textColor="#68480A"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Aguardando revisão</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.inReviewDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
        </Flex>
        {/*Terceira linha*/}
        <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
          {/*Demandas canceladas*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas canceladas',content:transformInList(demands.canceledDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#F8D0D0'}}
                  color="#F8D0D0"
                  counterColor="#C31E1E"
                  textColor="#C31E1E"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Canceladas</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.canceledDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>          
          {/*Demandas concluídas*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas concluídas',content:transformInList(demands.doneDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#D6FBD3'}}
                  color="#D6FBD3"
                  counterColor="#49A96A"
                  textColor="#49A96A"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Concluídas</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.doneDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas Aguardando Aprovação de Orçamento*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas Aprovação de Orçamento',content:transformInList(demands.waitingEstimateApprovalDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#FFD7F4'}}
                  color="#FFD7F4"
                  counterColor="#D4169F"
                  textColor="#D4169F"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Aguardando Aprovação de Orçamento</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.waitingEstimateApprovalDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
          {/*Demandas Aprovação do Advogado*/}
          <Box mb={2}>
            <FakeButton onClick={() => openModal({ title:'Demandas Aprovação do Advogado',content:transformInList(demands.waitingLawyerAcceptanceDemands)})}>
              <Box mr={4} ml={0} my={3} width={[1]}>
                <Card
                  style={{backgroundColor: '#FFD7F4'}}
                  color="#FFD7F4"
                  counterColor="#D4169F"
                  textColor="#D4169F"
                  title={
                    <Typography m={0} fontSize={2} textAlign="left">
                      <strong>Aguardando Aceite do Advogado</strong>
                    </Typography>
                  }
                  count={
                    <Typography m={0} fontSize={5}>
                      {
                        loading ? ( <Spinner color="primary" /> ):demands.waitingLawyerAcceptanceDemands.length
                      }
                    </Typography>
                  }
                />
              </Box>
            </FakeButton>
          </Box>
        </Flex>        
      </div>
      <div id="documents-statistics">
        <h2>Documentos</h2>
        <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
          <Box mb={2}>
            <Typography style={{textTransform: 'uppercase'}} fontWeight="bold" m={0}>
              Tipos de documentos
            </Typography>
            <div id="docs-list">
              <Box mr={4} ml={0} my={3} width={[1]}>
                <ul className="dash-list">
                {documents.folderNames.length > 0 && (
                  documents.folderNames.map((item, key) => {
                    return (
                      <li key={key}>
                        <a href={`/dashboard/documentos/${documents.folderIds[key]}/Contratos`} target="_blank" rel="noopener noreferrer">
                          {item} : {documents.numberOfDocsInFolders[key]}
                        </a>
                      </li>
                    )
                  })
                )}
                </ul>
              </Box>
            </div>
          </Box>
          <Box mb={2}>
            <Typography style={{textTransform: 'uppercase'}} fontWeight="bold" m={0}>
              Gráfico geral de documentos
            </Typography>
            <div id="dognohut">
              <DynamicChart
                type={"Dognhut"}
                labels={documents.folderNames}
                dataValues={documents.numberOfDocsInFolders}
              />
            </div>
          </Box>
        </Flex>
      </div>
      {userPlan === undefined? (
      <div id="wallets-statistics">
        <h2>Carteira</h2>
        <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
          <Box mb={2}>
            <div id="line">
              <DynamicChart
                type={"Line"}
                labels={transactions.date}
                dataValues={[transactions.valueReserve,transactions.valueCredit,transactions.valueDebit]}
              />
            </div>
          </Box>
          <Box mb={2}>
            <button className="refetchWallet" onClick={() => {
              changePeriod('last_30_days')
              refetchMyStatements()
            }}>30 DIAS</button>
            <button className="refetchWallet" onClick={() => {
              changePeriod('last_6_months')
              refetchMyStatements()
            }}>6 MESES</button>
            <button className="refetchWallet" onClick={() => {
              changePeriod('last_1_year')
              refetchMyStatements()
            }}>1 ANO</button>
            <Card
              style={{backgroundColor: '#009900'}}
              color="#009900"
              counterColor="#fff"
              textColor="#fff"
              title={
                <Typography m={0} fontSize={2} textAlign="left">
                  <strong>Total investido</strong>
                </Typography>
              }
              count={
                <Typography m={0} fontSize={5}>
                  {
                    loading ? ( <Spinner color="primary" /> ):`${transactions.totalExpended} BNZ`
                  }
                </Typography>
              }
            />
          </Box>
        </Flex>
        <Typography style={{textTransform: 'uppercase'}} fontWeight="bold" m={0}>
          Detalhes das transações
        </Typography>
        <Flex flexWrap="wrap" justifyContent="center"  width={1} mt={2} alignItems="space-between">
          <Box mb={1}>
            <ul className="dash-list">
              {transactions.description.length > 0 && (
                transactions.description.map((item, key) => {
                   return (
                    <li key={key}>
                      {item}
                    </li>
                  )
                })
              )}
            </ul>
          </Box>
        </Flex>
      </div>
        ):""}
    </div>
  )
}

export default ClientDemandsPanel
