import React from "react"
import { Box } from "@mui/material"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import {useMutation} from '@apollo/react-hooks'
import {clientsMutations} from "../../../../../graphql/mutations"

import './styles.css'
import { cardsContent } from "../cardsContent"

const PlansView = ({planSelected = '', onViewChange}) => {
  const history = useHistory()
  
  const content = planSelected ? cardsContent.find(card => card.plan === planSelected) : {}

  const goTo = (path) => {
    if(planSelected === 'freemium'){
      acceptClientPlan()
    }
    history.push(path)
  }

  const [acceptClientPlan] = useMutation(clientsMutations.ACCEPT_CLIENT_PLAN)

  return (
    <Box className="box welcome-view">
      <h2>Confirme o plano selecionado!</h2>
      <h3 className={`plan ${planSelected}`}>Plano {planSelected}</h3>
      <div className="plans-grid">
        {content?.cards && content?.cards.map((info, index) => (
          <div className={`plan-card ${planSelected}`} key={index}>
            <h6>{info?.title}</h6>
            <p>{info?.desc}</p>
            {info?.content}
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-end mt-3 position-relative">
        {
          planSelected !== 'freemium' &&
          <p className="absolute-p">*Plano mensal</p>
        }
        <div>
          <button className="btn btn-out-blue me-2" onClick={() => onViewChange('')}>Alterar Plano</button>
          <button className="btn btn-green" onClick={() => goTo(content?.link)}>Confirmar</button>
        </div>
      </div>
    </Box>  
  )
}

export default PlansView
