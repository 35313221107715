import gql from 'graphql-tag'

export const GET_DATAPRIVACY_CHECKOUT_FILES = gql`
  query getCheckDataPrivacyClientDocuments($clientId: String!) {
    getCheckDataPrivacyClientDocuments(clientId: $clientId) {
      data{
        _id
        type
      }
    }
  }
`

export const GET_DEMANDS = gql`
  query listDemands(
    $search: String
    $sort: String
    $order: String
    $offset: Int
    $page: Int
    $queryBy: String
    $query: String
    $queryFormat: String
  ) {
    listDemands(
      search: $search
      sort: $sort
      order: $order
      offset: $offset
      page: $page
      queryBy: $queryBy
      query: $query
      queryFormat: $queryFormat
    ) {
      data {
        _id
        code
        status
        clientId
        lawyer {
          _id
          displayAs
          professionalMobileNumber
          professionalEmail
        }
        admin {
          _id
          displayAs
          professionalMobileNumber
          professionalEmail
        }
        demandBids {
          _id
          comment
          price
          deadline
          lawyerId
          lawyerName
          isBNZAdquired
          createdAt
        }
        fullDescription
        belongsToSubaccountId
        price
        deadline
        finalDate
        isAuction
      }
      pagination {
        previous
        current
        next
        totalRecords
        pagesAmount
        sort
        order
      }
    }
  }
`

export const GET_DEMAND = gql`
  query demand($id: String!) {
    demand(id: $id) {
      _id
      serviceId
      subserviceId
      subserviceBicolunado
      subserviceExtraLanguage
      subserviceUrgentExtraBNZ
      code
      status
      price
      deadline
      initialDate
      finalDate
      fullDescription
      subserviceDescription
      isBicolumn
      isExtraLanguage
      isUrgent
      isAuction
      admin {
        _id
        displayAs
        professionalMobileNumber
        professionalEmail
      }
      lawyer {
        _id
        displayAs
        professionalMobileNumber
        professionalEmail
      }
      demandAnswers {
        _id
        questionId
        description
        answer
        createdAt
        documents {
          fileName
          fileURL
          name
          extension
        }
      }
      demandInteractionsHistory {
        _id
        comment
        interactionResponsibleName
        isLawyer
        createdAt
        status
        documents {
          fileName
          fileURL
          name
          extension
        }
      }
      demandBids {
        _id
        comment
        price
        deadline
        lawyerId
        lawyerName
        isBNZAdquired
        createdAt
      }
    }
  }
`

export const GET_DEMAND_EVALUATION = gql`
  query getDemandEvaluation($demandId: String!) {
    getDemandEvaluation(demandId: $demandId) {
      _id
      demandId
      evaluation
    }
  }
`

export const GET_SCHEDULED_ACTIVITIES = gql`
  query getScheduledActivities {
    getScheduledActivities {
      _id
      clientId
      lawyerId
      schedule_name
      schedule_activity_id
      schedule_date
      status
    }
  }
`

export const GET_OPEN_EVALUATION = gql`
  query getOpenEvaluation {
    getOpenEvaluation {
      demandId
      lawyerId
    }
  }
`

export const GET_DEMAND_REQUESTS = gql`
  query getRequest($id: String!) {
    getRequest(id: $id) {
      _id
      demandId
      lawyerId
      debitBNZ
      valueBNZ
      status
      createdAt
      updatedAt
      documents {
        _id
        requestId
        description
        status
        comment
        filesRequest {
          _id
          name
          fileURL
          extension
          mimeType
          size
          fileName
          createdAt
        }
        filesDelivery {
          _id
          name
          fileURL
          extension
          mimeType
          size
          fileName
          createdAt
        }
      }  
    }
  }
`

