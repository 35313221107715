import React, { useEffect } from 'react'
import './MyBnzResume.css'
import { Flex } from 'reflexbox'
import bnz_coin_icon from '../../images/coin.svg'
import { Link } from 'react-router-dom'
const MyBnzResume = (props) => {

  useEffect(()=>{

  },[])

  return (
    <Flex width={1} alignItems={'center'} id="my-bnz-resume" flexDirection="column"  justifyContent={'center'}>
        <Flex width={1} className={'bnz-resume'} justifyContent={'center'}>
          <Flex width={1/3}  justifyContent={'flex-start'}>
            <h2>Meu saldo BNZ</h2>
          </Flex>
          <Flex width={2/3} justifyContent={'flex-end'}>
            <div id="bnz-current-balance">
              <small>Saldo</small>
              <div id="bnz-current-balance-content">
                <img src={bnz_coin_icon} alt='Bnz coin icon' />
                <h4>{props.balance}</h4>
              </div>
            </div>
          </Flex>
        </Flex>

      <Flex width={1} alignItems={'center'}  className={'bnz-resume'} justifyContent={'center'}>
          <Flex width={1/3} alignItems={'center'} justifyContent={'flex-start'}>
            <div id='bnz-reserved-balance'>
            <small>Reservados</small>
              <div id='bnz-reserved-balance-content'>
                <h4>{props.reserved}</h4>
                <img src={bnz_coin_icon} alt='Bnz coin icon' />
              </div>
            </div>
          </Flex>
        <Flex width={2/3} alignItems={'center'} justifyContent={'flex-end'}>
          <Link to={'/dashboard/carteira'}>
            <button>Compre BNZ's</button>
          </Link>
          </Flex>
      </Flex>
    </Flex>
  );
}

export default MyBnzResume;
