import React, { useState, useCallback } from 'react'
import Box from '@mui/material/Box'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../graphql/mutations'
import { demandsQueries, walletQueries } from '../../graphql/queries'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import "./Proposals.css";

import AcceptModal from '../../components/Modal/Proposals/AcceptModal'
import RefuseModal from '../../components/Modal/Proposals/RefuseModal'
import SuccessModal from '../../components/Modal/Proposals/SuccessModal'
import DemandDetails from '../../components/Modal/Proposals/DemandDetails'
import ChatDemand from '../../components/ChatDemand'

import {
  CustomTableProposals,
  Hints,
  CustomSearchBar
} from '../../components'


const Proposals = (props) => {
  const { showMessage } = useAlertStack()
  const [currentPage, setCurrentPage] = useState(1)
  const [sort] = useState('code')
  const [order] = useState('desc')
  const [openAcceptModal, setOpenAcceptModal] = useState(false)
  const [openRefuseModal, setOpenRefuseModal] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const [openDemandDetailsModal, setOpenDemandDetailsModal] = useState(false)
  const [openChatModal, setOpenChatModal] = useState(false);
  const [demandIdProposal, setDemandIdProposal] = useState('');
  const [demandLawyerChatModal, setDemandLawyerChatModal] = useState('');

  const [demandBidId, setDemandBidId] = useState('');
  const [acceptDemandBidId, setAcceptDemandBidId] = useState('');
  const [refuseDemandBidId, setRefuseDemandBidId] = useState('');

  const [modalType, setModalType] = useState('')
  const [search, setSearch] = useState('')
  const [offset] = useState(10)

  const { data: dataClientSubscription } = useQuery(walletQueries.GET_MY_SUBSCRIPTION, {
    variables: {
      subscriptionType: 'juridico-pessoal'
    },
  })

  const { loading: demandsLoading, data: demandsData, refetch: refetchDemands } = useQuery(demandsQueries.GET_DEMANDS, {
    variables: {
      search: search,
      sort: sort,
      page: currentPage,
      order: order,
      offset: offset,
      queryBy: 'status'
    },
  })

  const [approveDemandBid, { loading: approveDemandBidLoading }] = useMutation(demandsMutations.APPROVE_DEMAND_BID, {
    variables: {
      bidId: acceptDemandBidId,
    },
    onCompleted() {
      setOpenDemandDetailsModal(false)
      setOpenAcceptModal(false)
      handleOpenSuccessModal('bidAccepted')
      refetchDemands()
    },
    // refetchQueries: [
    //   {
    //     query: demandsQueries.GET_DEMAND,
    //     variables: { id },
    //   },
    //   {
    //     query: walletQueries.GET_MY_WALLET,
    //   },
    // ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError({ graphQLErrors }) {
      setOpenAcceptModal(false)
      showMessage({
        title: 'Erro ao aceitar a proposta',
        message: graphQLErrors[0]?.extensions?.response?.body?.message || 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [reproveDemandBid, { loading: reproveDemandBidLoading }] = useMutation(demandsMutations.REPROVE_DEMAND_BID, {
    variables: {
      bidId: refuseDemandBidId,
    },
    onCompleted() {
      setOpenDemandDetailsModal(false)
      setOpenRefuseModal(false)
      handleOpenSuccessModal('bidRefused')
      refetchDemands()
    },
    // refetchQueries: [
    //   {
    //     query: demandsQueries.GET_DEMAND,
    //     variables: { id },
    //   },
    // ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      setOpenRefuseModal(false)
      showMessage({
        title: 'Erro ao reprovar a proposta',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleChangePrev = () => {
    const prevPage = currentPage - 1
    if (prevPage > 0) {
      setCurrentPage(currentPage - 1)
    }
  }

  const handleChangeNext = () => {
    const nextPage = currentPage + 1
    if (nextPage <= demandsData?.listDemands?.pagination?.pagesAmount) {
      setCurrentPage(nextPage)
    }
  }

  const handleOpenDemandDetailsModal = useCallback((demandId, demandBid) => {
    setDemandBidId(demandBid)
    setDemandIdProposal(demandId)
    setOpenDemandDetailsModal(!openDemandDetailsModal)
  }, []) //eslint-disable-line

  const handleOpenAcceptModal = useCallback((id) => {
    setAcceptDemandBidId(id)
    setOpenDemandDetailsModal(false)
    setOpenAcceptModal(!openAcceptModal)
  }, []) //eslint-disable-line

  const handleOpenRefuseModal = useCallback((id) => {
    setRefuseDemandBidId(id)
    setOpenDemandDetailsModal(false)
    setOpenRefuseModal(!openRefuseModal)
  }, []) //eslint-disable-line

  const handleOpenSuccessModal = useCallback((type) => {
    setModalType(type)
    setOpenSuccessModal(!openSuccessModal)
  }, []) //eslint-disable-line

  const handleChatModal = useCallback(({demandId, demandLawyer}) => {
    setDemandIdProposal(demandId)
    setDemandLawyerChatModal(demandLawyer)
    setOpenChatModal(!openChatModal)
  }, []) //eslint-disable-line

  const searchDemands = useCallback((event) => {
    setSearch(event.target.value)
  }, [])

  const handleAcceptDemandBid = useCallback(() => {
    approveDemandBid()
  }, []) //eslint-disable-line

  const handleRefuseDemandBid = useCallback(() => {
    reproveDemandBid()
  }, []) //eslint-disable-line


  return (
    <Box className="proposals-wrapper">
      <Box className="proposals-header-wrapper">
        <Hints
          hintInfo={'Solicite um serviço de Orçamento com um dos nossos Advogados B.onuz. Receba propostas e defina a melhor opção para você!'}
        />
      </Box>
      <Box className="proposals-header-inner-wrapper">
        <Box className="proposals-inner-wrapper-header-section">
          <Box className="proposals-inner-wrapper-search">
            <CustomSearchBar
              id="search-bar"
              name="search-bar"
              placeholder="Buscar por"
              onChange={searchDemands}
            />
          </Box>
        </Box>
        <CustomTableProposals
          loading={demandsLoading}
          data={demandsData?.listDemands?.data}
          isJp={dataClientSubscription !== undefined}
          headers={[
            {title: 'Número'},
            {title: 'Serviço'},
            {title: 'Prazo'},
            {title: 'Valor'},
            {title: 'Ações'}
          ]}
          changePage={(item) => setCurrentPage(item)}
          prevPage={() => handleChangePrev()}
          nextPage={() => handleChangeNext()}
          totalPages={demandsData?.listDemands?.pagination?.pagesAmount}
          page={currentPage}
          refuseModal={handleOpenRefuseModal}
          acceptModal={handleOpenAcceptModal}
          demandModal={handleOpenDemandDetailsModal}
        />
      </Box>
      <DemandDetails 
        openModal={openDemandDetailsModal}
        handleClose={() => setOpenDemandDetailsModal(false)}
        loading={demandsLoading}
        demandId={demandIdProposal}
        demandBidId={demandBidId}
        handleOpenChat={handleChatModal}
        handleOpenAcceptModal={handleOpenAcceptModal}
        handleOpenRefuseModal={handleOpenRefuseModal}
        isJp={dataClientSubscription !== undefined}
      />
      <AcceptModal 
        openModal={openAcceptModal}
        handleClose={() => setOpenAcceptModal(false)}
        action={handleAcceptDemandBid}
        loading={approveDemandBidLoading}
      />
      <RefuseModal 
        openModal={openRefuseModal}
        handleClose={() => setOpenRefuseModal(false)}
        action={handleRefuseDemandBid}
        loading={reproveDemandBidLoading}
      />
      <SuccessModal 
        openModal={openSuccessModal}
        handleClose={() => setOpenSuccessModal(false)}
        modalType={modalType}
      />
      <ChatDemand
        handleClose={() => setOpenChatModal(false)}
        openModal={openChatModal}
        demandId={demandIdProposal}
        demandLawyer={demandLawyerChatModal}
      />
    </Box>
  )
}

export default Proposals
