import React, { useEffect } from 'react'
import { Swiper,Pagination } from 'swiper'
import './NoticeCarrousel.css'
import { Link } from 'react-router-dom'
const NoticeCarrousel = (props) => {

  useEffect(()=>{
    new Swiper(".mySwiper", {
      modules:[Pagination],
      pagination: {
        el: ".swiper-pagination",
      },
    });
  },[])

  return (
     <div className="swiper mySwiper" id="notice-carrousel">
       <div className="swiper-wrapper">
         <div className="swiper-slide">
           <p>Consulte e solicite um serviço agora mesmo</p>
           <Link to={'/dashboard/serviços/solicitar'}>
             <button>Solicitar serviço</button>
           </Link>
         </div>
       </div>
       <div className="swiper-pagination"/>
     </div>
  );
}

export default NoticeCarrousel;
