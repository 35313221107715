import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Tabs, Tab, Button, ButtonGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Input, Select } from 'ui-components'
import Dropzone from '../../components/Dropzone'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { documentsMutations } from '../../graphql/mutations'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import CurrencyInput from 'react-currency-masked-input'
import { documentsQueries } from '../../graphql/queries'
import SwitchInput from '../../components/SwitchInput'
import FormLoading from '../../components/HalfLoading'
import DatePicker, { registerLocale } from 'react-datepicker';
import { ptBR } from 'date-fns/locale';
import EmailManager from './EmailManager'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { FaDownload } from 'react-icons/fa'

registerLocale('pt-BR', ptBR);

const Document = (props) => {
  // Component Variables
  const [inicialContractPartEmail, setInicialContractPartEmail] = useState([''])
  const [formValues, setFormValues] = useState({
    contractName: "",
    contractMembers: "",
    categoryName: "",
    contract: [],
    contractFilename: "",
    contractDueDate: "",
    notificationAlertPeriod: 0,
    contractValue: 0,
    contractValueIncreaseType: "mensal",
    contractPenalty: 0,
    observation: "",
    contractObject: "",
    contractReviewRequest: false,
    contractContactName: "",
    contractContactTelephone: "",
    contractContactEmail: "",
    contractPartEmail: [],
    savedAlerts: false,
    selectedAlert: "",
  })
  const { id } = props.match.params

  const [errors, setErrors] = useState({});

  const SUPPORTED_FORMATS = [
    'application/pdf',
    'image/png',
    'image/jpeg',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  ];

  const { data:contractData, loading: contractLoading } = useQuery(documentsQueries.GET_CONTRACT,{
    variables: {
      id:id
    },
    onCompleted(){
      const data = contractData?.getDocument;
      data.contractPartEmail = data?.contractPartEmail?.toString().split(';');
      data.contractDueDate = new Date(data.contractDueDate);
      data.savedAlerts = data.alertId ? true : false;
      data.contractFilename = data?.contractFile?.name;
      setFormValues(data);
      setInicialContractPartEmail([...data.contractPartEmail]);
    }
  })

  const validationSchema = yup.object().shape({
    contractName: yup.string()
      .required('Contrato é obrigatório')
      .min(3, 'Contrato deve ter pelo menos 3 caracteres'),
      contractMembers: yup.string()
      .required('Parte do Contrato é obrigatório')
      .min(3, 'Parte do Contrato deve ter pelo menos 3 caracteres'),
      contractDueDate: yup.string()
      .required('O vencimento é obrigatório'),
      contractContactName: yup.string()
        .required('Nome é obrigatório')
        .min(3, 'Nome deve conter pelo menos 3 caracteres'),
      contractContactTelephone:yup.string()
      .matches(
        /^\(\d{2}\)\d{4,5}-\d{4}$/,
        'Telefone inválido. Deve estar no formato (00) 0000-0000 ou (xx) 00000-0000'
      )
      .required('Telefone é obrigatório'),
      contractContactEmail: yup.string().required('O e-mail é obrigatório').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Informe um e-mail válido'),
      categoryName: yup.string().required('Selecione uma categoria').min(1, 'Selecione uma categoria'),
      contract: yup.mixed().when('$id', {
        is: (val) => !val,
        then: () => yup.mixed().required('O arquivo é obrigatório')
        .test(
          'fileFormat',
          'Formato de arquivo não suportado',
          (value) => value && SUPPORTED_FORMATS.includes(value.mimeType)
        ),
        otherwise: () => yup.mixed().notRequired()
      }),
      contractPartEmail: yup.array().when('$savedAlerts', {
        is: (val) => !val,
        then: () => yup.array().of(yup.string().required('O e-mail é obrigatório').matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Informe um e-mail válido'))
        .min(1, 'Deve informar menos um e-mail') 
        .required("Informe um e-mail"),
        otherwise: () =>  yup.array().notRequired()
      })
  });

  useEffect(() => {
    console.log('formValues', formValues)
  }, [formValues])

  const { showMessage } = useAlertStack()

  const cleanedTexts = (text) => text.replace(/\[\d+\]/g, '');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formValues, { abortEarly: false, context: { id, savedAlerts: formValues.savedAlerts} });
      setErrors({});
      createContract();
    } catch (validationErrors) {
      const formErrors = {};
      if (validationErrors.inner) {
        validationErrors.inner.forEach((error) => {
          formErrors[cleanedTexts(error.path)] = error.message;
        });
        toast.error('Os dados fornecidos são inválidos.', {
          theme: 'dark'
        })
      }
      setErrors(formErrors);
    }
  };

  const validateField = async (fieldName, value) => {
    try {
      await validationSchema.validateAt(fieldName, { [fieldName]: value }, { context: { id, savedAlerts: formValues.savedAlerts}});
      return { isValid: true, error: null };
    } catch (validationErrors) {
      return { isValid: false, error: validationErrors.message };
    }
  };

  const handleChange = async (name, value = null) => {
    let values = formValues;
    if (typeof name === 'object') {
      const objEntries = Object.keys(name);
      console.log('objEntries', objEntries)
        for (const obj of objEntries) {
          values[obj] = name[obj]
        }
    } else {
      values = {
        ...formValues,
        [name]: value
      }
    }
    setFormValues(values);

    const { isValid, error } = await validateField(name, value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: isValid ? '' : error,
    }));
  };

  const [createContract, {loading:createContractLoading}] = useMutation(id ? documentsMutations.UPDATE_CONTRACT : documentsMutations.CREATE_CONTRACT, {
    variables: {
      ...formValues,
      alertId: formValues.selectedAlert,
      contractPartEmail: formValues.contractPartEmail.join(';'),
      id: formValues?._id
    },
    onCompleted() {
      toast.success(id ? 'Contrato alterado com sucesso' : 'Contrato criado com sucesso.', {
        theme: 'dark'
      })
      if (!formValues?.id) {
        setInterval(()=>{
          window.location.href = '/dashboard/gestao-contratual'
        },1500)
      }
    },
    onError({ graphQLErrors}){
      const errors = JSON.parse(graphQLErrors[0].extensions.response.body.message);
      showErrors(errors)
    }
  })

  function showErrors(errors){
    errors.length > 0 && errors.forEach((error)=>{
      showMessage({
        title: 'Erro ao criar o contrato',
        message: error,
        color: 'danger',
        position: 'bottom-left',
        time:6000
      })
    })
  }

  function setFilesHandler(file){

    let actualFilesList = [];

    actualFilesList.push(file);

    let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
      attachment: '',
      name,
      randomFileName,
      mimeType,
      extension,
      size,
    }))
    handleChange({contract: actualFilesListFiltered[0], contractFilename: actualFilesListFiltered[0].name});
  }

  const { loading: alertsLoading, data: alertsData,
    refetch: refetchAlerts //eslint-disable-line
  } = useQuery(documentsQueries.GET_ALERTS, {

    variables: {
      queryBy: 'status'
    },
  })

  const handleContractValueChange = (event, maskedValue, floatValue) => {
    const normalizedValue = maskedValue
      .replace(",", ".");

      handleChange('contractValue', parseFloat(normalizedValue));
  };

  const handleContractPenaltyValueChange = (event, maskedValue, floatValue) => {
    const normalizedValue = maskedValue
      .replace(",", ".");

    handleChange('ContractPenaltyValue', parseFloat(normalizedValue));
  };

  return (
    <div className='pt-3 cm' style={{position:"relative"}}>
      {alertsLoading || createContractLoading || contractLoading
        ?
        <div className='full-screen-loading'>
          <FormLoading/>
        </div>
        : ''
      }
        <div className='doc p-4'>
          <h3>{id ? 'Alterar Documento' : 'Novo Documento'}</h3>
          <p>Seja notificado do prazo de vencimento do seu contrato. Nos forneça as seguintes informações:</p>
          <p><strong>Informações do contrato</strong></p>
          <Form>
            <Tabs defaultActiveKey="document" className="mb-3">
              <Tab eventKey="document" title="Dados do Contrato">
                <Row>
                  <Col sm={5}>
                    <Form.Group>
                      <Form.Label className="required">Contrato</Form.Label>
                      <Input
                        type="text"
                        placeholder="Contrato de prestação de serviços..." 
                        value={formValues.contractName} 
                        onChange={(e)=>handleChange('contractName', e.target.value)} 
                        className={`form-control ${errors.contractName ? 'is-invalid' : ''}`}
                      />
                      {errors.contractName && <div className="invalid-feedback">{errors.contractName}</div>}
                    </Form.Group>
                  </Col>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label className="required">Parte do Contrato</Form.Label>
                      <Input
                        type="text" 
                        placeholder="Ex: José Miguel Xavier" 
                        value={formValues.contractMembers} 
                        onChange={(e)=>handleChange('contractMembers', e.target.value)}
                        className={`form-control ${errors.contractMembers ? 'is-invalid' : ''}`}
                      />
                      {errors.contractMembers && <div className="invalid-feedback">{errors.contractMembers}</div>}
                    </Form.Group>
                  </Col>
                  <Col xl={2}>
                    <Form.Group>
                      <div className="input-group">
                        <Form.Label className="required">Vencimento</Form.Label>
                        <DatePicker
                          selected={formValues.contractDueDate}
                          onChange={(date) => handleChange('contractDueDate', date)}
                          className={`form-control ${errors.contractDueDate ? 'is-invalid' : ''}`}
                          dateFormat="dd/MM/yyyy"
                          locale="pt-BR"
                          placeholderText="DD/MM/AAAA"
                        />
                      </div>
                      {errors.contractDueDate && <div className="help-block help-block-red">{errors.contractDueDate}</div>}
                    </Form.Group>
                  </Col>
                  <Col xl={2}>
                    <Form.Group>
                      <Form.Label className="required">Categoria</Form.Label>
                      <Select
                        onChange={(e)=>handleChange('categoryName', e.target.value)}
                        className={`form-control ${errors.categoryName ? 'is-invalid' : ''}`}
                      >
                        <option disabled selected={formValues.categoryName === ""}>Selecione uma categoria</option>
                        <option selected={formValues.categoryName === 'clientes'} value='clientes'>Clientes</option>
                        <option selected={formValues.categoryName === 'prestador-de-servico-fornecedor'} value='prestador-de-servico-fornecedor'>Prestador de serviço/Fornecedores</option>
                        <option selected={formValues.categoryName === 'parcerias'} value='parcerias'>Parcerias</option>
                        <option selected={formValues.categoryName === 'funcionario-clt-pj'} value='funcionario-clt-pj'>Funcionário CLT/PJ</option>
                        <option selected={formValues.categoryName === 'investimento'} value='investimento'>Investimento</option>
                      </Select>
                      {errors.categoryName && <div className="invalid-feedback">{errors.categoryName}</div>}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm='6'>
                    <Form.Group>
                      <Form.Label className="required">{id ? 'Arquivo selecionado' : 'Anexar arquivo'}</Form.Label>
                      <ButtonGroup className='form-control'>
                        <Button disabled={id} type="button" style={{width: `${id ? '90%': '100%'}`}}
                          onClick={(e)=>{
                            e.preventDefault();
                            let fileRefuseDemand = document.getElementById('file-demand');
                            fileRefuseDemand.click();
                          }
                          }
                        >
                          { formValues.contractFilename !== ""? formValues.contractFilename : "Enviar arquivo" }
                        </Button>
                        {id && (
                          <Button variant="dark" type='button'  onClick={()=>window.open(formValues?.contractFile?.fileURL,'_blank')}>
                            <FaDownload />
                          </Button>
                        )}
                      </ButtonGroup>
                      {!id && (<span>Formatos permitidos: .pdf, .png, .jpeg, .jpg, .xls, .xlsx, .doc, .docx, .ppt, .pptx</span>)}
                      {errors.contract && <div className="help-block help-block-red">{errors.contract}</div>}
                      <Dropzone onLoad={()=>{}} onComplete={(file)=>setFilesHandler(file)}/>
                    </Form.Group>
                  </Col>
                  <Col xl='2'>
                    <Form.Label>Inclusão do valor</Form.Label>
                    <div className='d-flex'>
                      <Form.Check
                        type='radio'
                        label={`Mensal`}
                        className="me-2"
                        name={"radio-form"}
                        value='mensal'
                        onChange={(e)=>handleChange('contractValueIncreaseType', 'mensal')}
                        checked={formValues.contractValueIncreaseType === 'mensal'}
                      />

                      <Form.Check
                        type='radio'
                        label={`Anual`}
                        name={"radio-form"}
                        onChange={(e)=>handleChange('contractValueIncreaseType', 'anual')}
                        checked={formValues.contractValueIncreaseType === 'anual'}
                      />
                    </div>
                  </Col>
                  <Col xl='2'>
                    <Form.Group>
                      <Form.Label>Valor</Form.Label>
                      <CurrencyInput
                        className="input"
                        precision={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix="R$ "
                        onChange={handleContractValueChange}
                        value={formValues.contractValue}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl='2'>
                    <Form.Group>
                      <Form.Label>Multa para recisão</Form.Label>
                      <CurrencyInput
                        className="input"
                        precision={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix="R$"
                        onChange={handleContractPenaltyValueChange}
                        value={formValues.contractPenalty}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>Observação</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Observações"
                      style={{ height: '100px' }}
                      onChange={(e)=>handleChange('observation', e.target.value)}
                      value={formValues.observation}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Objeto do contrato</Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Escopo do contrato"
                      style={{ height: '100px' }}
                      onChange={(e)=>handleChange('contractObject', e.target.value)}
                      value={formValues.contractObject}
                    />
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <Form.Check
                      type='checkbox'
                      id={`default-check`}
                      label={`Quero a revisão do contrato`}
                      onChange={(e)=>handleChange('contractReviewRequest', e.target.checked)}
                      checked={formValues.contractReviewRequest}
                    />
                  </Col>
                </Row>
              </Tab>
              <Tab eventKey="alerts" title="Alertas">
                <Row>
                  <Col xl={2}>
                    <Form.Group>
                      <SwitchInput
                        id="saveCard"
                        name={'saveCard'}
                        onChange={()=>handleChange('savedAlerts', !formValues.savedAlerts)}
                        checked={formValues.savedAlerts}
                        sx={{marginLeft: '10px'}}
                        selected={formValues.savedAlerts}
                      />
                      <Form.Label>Usar alertas salvos</Form.Label>
                    </Form.Group>
                  </Col>
                  <Col md="4">
                  {
                    //alertId
                    formValues.savedAlerts? (
                      <Form.Group>
                        <Form.Label className='required'>Alerta</Form.Label>
                        <Select onChange={(e)=>handleChange('selectedAlert', e.target.value)}>
                          <option disabled selected>Selecione um alerta</option>
                          {
                            alertsData?.getAlerts?.data.length > 0 ?
                              (alertsData?.getAlerts?.data.map((alert,counter) => (
                                (
                                  <option key={counter} value={alert._id} selected={alert._id === formValues.alertId || alert.isDefault}>{alert.alertName}</option>
                                ))))
                              : <option disabled>Não há alertas cadastrados</option>
                          }
                        </Select>
                      </Form.Group>
                    ) : (
                      <Form.Group>
                        <Form.Label className='required'>Receba notificação em</Form.Label>
                        <Select onChange={(e)=>handleChange('notificationAlertPeriod', parseInt(e.target.value))} >
                          <option selected={formValues.notificationAlertPeriod === 15} value='15'>15 dias</option>
                          <option selected={formValues.notificationAlertPeriod === 30} value='30'>30 dias</option>
                          <option selected={formValues.notificationAlertPeriod === 60} value='60'>60 dias</option>
                          <option selected={formValues.notificationAlertPeriod === 90} value='90'>90 dias</option>
                          <option selected={formValues.notificationAlertPeriod === 120} value='120'>120 dias</option>
                        </Select>
                      </Form.Group>
                    )
                  }
                  </Col>
                </Row>
                {!formValues.savedAlerts && (
                  <Row>
                    <Col xs="12">
                      <hr />
                    </Col>
                    <Col xs="12">
                      <h3>Destinatários</h3>
                      <EmailManager onEmailsChange={(value) => handleChange('contractPartEmail', value)} initialEmails={inicialContractPartEmail} />
                      {errors.contractPartEmail && <div className="help-block help-block-red">{errors.contractPartEmail}</div>}
                    </Col>
                  </Row>
                )}
              </Tab>
              <Tab eventKey="contact" title="Contato">
                <Row>
                  <Col>
                    <Form.Label className='required'>Nome</Form.Label>
                    <Input
                      type="text"
                      placeholder="Nome"
                      onChange={(e)=>handleChange('contractContactName', e.target.value)}
                      className={`form-control ${errors.contractContactName ? 'is-invalid' : ''}`}
                      value={formValues.contractContactName}
                    />
                    {errors.contractContactName && <div className="invalid-feedback">{errors.contractContactName}</div>}
                  </Col>
                </Row>
                <Row className='mt-2'>
                  <Col>
                    <Form.Label className='required'>Telefone</Form.Label>
                    <Input
                      type="text"
                      placeholder="(00) 0000-0000"
                      onChange={(e)=>handleChange('contractContactTelephone', e.target.value)}
                      className={`form-control ${errors.contractContactTelephone ? 'is-invalid' : ''}`}
                      value={formValues.contractContactTelephone}
                    />
                    {errors.contractContactTelephone && <div className="invalid-feedback">{errors.contractContactTelephone}</div>}
                  </Col>
                  <Col>
                    <Form.Label className='required'>E-mail</Form.Label>
                    <Input
                      type="email"
                      placeholder="Email da outra parte"
                      onChange={(e)=>handleChange('contractContactEmail', e.target.value)}
                      className={`form-control ${errors.contractContactEmail ? 'is-invalid' : ''}`}
                      value={formValues.contractContactEmail}
                    />
                    {errors.contractContactEmail && <div className="invalid-feedback">{errors.contractContactEmail}</div>}
                  </Col>
                </Row>
              </Tab>
            </Tabs>
            <Row className='mt-2'>
              <Col className='d-flex justify-content-end'>
                <div className='me-2'>
                  <Link to="/dashboard/gestao-contratual"><button className='btn btn-cancel'>Voltar</button></Link>
                </div>
                <div>
                  <button className='btn btn-concluid' onClick={handleSubmit}>Confirmar</button>
                </div>
              </Col>
            </Row>
          </Form>
        </div>

    </div>
  )
}

export default Document
