import React from "react"

import img1 from '../../../../assets/icons/w2/welcome/contract_review.svg'
import img2 from '../../../../assets/icons/w2/welcome/contract_elaboration.svg'
import img3 from '../../../../assets/icons/w2/welcome/brand_registration.svg'
import img4 from '../../../../assets/icons/w2/welcome/software_registration.svg'
import img5 from '../../../../assets/icons/w2/welcome/business_start.svg'
import img6 from '../../../../assets/icons/w2/welcome/partner_deals.svg'
import img7 from '../../../../assets/icons/w2/welcome/terms_of_use.svg'
import img8 from '../../../../assets/icons/w2/welcome/privacy_politics.svg'
import img9 from '../../../../assets/icons/w2/welcome/contract.svg'

const servicesListEssencial = [
  {
    image:img1,
    title:"ELABORAÇÃO DE CONTRATO"
  },
  {
    image:img2,
    title:"REVISÃO DE CONTRATO"
  },
  {
    image:img3,
    title:"ELABORAÇÃO DE ATAS"
  },
  {
    image:img4,
    title:"REGISTRO DE MARCAS"
  },
  {
    image:img5,
    title:"REGISTRO DE SOFTWARE"
  },
  {
    image:img6,
    title:"ABERTURA DE EMPRESA"
  },
  {
    image:img7,
    title:"ACORDOS DE SOCIOS"
  },
  {
    image:img8,
    title:"TERMOS DE USO"
  },
  {
    image:img9,
    title:"POLÍTICA DE PRIVACIDADE"
  }
]

export const cardsContent = [
  {
    plan: 'freemium',
    link:'/dashboard/carteira',
    cards: [
      {
        title: 'BNZ a partir de R$2,00',
        desc: 'Compras avulsas será aplicado uma taxa'
      },
      {
        title: 'Transparência a valores, prazos e possibilidades de solicitação de orçamento, urgência e traduções'
      },
      {
        title: 'Gestão contratual limitada a 10 contratos para o cliente.'
      },
      {
        title: 'Pagamento utilizando os pacotes de créditos, sem expiração'
      },
      {
        title: 'Diversos serviços para você:',
        content: <ul>
          <li><strong>Registro de marca</strong> - 500 BNZs;</li>
          <li><strong>Contrato de vesting</strong> - elaboração 550BNZs | revisão 500BNZs;</li>
          <li><strong>Contrato com cliente</strong> - elaboração 500BNZs | revisão 300BNZs;</li>
          <li><strong>Contrato com cliente</strong> - elaboração 500BNZs | revisão 300BNZs;</li>
          <li><strong>abertura de empresa</strong> - 550 BNZs;</li>
          <li><strong>consultoria com advogado especialista</strong></li>
          <li><strong>mútuo conversível</strong> - 750BNZs;</li>
        </ul>
      },
    ]
  },
  {
    plan: 'essencial',
    link:'/dashboard/planos/pagamento?selectedPlan=plano-essencial',
    cards: [
      {
        title: 'Call com o advogado',
        desc: 'Fale com o seu advogado quando precisar. Por 50 BNZs cada call.'
      },
      {
        title: '10% OFF na Gestão Contratual',
        desc: 'ANEXE CONTRATOS ANTERIORES JÁ assinados e AO FIM DO prazo NÓS TE SINALIZAREMOS DO VENCIMENTO'
      },
      {
        title: '10% OFF na compra de BNZs ',
        desc: 'Tenha desconto de 10% na compra de nossa moeda (BNZs) para usar em serviços dentro da nossa plataforma, quando necessário!'
      },
      {
        title: '1.350 BNZs para uso (mensal)',
        desc: '',
        content: <div className="icons">
          {servicesListEssencial.map((item)=> {
            return (
              <div className="icon">
                <img src={item.image} alt=''/>
                <p><span>{item.title}</span></p>
              </div>
            )
          })}
        </div>
      },
    ]
  },
  {
    plan: 'premium',
    link:'/dashboard/planos/pagamento?selectedPlan=plano-premium',
    cards: [
      {
        title: '15 Calls/mês com o advogado',
        desc: 'Fale com o seu advogado sempre que precisar.'
      },
      {
        title: 'Legal account manager',
        desc: 'Conte com advogado e conheça as necessidades da sua operação.'
      },
      {
        title: '20% OFF na Gestão Contratual',
        desc: 'Anexe seus contratos e nós avisaremos ao fim do vencimento ou renovação.'
      },
      {
        title: '20% OFF na compra de BNZs',
        desc: 'Tenha desconto de 20% na compra de nossa moeda (BNZs) para usar em serviços dentro da nossa plataforma, quando necessário!!',
      },
      {
        title: 'Cobrança dinâmica com tabela PREMIUM',
        content: <div>
          <div className="row space-between ">
            <div className="col">Revisão</div>
            <div className="col">
              <strong>R$100,00</strong>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">Elaboração de Contrato</div>
            <div className="col">
              <strong>R$250,00</strong>
              </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">Demais serviços</div>
            <div className="col">
              <strong>20% OFF</strong>
              </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">
              Urgência (entregas até 48 horas)
            </div>
            <div className="col">
              <p>Valoração em <br/>
                <strong>50% do valor</strong>
              </p>
            </div>
          </div>
          <br/>
          <div className="row">
            <div className="col">Outro idioma (Ing/Esp)</div>
            <div className="col">
              <p>
                Valoração em <br/>
                <strong>
                  50% do valor
                </strong>
              </p>
            </div>
          </div>
          
        </div>
      },

    ]
  }
]
