import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button';
import { useHistory } from 'react-router-dom'
import { useQuery } from '@apollo/react-hooks'
import { clientsQueries, partnersQueries } from '../../graphql/queries'
import "./Partners.css";

import { 
  Loading, 
  AlertsVariants
} from '../../components'

const Partners = (props) => {
  const history = useHistory()
  
  const [partners, setPartners] = useState('')

  const { data: dataStatus, loading: loadingStatus } = useQuery(clientsQueries.GET_STATUS)
  const { data: dataPartners, loading: loadingPartners } = useQuery(partnersQueries.GET_PARTNERS)


  useEffect(() => {
    if (dataStatus && dataStatus?.me?.status === 'incomplete') {
      history.push('/completar-cadastro')
    }
  }, [dataStatus]) // eslint-disable-line

  useEffect(() => {
    if (dataPartners) {
      setPartners(dataPartners.getPartners)
    }
  }, [dataPartners]) // eslint-disable-line


  if (loadingStatus || loadingPartners) return <Loading />

  if (dataStatus?.me?.status !== 'active' && dataStatus?.me?.status !== 'pending_articles_of_association') {
    return <AlertsVariants status={dataStatus?.me?.status} />
  }


  return (
    <Box className="partners-wrapper">
      <Box className="partners-header-wrapper">
        <Box className="partners-header-left-container">
          <h2>Bem-vindo ao Clube de Parceiros B.onuz!</h2>
          <span>Você pode consultar os nossos<br/>parceiros e obter descontos.</span>
        </Box>
        <Box className="partners-header-right-container" />
      </Box>
      <Box className="partners-middle-wrapper">
        {(partners || []).map((item, index) => (
          item.showPartner && (
            <Box className="partners-middle-partner-container">
              <Box className="partners-middle-partner-container-header">
                <h4>
                  {item.partnerName}
                </h4>
              </Box>
              <span>
                {item.benefits}
              </span>
              <p>
                <b>{item.textHighlighted}</b>
              </p>
              <p>
                {item.description}
              </p>
              <Button 
                disabled={!item.partnerLink}
                onClick={() => window.open(item.partnerLink, "_blank")}
                className="partners-link-button"
              >
                Ver mais
              </Button>
            </Box>
          )
        ))}
      </Box>
    </Box>
  )
}

export default Partners
