import React, { useState, useEffect, useRef } from "react";
import { useQuery, useMutation } from '@apollo/react-hooks'
import { chatMutations } from '../../graphql/mutations'
import { chatQueries } from '../../graphql/queries'
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import FormControl from '@mui/material/FormControl';
import Spinner from "ui-components/lib/components/Spinner";
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { formatISOTimestampToDate } from "../../utils";
import { ReactComponent as SendMessageIcon } from "../../images/icons/sendMessageIcon.svg";
import { ReactComponent as AttachIcon } from "../../images/icons/clipes.svg";
import { ReactComponent as ChatBg } from "../../assets/img/DemandsV2/chat-bg.svg";
import "./ChatDemandFixed.css";
import {Col, Row} from "react-bootstrap";
import Dropzone from "../Dropzone";

const ChatDemandFixed = (props) =>{
  const { showMessage } = useAlertStack()
  const [message, setMessage] = useState("")
  const [chatMessagesData, setChatMessagesData] = useState([])
  const [files,setFiles] = useState([]);
  const [loadingFiles, setLoadingFiles] = useState(false)
  const refConversasion = useRef(null);

  useEffect(() => {
    if (refConversasion.current) {
      refConversasion.current.scrollTop = refConversasion.current.scrollHeight;
    }
  }, [chatMessagesData]);

  const {
    openModal,
    demandId,
    demandLawyer,
    subscription
  } = props;


  const { loading: chatLoading, data: chatData, refetch: chatRefetch } = useQuery(chatQueries.GET_CHAT_MESSAGES, {
    variables: {
      demandId: demandId,
      subscription: subscription
    },
  })


  const [sendChatMessage, { loading: sendChatMessageLoading }] = useMutation(chatMutations.SEND_CHAT_MESSAGES, {
    variables: {
      demandId: demandId ? demandId : null,
      lawyerId: demandLawyer ? demandLawyer._id : null,
      message: message,
      subscription: subscription,
      files:files
    },
    onCompleted(response) {
      setMessage("")
      setFiles([])
      chatRefetch()
    },
    onError(error) {
      showMessage({
        title: 'Erro ao enviar a mensagem',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleChangeMessage = (event) => {
    setMessage(event.target.value)
  }

  const handleSendMessage = () => {
    if(message){
      sendChatMessage()
    }
  }

  const handleSendMessageByKeyPress = (event) => {
    if(event.key === 'Enter' && message){
      sendChatMessage()
    }
  }

  useEffect(() => {
    if(chatData?.getChatMessages) {
      const chatMessages = chatData?.getChatMessages.map((item, index) => {
        let messageCreatedAtDate = formatISOTimestampToDate(item.createdAt).formattedDate
        let messageCreatedAtHour = formatISOTimestampToDate(item.createdAt).formattedHourMinute

        if(!item.createdAt) {
          messageCreatedAtDate = formatISOTimestampToDate(item.created_at, 'ISO').formattedDate
          messageCreatedAtHour = formatISOTimestampToDate(item.created_at, 'ISO').formattedHourMinute
        }

        return {
          _id: item._id,
          chatId: item.chatId,
          message: item.message,
          sender: item.sender,
          createdAtDate: messageCreatedAtDate,
          createdAtHour: messageCreatedAtHour,
          files:item.files
        }
      })

      setChatMessagesData(chatMessages)
    }
  }, [chatData]); // eslint-disable-line

  useEffect(() => {
    if(openModal){
      chatRefetch()
    }
  },[openModal]) // eslint-disable-line

  function setFilesHandler(file){
    setLoadingFiles(false)
    let actualFilesList = files;

    actualFilesList.push(file);
    let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
      attachment: '',
      name,
      randomFileName,
      mimeType,
      extension,
      size,
    }))
    setFiles(actualFilesListFiltered);
  }



  return (
      <div>
        <Box className="container-chat">
          <Box className="container-messages-chat">
            <Box className="container-messages-chat-reverse" ref={refConversasion}>
              {chatMessagesData.length > 0 ? (
                chatMessagesData.map((item, index) => (
                  <>
                    {item.createdAtDate && (
                      <Box className="message-createdAt">
                        {item.createdAtDate}
                      </Box>
                    )}
                    {item.sender === 'client' ? (
                      <Box className="message-client-container">
                        <Box className="message-client-attatchment">
                          {item.files && item.files.length > 0 ? item.files.map((item,counter) => {
                            return (<button key={counter} className={"message-attatch"} onClick={()=>window.open(item.fileURL,'_blank')} style={{marginRight:'1px', fontSize:'12px'}}><AttachIcon width={16} height={16} /> {item.name}</button>)
                          }):''}
                        </Box>
                        <Box className="message-client">
                          {item.message}
                        </Box>
                        {item.createdAtHour}
                      </Box>
                    ) : (
                      <Box className="message-lawyer-container">
                        <Box className="message-lawyer-attatchment">
                          {item.files && item.files.length > 0 ? item.files.map((item,counter) => {
                            return (<button key={counter} className={"message-attatch"} onClick={()=>window.open(item.fileURL,'_blank')} style={{marginRight:'1px'}}>{item.name}</button>)
                          }):''}
                        </Box>
                        <Box className="message-lawyer">
                          {item.message}
                        </Box>
                        {item.createdAtHour}
                      </Box>
                    )}
                  </>
                ))
              ) : (
                <div className="no-messages-yet">
                  <Row>
                    <h4><strong>Envie mensagens, anexos e midias</strong></h4>
                  </Row>
                  <Row>
                    <Box>
                      <Col>
                        <Row>
                          <ChatBg/>
                        </Row>
                      </Col>
                    </Box>
                  </Row>
                </div>
              )}
            </Box>
            <Row className={"message-chat-footer"}>
              <div>
                {files.length > 0 && files.map((file,counter)=>{
                  return (
                      <button key={counter} disabled className={"message-attatch"} onClick={()=>window.open(file.fileURL,'_blank')} style={{marginRight:'1px'}}>{file.name}</button>
                  )
                })}
              </div>
              <FormControl variant="outlined">
                <OutlinedInput
                  sx={
                    chatLoading ? {
                      background: '#FAFAFA',
                      borderRadius: "8px",
                      border: "1px solid",
                      borderColor: "#D3D2D7"
                    } : {
                      background: 'transparent',
                      borderRadius: "8px",
                      border: "1px solid",
                      borderColor: "#D3D2D7"
                    }}
                  id="outlined-adornment-message"
                  value={message}
                  onChange={handleChangeMessage}
                  onKeyPress={handleSendMessageByKeyPress}
                  endAdornment={
                    <InputAdornment position="end">
                      {chatLoading || sendChatMessageLoading || loadingFiles? (
                        <Spinner color="primary" />
                      ) : (
                          <>
                            <IconButton
                                onClick={(e)=>{
                                  e.preventDefault();
                                  let fileHandler = document.getElementById('file-demand');
                                  fileHandler.click();
                                }}
                            >
                              <AttachIcon />
                            </IconButton>
                            <IconButton
                              onClick={handleSendMessage}
                            >
                              <SendMessageIcon />
                            </IconButton>
                          </>
                      )}
                    </InputAdornment>
                  }
                  placeholder="Escreva uma mensagem"
                  inputProps={{
                    'aria-label': 'message',
                  }}
                />
              </FormControl>
            </Row>
          </Box>
        </Box>
        <Dropzone  onLoad={setLoadingFiles} onComplete={(file)=>setFilesHandler(file)}/>
      </div>
  );
}

export default ChatDemandFixed;
