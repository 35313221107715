import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Flex, Box } from 'reflexbox'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { clientsQueries } from '../../graphql/queries'
import { clientsMutations } from '../../graphql/mutations'
import Table from 'ui-components/lib/components/Table'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Button from 'ui-components/lib/components/Button'
import AccountUserModal from '../../components/Modal/AccountUserModal'
import { FormTitle } from '../../components'
import Breadcumb from 'ui-components/lib/components/BreadCrumb'
import { ReactComponent as TrashEmpty } from "../../images/icons/trash-empty.svg";
import { ReactComponent as EditIcon } from "../../images/icons/edit_icon.svg";

const MyTeam = () => {
  const history = useHistory()
  const { showMessage } = useAlertStack()
  const [openAddAccountUsers, setOpenAddAccountUsers] = useState(false)
  const [accountUsers, setAccountUsers] = useState('')
  const [accountUserId, setAccountUserId] = useState('')

  const { data: dataMyInfos } = useQuery(clientsQueries.GET_MY_INFOS)
  const { data: dataMyName } = useQuery(clientsQueries.GET_MY_NAME)
  const { data: dataAccountTypes } = useQuery(clientsQueries.GET_ACCOUNT_TYPES)
  const { data: dataAccountUsers, refetch: refetchAccountUsers } = useQuery(clientsQueries.GET_ACCOUNT_USERS)

  const [deleteAccountUser] = useMutation(clientsMutations.DELETE_ACCOUNT_USER, {
    onCompleted() {
      showMessage({
        title: 'Usuário excluído com sucesso',
        message: 'Seu cadastro foi atualizado.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      refetchAccountUsers()
    },
    onError() {
      showMessage({
        title: 'Cadastro não atualizado',
        message: 'Ocorreu um erro ao excluir o usuário. Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const handleDeleteAccountUser = useCallback((id) => {
    deleteAccountUser({ variables: { id } })
  },[deleteAccountUser])

  const handleManageAccountUser = (id) => {
    setOpenAddAccountUsers(true)
    setAccountUserId(id)
  }

  const handleCloseAccountUserModal = useCallback(() => {
    setOpenAddAccountUsers(false)
    refetchAccountUsers()
  },[refetchAccountUsers])

  useEffect(() => {
    if (dataMyName && dataMyInfos && dataAccountUsers && dataAccountTypes){
      let accountUsersArray = [
        {
          id: '',
          name: dataMyName?.me?.companyName ? dataMyName?.me?.companyName : dataMyName?.me?.firstName + dataMyName?.me?.lastName,
          email: dataMyInfos?.me?.email,
          document: '',
          accountType: 'Administrador proprietário',
        }
      ]

      dataAccountUsers.getAccountUsers.map((item, index) => {
        const filteredSelectedPackage = dataAccountTypes.getAccountTypes.filter((typeItem, typeIndex, array) => {
          return typeItem._id === item.accountTypeId
        })
        
        accountUsersArray.push({
          id: item._id,
          name: item.name,
          email: item.email,
          document: item.document,
          accountType: filteredSelectedPackage[0].type,
        })

        return true
      })

      setAccountUsers(accountUsersArray)
    }

  }, [dataMyName, dataMyInfos, dataAccountUsers, dataAccountTypes]) //eslint-disable-line

  const breadcrumbLinks = [
    {
      label: 'Home',
      onClick: () => history.push('/dashboard')
    },
    {
      label: 'Minha equipe'
    },
  ]


  return (
    <>
      <Box>
        <Breadcumb color={'primary'} navigationLinks={breadcrumbLinks} ml={'-1rem'} mb={'2.5rem'} />
      </Box>
        
        <Flex mt={5} flexDirection="column" justifyContent="center" width="100%">
          <FormTitle>Minha equipe</FormTitle>
          <Flex justifyContent="center" overflow={'auto'} flex={1} style={{ border: '1px solid lightGray', borderRadius: '5px'}}>
            {accountUsers !== 0 && (
              <Table
                flex={1}
                width={1}
                loading={!accountUsers}
                overflow={'auto'}
                className="demand-table"
                style={{ cursor: 'pointer' }}
                headers={[
                  {
                    title: 'Nome',
                    key: 'name',
                    align: 'left',
                  },
                  {
                    title: 'Email',
                    key: 'email',
                    align: 'left',
                  },
                  {
                    title: 'Tipo',
                    key: 'accountType',
                    align: 'left',
                  },
                  {
                    title: 'Editar',
                    key: 'id',
                    align: 'left',
                    width: '60px',
                    cellComponent: ({ value, row }) => <button style={{alignItems:'flex-end', background:'transparent', border:'none', display:`${row.id ? 'block': 'none'}`}} onClick={() => handleManageAccountUser(value)}><EditIcon /></button>,
                  },
                  {
                    title: 'Excluir',
                    key: 'id',
                    align: 'left',
                    width: '60px',
                    cellComponent: ({ value, row }) => <button style={{alignItems:'flex-end', background:'transparent', border:'none', display:`${row.id ? 'block': 'none'}`}} onClick={() => handleDeleteAccountUser(value)}><TrashEmpty /></button>,
                  },
                ]}
                data={accountUsers}
              />
            )}
          </Flex>
          <Button
            mt={3}
            onClick={() => setOpenAddAccountUsers(true)}
            style={{
              width: '200px',
              backgroundColor: '#2D4677', 
              color: '#FFFFFF',
              fontWeight: 'bold',
              fontSize: '16px',
              borderRadius: '8px',
              padding: '8px 20px'
            }}>
            {'Adicionar usuário'}
          </Button>
        </Flex>
        <AccountUserModal
          openModal={openAddAccountUsers}
          handleClose={handleCloseAccountUserModal}
          userType={'Usuario'}
          userId={accountUserId}
        />  
    </>
  )
}

export default MyTeam
