import {
  NotFound,
  Dashboard,
  Wallet,
  Transactions,
  MyAccount,
  MyTeam,
  Demands,
  DemandDetails,
  Proposals,
  Partners,
  PurchaseService,
//  Repository,
//  Folder,
  ClientDemandsPanel,
//  JuridicoPessoal,
//  JPPlans,
  PurchaseDataPrivacy,
//  PurchasePersonalLegal,
  TalkToLawer,
  DataPrivacy,
  BonuzPlans,
  PurchaseBonuzPlans,
  ContractualManagement,
  ContractualManagementForm,
  ContractualManagementAlerts,
  BonuzSign,
  PurchaseBonuzSign,
} from '../containers'
import Document from '../containers/ContractualManagement/Document';
import DemandDetailsV2 from "../containers/DemandDetails/V2/DemandDetailsV2";


const url_base = '/dashboard'

export const dashboard = [
  { path: `${url_base}`, component: Dashboard, exact: true, routeTo: 'dashboard', jp: true}, // VAI TER ACESSO
  { path: `${url_base}/carteira`, component: Wallet, exact: true, routeTo: 'wallet'}, // NÃO VAI TER
  { path: `${url_base}/transações`, component: Transactions, exact: true, routeTo: 'transactions', jp: false },  // NÃO VAI TER
  { path: `${url_base}/painel`, component: ClientDemandsPanel, exact: true, routeTo: 'client-panel', jp: true }, // VAI TER ACESSO
  { path: `${url_base}/minha-conta`, component: MyAccount, exact: true, routeTo: 'my-account', jp: true }, // VAI TER
  { path: `${url_base}/minha-equipe`, component: MyTeam, exact: true, routeTo: 'my-team', jp: false }, // VAI TER
  //{ path: `${url_base}/documentos`, component: Repository, exact: true, routeTo: 'repository', jp: false }, // NÃO VAI TER
  //{ path: `${url_base}/documentos/:id/:name`, component: Folder, exact: true, routeTo: 'documents', jp: false }, // NÃO VAI TER
  { path: `${url_base}/serviços`, component: Demands, exact: true, routeTo: 'demands', jp: true }, // VAI TER
  { path: `${url_base}/propostas`, component: Proposals, exact: true, routeTo: 'proposals', jp: true }, // VAI TER
  { path: `${url_base}/parceiros`, component: Partners, exact: true, routeTo: 'partners', jp: true }, // VAI TER
  { path: `${url_base}/serviços/solicitar/:subscriptionService?`, component: PurchaseService, exact: true, routeTo: 'purchase-service', jp: false }, // NÃO VAI TER
  { path: `${url_base}/serviços/detalhes/:id`, component: DemandDetailsV2, exact: true, routeTo: 'demand-details', jp: true }, // VAI TER
  { path: `${url_base}/serviços/detalhes/proposta/:id`, component: DemandDetails, exact: true, routeTo: 'demand-details-proposals', jp: true }, // VAI TER
  //{ path: `${url_base}/juridico-pessoal`, component: JuridicoPessoal, exact: true, routeTo: 'personal-legal', jp: true }, // VAI TER
  //{ path: `${url_base}/juridico-pessoal/servicos`, component: JPPlans, exact: true, routeTo: 'jp-plans', jp: true }, // VAI TER
  { path: `${url_base}/data-privacy/pagamento`, component: PurchaseDataPrivacy, exact: true, routeTo: 'purchase-data-privacy', jp: false }, // NÃO VAI TER
  { path: `${url_base}/data-privacy`, component: DataPrivacy, exact: true, routeTo: 'data-privacy', jp: false }, // NÃO VAI TER
  //{ path: `${url_base}/juridico-pessoal/pagamento`, component: PurchasePersonalLegal, exact: true, routeTo: 'purchase-personal-legal', jp: true }, // VAI TER
  { path: `${url_base}/fala-advogado/:subject?`, component: TalkToLawer, exact: true, routeTo: 'talk-to-lawyer', jp: true }, // VAI TER
  { path: `${url_base}/planos`, component: BonuzPlans, exact: true, routeTo: 'bonuz-plans' },
  { path: `${url_base}/planos/pagamento`, component: PurchaseBonuzPlans, exact: true, routeTo: 'purchase-bonuz-plans' },
  { path: `${url_base}/gestao-contratual`, component: ContractualManagement, exact: true, routeTo: 'contractual-management' },
  { path: `${url_base}/gestao-contratual/alerts`, component: ContractualManagementAlerts, exact: true, routeTo: 'contractual-management-alerts' },
  { path: `${url_base}/gestao-contratual/document`, component: ContractualManagementForm, exact: true, routeTo: 'contractual-management-document' },
  { path: `${url_base}/gestao-contratual/document/:id?`, component: Document, exact: true, routeTo: 'contractual-management-document-update' },
  { path: `${url_base}/gestao-contratual/alerts/:id`, component: ContractualManagementAlerts, exact: true, routeTo: 'contractual-management-alert'},
  { path: `${url_base}/bonuz-sign/create`, component: BonuzSign, exact: true, routeTo: 'bonuz-sign-create'},
  { path: `${url_base}/bonuz-sign/purchase`, component: PurchaseBonuzSign, exact: true, routeTo: 'purchase-bonuz-sign'},
  { path: `*`, component: NotFound },
]
