import React, { useState } from 'react'
import { Modal, Box } from "@mui/material";
import "./style.css";
import {ReactComponent as Owl1} from "./owl/owl-1.svg"
import {ReactComponent as Owl2} from "./owl/owl-2.svg"
import { ReactComponent as Anexar } from "../../../assets/icons/anexar.svg";
import { useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import { demandsQueries, walletQueries } from '../../../graphql/queries'
import graphqlHelpers from 'ui-components/lib/helpers/graphql'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import Dropzone from '../../Dropzone'
import { ReactComponent as AttachIcon } from "../../../images/icons/clipes.svg";


const ConfirmationModal = (props) => {
    
    const {
        openModal,
        handleClose,
        type,
        id,
        openRating
    } = props;

    const { showMessage } = useAlertStack()
    const [comment, setComment] = useState('')
    const [files,setFiles] = useState([]);

    const setModal = () => {
        setView(0)
        handleClose()
    }

    const [view, setView] = useState(0)

    const nextView = () => {
        const nxtView = view + 1;
        setView(nxtView)
    }

  const [concludeDemand] = useMutation(demandsMutations.CONCLUDE_DEMAND, {
    variables: {
      demandId: id,
    },
    onCompleted() {
      showMessage({
        title: 'Serviço concluido',
        message: 'Serviço finalizado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })

      nextView()
    },
    refetchQueries: [
      {
        query: demandsQueries.GET_DEMAND,
        variables: { id },
      },
    ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      showMessage({
        title: 'Erro ao concluir serviço',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [refuseDemand] = useMutation(demandsMutations.REFUSE_DEMAND, {
    variables: {
      demandId: id,
      comment,
      files
    },
    onCompleted() {
      showMessage({
        title: 'Serviço recusado',
        message: 'Serviço recusado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })

      window.location.reload()
    },
    refetchQueries: [
      {
        query: demandsQueries.GET_DEMAND,
        variables: { id },
      },
    ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      showMessage({
        title: 'Erro ao recusar serviço',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [approveEstimateDemand] = useMutation(demandsMutations.APPROVE_ESTIMATE_DEMAND, {
    variables: {
      demandId: id,
    },
    onCompleted() {
      showMessage({
        title: 'Serviço aprovado',
        message: 'Serviço aprovado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      nextView()
    },
    refetchQueries: [
      {
        query: demandsQueries.GET_DEMAND,
        variables: { id },
      },
      {
        query: walletQueries.GET_MY_WALLET,
      },
    ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      showMessage({
        title: 'Erro ao aprovar serviço',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  const [reproveEstimateDemand] = useMutation(demandsMutations.REPROVE_ESTIMATE_DEMAND, {
    variables: {
      demandId: id,
      comment,
    },
    onCompleted() {
      showMessage({
        title: 'Serviço recusado',
        message: 'Serviço recusado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time: 4000,
      })
      window.location.reload()
    },
    refetchQueries: [
      {
        query: demandsQueries.GET_DEMAND,
        variables: { id },
      },
    ],
    update: graphqlHelpers.deleteItemsFromCache('Demands'),
    onError() {
      showMessage({
        title: 'Erro ao recusar serviço',
        message: 'Tente novamente mais tarde.',
        color: 'danger',
        position: 'bottom-left',
        time: 4000,
      })
    },
  })

  function setFilesHandler(file){
    let actualFilesList = files;
    console.log('file', file)
    actualFilesList.push(file);
    let actualFilesListFiltered = file.map(({ fileName: name, randomFileName, mimeType, fileExtension: extension, fileSize: size }) => ({
      attachment: '',
      name,
      randomFileName,
      mimeType,
      extension,
      size,
    }))
    setFiles(actualFilesListFiltered);

  }
    const ModalView = () => {
       if( view === 0 && type === 'cancelar') {
      return (
        <>
          <h3 className="mb-4">Cancelamento de demnada!</h3>
          <Owl1 />
          <p className="mt-3">Tem certeza que gostaria de cancelar esta demanda? </p>
          <div className="d-flex my-2 align-items-center">
            <input
              type="text"
              defaultValue={comment}
              onChange={(e)=> setComment(e.target.value)}
              placeholder="Deixe aqui o motivo da recusa"
              style={{marginRight: "8px"}}
              autoFocus
            />
          </div>
          <div className="mt-3">
            <button className="btn btn-cancel btn-wd-1" onClick={() => setModal()}>Cancelar</button>
            <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px"}} onClick={() => refuseDemand()}>Sim</button>
          </div>
        </>
      )
    } else if( view === 1 && type === 'cancelar') {
      return (
        <>
          <h3 className="mb-3">Demanda cancelada com sucesso!</h3>
          <Owl2 className="Owl-2"/>
          <div className="mx-auto" style={{width: "360px", marginTop: "220px"}}>
            <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px", width: "100%"}} onClick={() => setModal()}>Sim</button>
          </div>
        </>
      )
     }else if( view === 0 && type === 'recusar') {
        return (
          <>
            <h3 className="mb-4">Recusar orçamento!</h3>
            <Owl1 />
            <p className="mt-3">Tem certeza que gostaria de recusar o orçamento do serviço?</p>
            <div className="mt-3">
              <button className="btn btn-cancel btn-wd-1" onClick={() => setModal()}>Cancelar</button>
              <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px"}} onClick={() => nextView()}>Sim</button>
            </div>
          </>
        )
      } else if( view === 1 && type === 'recusar') {
        return (
          <>
            <h3 className="mb-3">Mandar o serviço para o advogado revisar!</h3>
            <Owl1 />
            <div className="mt-3 mx-auto" style={{width: "300px"}}>
              <div className="d-flex my-2 align-items-center">
                <input
                  type="text"
                  defaultValue={comment}
                  onChange={(e)=> setComment(e.target.value)}
                  placeholder="Deixe aqui o motivo da recusa"
                  style={{marginRight: "8px"}}
                  autoFocus
                />
              </div>
              <button className="btn btn-concluid btn-wd-1" style={{width: "100%"}} onClick={() => reproveEstimateDemand()}>Sim</button>
            </div>
          </>
        )
      } else if( view === 0 && type === 'aceitar') {
        return (
          <>
            <h3 className="mb-4">Aceite de orçamento !</h3>
            <Owl1 />
            <p className="mt-3">Tem certeza que gostaria de aceitar o orçamento para esse serviço?</p>
            <div className="mt-3">
              <button className="btn btn-cancel btn-wd-1" onClick={() => setModal()}>Cancelar</button>
              <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px"}} onClick={() => approveEstimateDemand()}>Sim</button>
            </div>
          </>
        )
      } else if( view === 1 && type === 'aceitar') {
        return (
          <>
            <h3 className="mb-3">Orçamento aceito com sucesso com sucesso!</h3>
            <Owl2 className="Owl-2"/>
            <div className="mx-auto" style={{width: "360px", marginTop: "220px"}}>
              <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px", width: "100%"}} onClick={() => setModal()}>Sim</button>
            </div>
          </>
        )
      }else if( view === 0 && type === 'revisar') {
            return (
                <>
                    <h3 className="mb-4">Mandar o serviço para o advogado revisar!</h3>
                    <Owl1 />
                    <p className="mt-3">Tem certeza que gostaria de solicitar uma revisão do serviço?</p>
                    <div className="mt-3">
                        <button className="btn btn-cancel btn-wd-1" onClick={() => setModal()}>Cancelar</button>
                        <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px"}} onClick={() => nextView()}>Sim</button>
                    </div>
                </>
            )
        } else if( view === 1 && type === 'revisar') {
            return (
                <div id="div-revisar">
                    <h3 className="mb-3">Mandar o serviço para o advogado revisar!</h3>
                    <Owl1 />
                    <p className="mt-3">O que deseja revisar?</p>
                    <div className="mt-3 mx-auto" style={{width: "90%"}}>
                        <div className="d-flex my-2 align-items-center">
                          <Anexar
                            className="anexar"
                            style={{cursor:'pointer'}}
                            onClick={()=>{
                              let fileRefuseDemand = document.getElementById('file-demand-rev');
                              fileRefuseDemand.click();
                            }}/>
                            <input
                              type="text"
                              defaultValue={comment}
                              onChange={(e)=> setComment(e.target.value)}
                              placeholder="Defina o que deseja revisar"
                              autoFocus
                            />
                          <Dropzone id='file-demand-rev' onLoad={()=>{}} onComplete={(file)=>setFilesHandler(file)}/>
                        </div>
                        <div className="mt-3 mx-auto" style={{width: "50%"}}>
                        <Box className="message-client-attatchment">
                          {files && files.length > 0 ? files.map((item,counter) => {
                            return (<button key={counter} className={"message-attatch"} style={{marginRight:'1px', fontSize:'12px'}}><AttachIcon width={16} height={16} /> {item.name}</button>)
                          }):''}
                        </Box>
                        </div>
                        <div className="mt-3 mx-auto" style={{width: "50%"}}>
                          <button className="btn btn-concluid btn-wd-1" style={{width: "100%"}} onClick={() => refuseDemand()}>solicitar revisão</button>
                        </div>
                    </div>
                </div>
            )
        } else if( view === 1 && type === 'revisar') {
          return (
            <>
              <h3 className="mb-3">Demanda enviada para revisão com sucesso!</h3>
              <Owl2 className="Owl-2"/>
              <div className="mx-auto" style={{width: "360px", marginTop: "220px"}}>
                <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px", width: "100%"}} onClick={() => setModal()}>fechar</button>
              </div>
            </>
          )
        } else if( view === 0 && type === 'concluir') {
            return (
                <>
                    <h3 className="mb-4">Conclusão do serviço !</h3>
                    <Owl1 />
                    <p className="mt-3">Tem certeza que gostaria de concluir esse serviço?</p>
                    <div className="mt-3">
                        <button className="btn btn-cancel btn-wd-1" onClick={() => setModal()}>Cancelar</button>
                        <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px"}} onClick={() => concludeDemand()}>concluir serviço</button>
                    </div>
                </>
            )
        } else if( view === 1 && type === 'concluir') {
            return (
                <>
                    <h3 className="mb-3">Demanda Concluída com sucesso!</h3>
                    <Owl2 className="Owl-2"/>
                    <div className="mx-auto" style={{width: "360px", marginTop: "220px"}}>
                        <button className="btn btn-concluid btn-wd-1" style={{marginLeft: "15px", width: "100%"}} onClick={() => openRating()}>fechar</button>
                    </div>
                </>
            )
        }
        
    }

    return (
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="confirmation"
        >
            <Box className={view === 0 || view === 2 ? "box md-size-1 yellow modal-size" : "box md-size-1 blue modal-size" }>
                <ModalView />
            </Box>
        </Modal>
    )
}

export default ConfirmationModal;
