import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useHistory } from 'react-router-dom';

const CardInfo = ({info, openModal}) => {
  const {
    title,
    colors,
    options=[],
    monthlyValue
  } = info;

  const history = useHistory()

  const stylesCard = {
    background: colors.background
  }

  return (
    <div className='plan' style={stylesCard}>
      <header>
        <h3>PLANO</h3>
        <h3 style={{color: colors.title, fontSize: '30px'}}>{title}</h3>
        <div className="line" style={{background: `linear-gradient(90deg, ${colors.background} 0%, ${colors.line} 49.51%, ${colors.background} 100%)`}}>
        </div>
      </header>
      <div className="balloon">
        {options.map(({title, info, notCheck}) => ( 
          <div className="option" key={title}>
            <li>
              {
                notCheck ?
                    <span className="x"><FaTimes/></span>
                :
                  <span className="check"><FaCheck/></span>
              }
                {title}
            </li>

            <div className="info">
            </div>
            <OverlayTrigger
              placement="top"
              delay={{ show: 150, hide: 150 }}
              overlay={<Tooltip style={{backgroundColor: '#5A67DF', borderRadius: '8px'}}>{info}</Tooltip>}
            >
              <div className="info-toggle">
                <p>?</p>
              </div>
            </OverlayTrigger>
          </div>
        ))}
      </div>
      <footer>
        <div>
          {title === 'essencial' ? <p onClick={openModal} className="link">Consultar entregáveis</p> : <p className="big">A PARTIR</p>}
          <h2>R${monthlyValue},00</h2>
          <p>POR MÊS</p>
          <button 
            style={ colors.button === '#041181' ? {color: '#fff', background: colors.button} : {color: '#000', background: colors.button}}
            onClick={() => history.push('/dashboard/planos/pagamento')}
          >
            Adquirir Plano
          </button>
        </div>
      </footer>
    </div>
  )
}

export default CardInfo;
