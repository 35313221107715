import React, { useState } from 'react'
import { Modal, Box, Rating } from "@mui/material"
import { Textarea, Spinner } from 'ui-components'
import { useMutation } from '@apollo/react-hooks'
import { demandsMutations } from '../../../graphql/mutations'
import * as localStorageService from '../../../services/localStorageService'
import './styles.css'
import Owl1 from '../../../assets/img/owl/rating-modal.png'
import Owl2 from '../../../assets/img/owl/rating-modal2.png'

const RatingModal = ({ openModal, handleClose, demandId, lawyerId }) => {
  const [demandRating, setDemandRating] = useState(0)
  const [lawyerRating, setLawyerRating] = useState(0)
  const [clientFeedback, setClientFeedback] = useState('')
  const [view, setView] = useState(0)

  const [evaluateDemand, { loading: loadingRating }] = useMutation(demandsMutations.EVALUATE_DEMAND, {
    variables: {
      demandId: demandId,
      lawyerId: lawyerId,
      evaluation: demandRating,
      lawyerEvaluation: lawyerRating,
      clientFeedback: clientFeedback
    },
    onCompleted() {
      setView(2)
    },
    onError() {
      setView(3)
    },
  })

  const handleCloseModal = () => {
    localStorageService.createStorage('demand-evaluation', true)
    handleClose()
  }

  const labels = {
    0: 'Resposta vazia',
    1: 'Muito insatisfeito',
    2: 'Insatisfeito',
    3: 'Neutro',
    4: 'Satisfeito',
    5: 'Muito satisfeito',
  };


  return (
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="rating-modal"
    >
      {view ? (
        <Box className='box md-size-2'>
          <img src={Owl2} alt="Owl saying hi!" />
          <h5 style={{textAlign:'center'}}>
            {view === 3 ? (
              'Ocorreu um erro ao enviar sua avaliação!'
            ) : (
              'Obrigado pela avaliação!'
            )}
          </h5>
          {view === 1 && (
            <p className='text-center'>Acesse nossa aba de Gestão Contratual, anexe o documento feito e te informaremos quando estiver próximo do vencimento.</p>
          )}
          <button className='btn btn-blue' style={{width: '100%', margin: '10px'}} onClick={() => handleClose()}>OK</button>
        </Box>
      ) : (
        <Box className='box md-size-1'>
          <img src={Owl1} alt="Owl saying hi!" />
          <h4>Avalie o serviço bonuz!</h4>
          <Rating
            size="large"
            name="demand-rating"
            value={demandRating}
            precision={1}
            onChange={(event, newValue) => {
              setDemandRating(newValue)
            }}
          />
          <Box sx={{ ml: 2 }}>{labels[demandRating]}</Box>
          <h4 className='mt-5'>Avalie o Advogado!</h4>
          <Rating
            size="large"
            name="lawyer-rating"
            value={lawyerRating}
            precision={1}
            onChange={(event, newValue) => {
              setLawyerRating(newValue)
            }}
          />
          <Box sx={{ ml: 2 }}>{labels[lawyerRating]}</Box>
          <div className='text-area-container'>
            <Textarea 
              placeholder='Deixe um comentário'
              onChange={(event) => {
                setClientFeedback(event.target.value)
              }}
            />
          </div>
          <button 
            className='btn btn-green' 
            onClick={() => evaluateDemand()}
            disabled={!demandRating || !lawyerRating}
          >
            {loadingRating ? <Spinner /> : 'Enviar'}            
          </button>
        </Box>        
      )}
    </Modal>
  )
}

export default RatingModal
