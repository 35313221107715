import React, {useState } from 'react'
import BannerImage from '../../assets/img/cm/bannerImg.png'
import { Card, Col, Form, Row } from 'react-bootstrap'
import { Input, Pagination, Select, Textarea } from 'ui-components'
// import SwitchInput from '../../components/SwitchInput'
import Typography from 'ui-components/lib/components/Typography'
import { Flex } from 'reflexbox'
import ContextMenu from 'ui-components/lib/components/ContextMenu'
import Table from 'ui-components/lib/components/Table'
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks'
import { documentsMutations } from '../../graphql/mutations'
import useAlertStack from 'ui-components/lib/hooks/useAlertStack'
import { documentsQueries } from '../../graphql/queries'
import FormLoading from '../../components/HalfLoading'
import { AlertPeriod, AlertReceiver, AlertRepeatPeriod } from '../../components'

const ContractualManagementAlerts = (props) => {

  const [id,setId] = useState('');
  const [alertName,setAlarmName] = useState('');
  const [alertDescription,setAlertDescription] = useState('');
  const [alertPeriod,setAlertPeriod] = useState('');
  const [alertReceivers,setAlertReceivers] = useState('');
  const [alertRepeatPeriod,setAlertRepeatPeriod] = useState(0);
  const [isDefault, setIsDefault] = useState(false);
  const [sort, setSort] = useState('contractDueDate') //eslint-disable-line
  const [order,setOrder] = useState('desc') //eslint-disable-line
  const [currentPage, setCurrentPage] = useState(1)
  const [updateAlertOperation,setUpdateAlertOperation] = useState(false)
  const offset = 10

  const { showMessage } = useAlertStack()

  const { loading: alertsLoading, data: alertsData,
    refetch: refetchAlerts //eslint-disable-line
  } = useQuery(documentsQueries.GET_ALERTS, {

    variables: {
      sort: sort,
      page: currentPage,
      order,
      offset,
      queryBy: 'status'
    },
  })

  const [createAlert, {loading:createAlertLoading}] = useMutation(documentsMutations.CREATE_ALERT,{
    variables:{
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      isDefault
    },
    onCompleted(reponse){
      showMessage({
        title: 'Alerta criado',
        message: 'Alerta criado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time:4000
      })
      refetchAlerts()
    },
    onError({ graphQLErrors }){
      console.log(graphQLErrors[0].extensions.response.body)
      showMessage({
        title: 'Erro ao criar o alerta',
        message: graphQLErrors[0].extensions.response.body,
        color: 'danger',
        position: 'bottom-left',
        time:2000
      })
    }
  })

  const [updateAlert, {loading:updateAlertLoading}] = useMutation(documentsMutations.UPDATE_ALERT,{
    variables:{
      id,
      alertName,
      alertDescription,
      alertPeriod,
      alertReceivers,
      alertRepeatPeriod,
      isDefault
    },
    onCompleted(){
      setUpdateAlertOperation(!updateAlertOperation)
      setId('')
      setAlarmName('')
      setAlertDescription('')
      setAlertPeriod('')
      setAlertReceivers('')
      setAlertRepeatPeriod('')
      setIsDefault('')
      showMessage({
        title: 'Alerta atualizado',
        message: 'Alerta atualizado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time:4000
      })
    },
    onError(){
      alert('Dei erro mesmo e fodase')
    }
  })

  const [deleteAlert] = useMutation(documentsMutations.DELETE_ALERT,{
    onCompleted(){
      showMessage({
        title: 'Alerta deletado',
        message: 'Alerta deletado com sucesso.',
        color: 'success',
        position: 'bottom-left',
        time:4000
      })
      refetchAlerts()
    }
  })

  const handleChangeNext = () => {
    const nextPage = currentPage + 1
    if (nextPage <= alertsData?.getDocuments?.pagination?.pagesAmount) setCurrentPage(nextPage)
  }

  const handleChangePrev = () => {
    const prevPage = currentPage - 1
    if (prevPage > 0) setCurrentPage(currentPage - 1)
  }

  const handleChangeOrder = (item) => {
    if (item.key === sort) {
      setOrder(order === 'asc' ? 'desc' : 'asc')
    } else {
      setSort(item.key)
      setOrder('asc')
    }
  }

  const handleDeleteAlert = (id) => {
    deleteAlert({
      variables:{
        id:id
      }
    });
  }


  const [getAlert, { loading: alertLoading}] = useLazyQuery(documentsQueries.GET_ALERT, {
    variables: {
      id:id,
    },
    onCompleted({ getAlert }){
      setUpdateAlertOperation(true)
      setId(getAlert?._id)
      setAlarmName(getAlert?.alertName)
      setAlertDescription(getAlert?.alertDescription)
      setAlertPeriod(getAlert?.alertPeriod)
      setAlertReceivers(getAlert?.alertReceivers)
      setAlertRepeatPeriod(getAlert?.alertRepeatPeriod)
      setIsDefault(getAlert?.isDefault)
    }
  })


  const setAlertId = (id)=>{
    setId(id)

    if(id !== ""){
      getAlert({
        variables:{
          id:id
        }
      })
    }
  }

  return (
    <div className='cm'>
      <div className='banner'>
        <div>
          <h1>Para que você possa centralizar todos os seus documentos!</h1>
          <p>Anexe contratos ou ao finalizar um serviço na Bonuz você poderá ter a gestão do prazo de vencimento, edite quando achar necessário e seja notificado por e-mail.</p>
          <br/>
          <p>Obtenha desconto ao solicitar o serviço novamente conosco.</p>
        </div>
        <div>
          <img alt="" src={BannerImage}/>
        </div>
      </div>
      <Col style={{width:'15%', marginTop:'30px',marginBottom:'20px'}}>
        <Row xs="auto" style={{flexDirection:'row',flexWrap:'nowrap',alignItems:'center'}}>
          <h3 style={{marginBottom:'0px'}}>
            {updateAlertOperation?'Atualizar Alerta':'Novo Alerta'}
          </h3>
        </Row>
      </Col>
      <div >
        <Card
          className='doc p-4'
        >
          {alertLoading || createAlertLoading || updateAlertLoading
            ?
            <FormLoading/>
            :
            <Row>
              <Col>
                <Row>
                  <Col>
                    <Form.Group>
                      <Form.Label>Nome</Form.Label>
                      <Input type="text" placeholder="Nome do alerta" value={alertName} onChange={(e)=>{setAlarmName(e.target.value)}}/>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Descrição</Form.Label>
                      <Textarea type="text" placeholder="Descrição do alerta" value={alertDescription} onChange={(e)=>{setAlertDescription(e.target.value)}}>
                      </Textarea>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{marginTop:'20px'}}>
                  <Col>
                    <Form.Group>
                      <Form.Label>Prazo para o recebimento (em dias)</Form.Label>
                      <Input type="text" placeholder="dias" value={alertPeriod}  onChange={(e)=>setAlertPeriod(e.target.value)}/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Destinatários da notificação <small>* use ; como separador</small></Form.Label>
                      <Input type="email" placeholder="exemplo@exemplo.com;exemplo2@exemplo.com" onChange={(e)=>{setAlertReceivers(e.target.value)}}/>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group>
                      <Form.Label>Repetir alerta</Form.Label>
                      <Select value={alertRepeatPeriod} onChange={(e)=> {setAlertRepeatPeriod(parseInt(e.target.value))}}>
                        <option selected value="0" disabled>Selecione um alerta</option>
                        <option value='7'>Semanalmente</option>
                        <option value='14'>A cada 2 semanas</option>
                        <option value='30'>Mensalmente</option>
                        <option value='1'>Não repetir</option>
                      </Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row style={{marginTop:'30px'}}>
                  <div className='d-flex justify-content-end'>
                    <button onClick={
                      () => (id ? updateAlert() : createAlert())} className="btn btn-primary">
                      {id?'Atualizar':'Criar'}
                    </button>
                  </div>
                </Row>
              </Col>
            </Row>
          }
        </Card>
      </div>
      <Col style={{width:'15%', marginTop:'30px',marginBottom:'20px'}}>
        <Row xs="auto" style={{flexDirection:'row',flexWrap:'nowrap',alignItems:'center'}}>
          <h3 style={{marginBottom:'0px'}}>
            Alertas criados
          </h3>
          <Select style={{height:'40px'}} onChange={(e)=> handleChangeOrder(e.target.value)}>
            <option disabled selected>Ordenar por </option>
            <option value='alertName'>Alerta</option>
            <option value='alertReceivers'>Quem receberá</option>
            <option value='alertRepeatPeriod'>Repetir alerta</option>
            <option value='alertPeriod'>Prazo</option>
          </Select>
        </Row>
      </Col>
      <div>
         <Row className='p-4'>
            <Col>
              <Table
                flex={1}
                width={[1]}
                loading={alertsLoading}
                refetch={refetchAlerts}
                onChangeOrder={handleChangeOrder}
                overflow={'auto'}
                pagination={()=>{}}
                headers={[
                  {
                    title: 'Alerta',
                    key: 'author',
                    sort: false,
                    align: 'left',
                    cellComponent: ({ row }) => <Typography>{row.alertName}</Typography>,
                  },
                  {
                    title: 'Quem receberá',
                    key: 'createdAt',
                    sort: false,
                    align: 'left',
                    cellComponent: ({ row }) => <AlertReceiver alertReceivers={row.alertReceivers}/>,
                  },
                  {
                    title: 'Repetir alerta',
                    key: 'size',
                    sort: false,
                    align: 'left',
                    cellComponent: ({ row }) => <AlertRepeatPeriod alertPeriod={parseInt(row.alertRepeatPeriod)}/>,
                  },
                  {
                    title: 'Prazo para recebimento',
                    key: 'size',
                    sort: false,
                    align: 'left',
                    cellComponent: ({ row }) => <AlertPeriod alertPeriod={parseInt(row.alertPeriod)}/>,
                  },
                  {
                    title: 'Alarme Padrão',
                    key: 'size',
                    sort: false,
                    align: 'left',
                    cellComponent: ({ row }) => <Typography>{row.isDefault?'Sim':'Não'}</Typography>,
                  },
                  {
                    title: 'Ações',
                    key: 'actions',
                    sort: false,
                    align: 'center',
                    width: '50px',
                    cellComponent({ row, headers, value }) {
                      const actions = [
                        {
                          text: 'Editar',
                          buttonFunction: () => setAlertId(row._id),
                        },
                        {
                          text: 'Excluir',
                          buttonFunction: () => handleDeleteAlert(row._id),
                        },
                      ]
                      return (
                        <Flex justifyContent={'center'}>
                          <ContextMenu contextMenuActions={actions} />
                        </Flex>
                      )
                    }
                  },
                ]}
                data={alertsData?.getAlerts?.data}
              />
            </Col>
             <Flex justifyContent="center">
               <Pagination
                 color="primary"
                 m={20}
                 onChangePage={(item) => setCurrentPage(item)}
                 onPrevPage={() => handleChangePrev()}
                 onNextPage={() => handleChangeNext()}
                 total={alertsData?.getAlerts?.pagination?.pagesAmount}
                 page={alertsData?.getAlerts?.pagination?.current || 1}
               />
             </Flex>
          </Row>
      </div>
    </div>
  )
}
export default ContractualManagementAlerts
